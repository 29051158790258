import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { CancelButton } from '../../ui';

interface Props {
  show: boolean;
  html: string;
  onClose: () => void;
}

const EmailDialog: React.FC<Props> = ({ show, html, onClose }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogActions>
        <CancelButton onClick={onClose}>{t('Close')}</CancelButton>
      </DialogActions>
      <iframe srcDoc={html} height="1200px" className="emailIframe"></iframe>
    </Dialog>
  );
};

export default EmailDialog;
