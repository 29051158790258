import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';
import { MiniButton } from '../ui/inputs/MiniButton/MiniButton';

interface Props extends GridCellProps {
  handleOnClick?: () => void;
  labelText: string;
}

const IdCell: React.FC<Props> = ({ handleOnClick, labelText, ...props }) => {
  const field = props.field || '';
  const value = props.dataItem[field];

  return (
    <StyledTdId
      colSpan={props.colSpan}
      className={`${props.className} cursor-pointer`}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
      onClick={handleOnClick}
    >
      <a>
        <div className="flex items-center">
          {value}

          <div className="hidden-button invisible">
            <MiniButton labelText={labelText} />
          </div>
        </div>
      </a>
    </StyledTdId>
  );
};

export default IdCell;

const StyledTdId = styled.td`
  :hover {
    .hidden-button {
      visibility: visible;
    }
  }
`;
