import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { Submission } from '../store/submission/types';
import submissionSlice from '../store/submission/slice';
import {
  selectBidState,
  selectInfo,
  selectKeywordState,
  selectSubmissionState,
  selectTrackRoles,
} from '../store/selectors';
import { connect } from 'react-redux';
import Loading from '../components/Loading/Loading';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../store/info/types';
import { formatKeywordColumn } from '../helpers/table';
import { getMenuItemName } from '../helpers/translations';
import MyGridRedux from '../components/MyGridRedux/MyGridRedux';
import { useTranslation } from 'react-i18next';
import HeaderEditBiddingAssignment from '../components/HeaderEditBiddingAssignment/HeaderEditBiddingAssignment';
import { Role } from '../store/conference/types';
import infoSlice from '../store/info/slice';
import { getRouteByName } from '../router/routes';
import history from '../store/history';
import { fillRoutePath } from '../helpers/path';
import { MenuItemName } from '../layouts/types';
import { Bid, BidOption } from '../store/bid/types';
import { getOtherBids } from '../store/bid/sagas';
import { getFullName, getReviewer } from '../helpers/users';

interface Props {
  getSubmissionsAction: () => void;
  submissionsById: { [key: string]: Submission };
  loading: boolean;
  keywordById: { [key: number]: Keyword };
  info: Info;
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  bidOptionsById: { [key: number]: BidOption };
}

const SubmissionBidsPage = ({
  getSubmissionsAction,
  submissionsById,
  loading,
  keywordById,
  info,
  roleById,
  getInfoAction,
  bidOptionsById,
}: Props) => {
  const { t, i18n } = useTranslation();
  const params: any = useParams();
  const id = parseInt(params.id);

  const [bids, setBids] = useState<Bid[]>([]);
  const [bidsLoading, setBidsLoading] = useState<boolean>(true);

  const friendlyName: TableFriendlyName = 'submission_bids';

  // Given id must match with external_id field
  const submission = Object.values(submissionsById).find((submission) => submission.external_id == id);

  /* Fetch table data */
  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      getInfoAction({ friendlyName: 'users' });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction();
    } else if (submission) {
      /* Fetch submission bids */
      getOtherBids({ submission_id: submission.id }).then((result) => {
        if (result.type == 'ok') {
          setBids(result.value.data);
          setBidsLoading(false);
        }
      });
    }
  }, [submissionsById]);

  if (loading || bidsLoading) {
    return <Loading />;
  } else if (!submission) {
    return <div>Submission #{id} not found</div>;
  }

  /* Generate data for table */
  const chairInfo = info as ChairInfo;
  const inputData: unknown[] = [];
  bids.forEach((bid) => {
    const reviewer = getReviewer(bid.person_role_id, chairInfo.users);
    if (reviewer) {
      const newRegister = {
        ...reviewer,
        bid: bid.bid_option_id ? bidOptionsById[bid.bid_option_id].name : undefined,
        conflict: bid.conflict,
      };

      // Add Role description
      newRegister.role = roleById[reviewer.role_id].description;

      formatKeywordColumn(newRegister, keywordById);

      inputData.push(newRegister);
    }
  });

  /* Generate header */
  const headerContent = {
    title: submission.title,
    row1: {
      title: t('Authors'),
      content: submission.authors.map((author) => getFullName(author) + '. '),
    },
    row2: {
      title: t('Main area'),
      content:
        submission && submission?.keyword_ids.length > 0
          ? (submission?.keyword_ids
              .map((id) => {
                const parentId = keywordById[id].parent_id;
                return parentId && keywordById[parentId].name + '. ';
              })
              .filter((item, index, self) => self.indexOf(item) === index) as string[]) //to avoid duplicate main areas
          : 'The submission has not selected main areas.',
    },
    row3: {
      title: t('Keywords'),
      content:
        submission && submission.keyword_ids.length > 0
          ? (submission?.keyword_ids.map((id) => keywordById[id].name + '. ') as string[])
          : 'The submission has not selected keywords.',
    },
    row4: { title: t('Abstract'), content: submission.abstract },
  };

  const parentRoute = getRouteByName('RouteAllSubmissionsBids');

  return (
    <div className="flex flex-col h-full">
      <HeaderEditBiddingAssignment
        onPageTitleClick={() => history.push(fillRoutePath(parentRoute.path))}
        originPageTitle={getMenuItemName(parentRoute.main_menu_option as MenuItemName, t)}
        headerContent={headerContent}
      />
      <div className="mt-8" />
      <MyGridRedux friendlyName={friendlyName} inputData={inputData as Record<string, unknown>[]} />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  submissionsById: selectSubmissionState(state).submissionsById,
  loading: selectSubmissionState(state).loading || selectInfo(state).loading,
  keywordById: selectKeywordState(state).keywordById,
  info: state.info.info,
  roleById: selectTrackRoles(state),
  bidOptionsById: selectBidState(state).bidOptions.byId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: () => dispatch(submissionSlice.actions.GET_SUBMISSIONS()),
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionBidsPage);
