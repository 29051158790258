import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import { getReviewsResponse, UpdateAssignmentDTO, UpdateAssignment, UpdateAssignmentResponse } from './types';
import { put, select, takeLatest } from '@redux-saga/core/effects';
import assignmentSlice from './slice';
import errorSlice from '../error/slice';
import formSlice from '../form/slice';
import { FileUploadUpdaterDTO, UploadFeedbackResponse } from '../submission/types';
import { PayloadAction } from '@reduxjs/toolkit';
import tableSlice from '../table/slice';
import infoSlice from '../info/slice';
import { selectTable } from '../selectors';
import { TableState } from '../table/types';
import { TableFriendlyName } from '../info/types';

const baseURL = process.env.REACT_APP_API_URL;

export const postAssignmentsBulk = async (
  data: FileUploadUpdaterDTO & { templateName: string },
): Promise<Result<UploadFeedbackResponse, APIValidationError>> => {
  const { file, dry_run, templateName } = data;

  const formData = new FormData();
  formData.append('file', file);

  const init = {
    method: 'POST',
    auth: true,
    role: true,
    defaultContentType: true,
    body: formData,
  };

  return apiCall<UploadFeedbackResponse>(
    `${baseURL}/api/assignments/bulk?dry_run=${dry_run ? 1 : 0}&template=${templateName}`,
    init,
  );
};

const getReviews = async (): Promise<Result<getReviewsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<getReviewsResponse>(`${baseURL}/api/assignments/current`, init);
};

function* getReviewsSaga() {
  const result = (yield getReviews()) as Await<ReturnType<typeof getReviews>>;

  switch (result.type) {
    case 'ok':
      const { answerById } = result.value.data;
      yield put(formSlice.actions['GET:ANSWERS:OK']({ answerById }));
      yield put(assignmentSlice.actions['GET_REVIEWS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const getAssignmentDetails = async (submissionId: number): Promise<Result<getReviewsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<getReviewsResponse>(`${baseURL}/api/assignments/${submissionId}`, init);
};

function* getAssignmentDetailsSaga(action: PayloadAction<number>) {
  const result = (yield getAssignmentDetails(action.payload)) as Await<ReturnType<typeof getAssignmentDetails>>;

  switch (result.type) {
    case 'ok':
      const { answerById } = result.value.data;
      yield put(formSlice.actions['GET:ANSWERS:OK']({ answerById }));
      yield put(assignmentSlice.actions['GET_REVIEWS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const assignmentUpdate = async (
  data: UpdateAssignmentDTO,
): Promise<Result<UpdateAssignmentResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<UpdateAssignmentResponse>(`${baseURL}/api/assignments/assign`, init);
};

function* assignmentsUpdateSaga(action: PayloadAction<UpdateAssignment>) {
  const result = (yield assignmentUpdate(action.payload)) as Await<ReturnType<typeof assignmentUpdate>>;

  switch (result.type) {
    case 'ok':
      const { tableFriendlyName, assigned, modelId, queryParams } = action.payload;
      const { id: assignment_id } = result.value.data;
      yield put(infoSlice.actions['ASSIGNMENTS:UPDATE:OK']({ assignment_id, assigned, modelId, tableFriendlyName }));
      yield put(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET']({ tableFriendlyName, queryParams }));
      yield put(tableSlice.actions['NOTIFIABLES:UPDATE']({ assignment_id, assigned }));
      yield put(assignmentSlice.actions['ASSIGNMENTS:UPDATE:OK']()); // This updates the "loading" within column cell
      /* Here we have a hard problem.
      Aggregated data that depends on assignment (num_assignments, pct_completed_assignments...)  may be out-dated at this point for some tables
      Let's do a quick fix: We'll delete all column aggregated data in order to force re-fetch it from DB.
      TODO Improve this solution
      */
      const tableState: TableState = yield select(selectTable);
      for (const value in tableState.customColumnsData) {
        if (value != tableFriendlyName) {
          yield put(tableSlice.actions['CUSTOMCOLUMNS:DATA:DELETE']({ friendlyName: value as TableFriendlyName }));
        }
      }
      break;
    case 'validation-error':
      yield put(errorSlice.actions['SET:VALIDATION_ERRORS'](Object.values(result.value.validation)));
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(assignmentSlice.actions['GET_REVIEWS'], getReviewsSaga),
  takeLatest(assignmentSlice.actions['GET_ASSIGNMENT_DETAILS'], getAssignmentDetailsSaga),
  takeLatest(assignmentSlice.actions['ASSIGNMENTS:UPDATE'], assignmentsUpdateSaga),
];
