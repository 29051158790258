import React from 'react';
import { can, SubmissionPermissionManager } from '../../helpers/permissions';
import AddUserForm from '../AddUserForm/AddUserForm';
import { Author, Submission } from '../../store/submission/types';
import arrayMove from '../../helpers/arrayMove';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '../ui';
import SimpleDialog from '../dialogs/SimpleDialog/SimpleDialog';
import TrashCan from '../../icons/TrashCan';
import styled from 'styled-components';
import { Role } from '../../store/conference/types';
import { useTranslation } from 'react-i18next';
import { getFullName } from '../../helpers/users';

interface Props {
  submission: Submission;
  setSubmission: React.Dispatch<React.SetStateAction<Submission>>;
  userRole: Role;
  personId: number;
}

const AddAuthorsListForm: React.FC<Props> = ({ submission, setSubmission, userRole, personId }) => {
  const { t, i18n } = useTranslation();
  const [dialog, setDialog] = React.useState<JSX.Element | undefined>();

  const primaryAuthorChangeHandler = (index: number) => {
    setSubmission({
      ...submission,
      authors: [
        ...submission.authors.map((author, i) => {
          return {
            ...author,
            is_primary: index == i ? !author.is_primary : author.is_primary,
          };
        }),
      ],
    });
  };

  const currentAuthor = submission.authors.find((author) => author.id === personId);
  const isPrimaryAuthor = new SubmissionPermissionManager(submission).isPrimaryAuthor(currentAuthor?.id ?? 0, userRole);
  const primaryAuthorsCount = submission.authors.filter((author) => author.is_primary).length;
  const isChair = userRole.type == 'chair';

  return (
    <StyledAuthorsListWrapper>
      {can('CAN_EDIT_AUTHORS') && (
        <div className="mt-2">
          <AddUserForm
            users={submission.authors}
            save_handler={(data) => {
              const author: Author = { ...data, id: 0, person_role_id: 0, is_primary: false, middle_name: null };
              setSubmission({ ...submission, authors: [...submission.authors, author] });
            }}
          />
        </div>
      )}
      {submission.authors.map((author, index) => {
        const fullName = getFullName(author);
        const canTogglePrimary =
          (isChair || (isPrimaryAuthor && author.id !== currentAuthor?.id)) &&
          (!author.is_primary || (author.is_primary && primaryAuthorsCount > 1));
        const canRemove = canTogglePrimary;
        const canOrder = isChair || isPrimaryAuthor;
        return (
          <StyledAuthorsList key={index}>
            {can('CAN_EDIT_AUTHORS') && canOrder && (
              <div className="mr-5 flex" style={{ width: 32, color: '#0044f0' }}>
                {index > 0 && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      const authors = arrayMove([...submission.authors], index, index - 1);
                      setSubmission({ ...submission, authors });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortUp} className="text-xl mr-2" style={{ verticalAlign: -16 }} />
                  </div>
                )}
                {index < submission.authors.length - 1 && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      const authors = arrayMove([...submission.authors], index, index + 1);
                      setSubmission({ ...submission, authors });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortDown} className="text-xl" style={{ verticalAlign: 4 }} />
                  </div>
                )}
              </div>
            )}
            <strong className="flex-grow-0">{fullName}</strong>
            <span className="spacer">|</span>
            <span className="flex-grow-0"> {author.email}</span> <span className="spacer">|</span>
            <span className="pr-4">{author.organization}</span>
            <StyledCheckbox>
              <Checkbox
                label={t('Primary')}
                checked={author.is_primary}
                disabled={!(can('CAN_EDIT_AUTHORS') && canTogglePrimary)}
                onChange={() => {
                  if (isPrimaryAuthor && author.id === currentAuthor?.id) {
                    setDialog(
                      <SimpleDialog
                        open={true}
                        handleClose={() => primaryAuthorChangeHandler(index)}
                        handleOk={() => {
                          setSubmission({
                            ...submission,
                            authors: [...submission.authors.filter((e, i) => i != index)],
                          });
                        }}
                        title={t('Remove yourself as primary author?')}
                      >
                        <p className="text-sm mb-2">
                          {t(
                            `Take into account that you'll lose the ability of edit this paper and no longer receive notifications.`,
                          )}
                        </p>
                      </SimpleDialog>,
                    );
                  } else {
                    primaryAuthorChangeHandler(index);
                  }
                }}
              />
            </StyledCheckbox>
            {can('CAN_EDIT_AUTHORS') && canRemove && (
              <div
                className="ml-5 flex items-center cursor-pointer"
                onClick={() => {
                  setDialog(
                    <SimpleDialog
                      open={true}
                      handleClose={() => setDialog(undefined)}
                      handleOk={() => {
                        setSubmission({
                          ...submission,
                          authors: [...submission.authors.filter((e, i) => i != index)],
                        });
                      }}
                      title={t('Remove author?')}
                    >
                      <p className="text-sm mb-2">{t(`Are you sure you want to remove the author?`)}</p>
                    </SimpleDialog>,
                  );
                }}
              >
                <StyledTrash>
                  <TrashCan />
                </StyledTrash>
                <div className="ml-1">{t('Remove')}</div>
              </div>
            )}
          </StyledAuthorsList>
        );
      })}
      {dialog}
    </StyledAuthorsListWrapper>
  );
};

export default AddAuthorsListForm;

const StyledAuthorsListWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const StyledAuthorsList = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem 1.5rem;
  background-color: #e5e6ea;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.dark};
  .spacer {
    margin: 0 0.8rem;
  }
`;

const StyledCheckbox = styled.div`
  flex-grow: 1;
  text-align: right;
  div {
    vertical-align: middle;
  }
`;

const StyledTrash = styled.div`
  margin-top: -3px;
`;
