import React from 'react';
import Close from '../../icons/Close';
import Alert from '../../../../icons/Alert';
import styled from 'styled-components';

type MESSAGE_TYPE = 'info' | 'success' | 'warning' | 'error';

interface Props {
  severity?: MESSAGE_TYPE;
  className?: string;
  handleClose: () => void;
}
const Message: React.FC<Props> = ({ severity, className, children, handleClose }) => {
  let bgColor: string;
  let borderColor: string;

  switch (severity) {
    case 'warning':
      bgColor = '#fff7d9';
      borderColor = '#E9E0C1';
      break;
    default:
      bgColor = '#e5f6fd';
      borderColor = '#ddd';
      break;
  }
  return (
    <StyledMessage className={className} style={{ backgroundColor: bgColor, borderColor: borderColor }}>
      {severity && severity === 'warning' && <Alert />}
      <div className="content-wrapper">{children}</div>
      <div className="close-button" onClick={handleClose}>
        <Close />
      </div>
    </StyledMessage>
  );
};

export default Message;

const StyledMessage = styled.div`
  position: relative;
  border-width: 1px;
  border-radius: 0.375rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;

  > svg {
    margin-right: 3rem;
  }
  .content-wrapper {
    margin-right: 1rem;
    .title {
      font-weight: 600;
    }
    .text {
    }
  }

  .close-button {
    position: absolute;
    right: 0.875rem;
    top: 0.875rem;
    cursor: pointer;
  }
`;
