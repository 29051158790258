import React, { useState } from 'react';
import { Select, SelectRadioButton } from './ui';
import { useTranslation } from 'react-i18next';
import { AppState } from '../store/state';
import { selectTrackRoles } from '../store/selectors';
import { AppDispatch } from '../store/store';
import { Role } from '../store/conference/types';
import { connect } from 'react-redux';
import { parsePositiveNumber } from '../helpers/validation';

type ReviewerType = 'submissions' | 'reviewers';

type ReviewerTypePickerState = {
  type: ReviewerType;
  roleId: number;
};

interface Props {
  fromRoleId: number;
  toRoleId: number | null;
  roleById: { [key: string]: Role };
  onChange?: (roleId: number | null) => void;
}

const ReviewerTypePicker: React.FC<Props> = ({ fromRoleId, toRoleId, roleById, onChange }) => {
  const { t, i18n } = useTranslation();
  const [pickerState, setPickerState] = useState<ReviewerTypePickerState>({
    type: toRoleId ? 'reviewers' : 'submissions',
    roleId: toRoleId ?? 0,
  });

  const handleOnChange = (newState: ReviewerTypePickerState) => {
    setPickerState(newState);
    onChange?.(newState.type == 'reviewers' ? newState.roleId : null);
  };

  const getOptions = (): { id: string; label: string }[] => {
    const options = [{ id: 'submissions', label: t('Handles submissions') }];
    if (reviewerRoles.length) {
      options.push({ id: 'reviewers', label: t('Handles reviewers') });
    }
    return options;
  };

  const reviewerRoles = Object.values(roleById)
    .filter((role) => role.type == 'reviewer' && fromRoleId != role.id)
    .map((role) => ({ id: role.id.toString(), label: role.description }));

  return (
    <div>
      <span>{t('Reviewer type') + ' *'}</span>
      <SelectRadioButton
        required
        options={getOptions()}
        value={pickerState.type}
        onChange={(value) => {
          const newState: ReviewerTypePickerState = { ...pickerState, type: value as ReviewerType };
          handleOnChange(newState);
        }}
        className="flex flex-col mt-1 gap-y-1"
      />
      {pickerState.type == 'reviewers' && (
        <Select
          options={reviewerRoles}
          defaultItem={{ label: t('Choose reviewer role') + '...' }}
          value={pickerState.roleId.toString()}
          onChange={(value) => {
            const newState: ReviewerTypePickerState = { ...pickerState, roleId: parsePositiveNumber(value) ?? 0 };
            handleOnChange(newState);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  roleById: selectTrackRoles(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerTypePicker);
