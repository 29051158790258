import React from 'react';
import { Button, CancelButton } from '../ui';
import { Review } from '../../store/review/types';
import { Answer, Form, Question } from '../../store/form/types.d';
import { OpenSnackBarDTO } from '../../store/error/types';
import TabForms from '../TabForms/TabForms';
import { isCompleted } from '../../helpers/form';
import { ValidatedField } from '../../store/api.d';
import useTheme from '@material-ui/core/styles/useTheme';
import styled, { ThemeProvider } from 'styled-components';
import ChipMessage from '../ui/display/Chip/ChipMessage';
import { useTranslation } from 'react-i18next';
import { Submission } from '../../store/submission/types';

interface Props {
  review: Review;
  submission?: Submission;
  openSnackBarAction?: (data: OpenSnackBarDTO) => void;
  formsData: { form: Form; questions: Question[]; answers: Answer[] }[];
  onCancelClick?: () => void;
  onSaveHandler?: (data: Answer[]) => void;
  validationErrors?: ValidatedField[];
  canViewFeedback: boolean;
}

const ReviewForm: React.FC<Props> = ({
  review,
  submission,
  openSnackBarAction,
  formsData,
  onCancelClick,
  onSaveHandler,
  validationErrors,
  canViewFeedback,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [answers, setAnswers] = React.useState<Answer[]>(review.answers ?? []);

  /* Here is where we replace answers by those in the current form */
  const formsData_ = formsData.map(({ form, questions }) => {
    const questionIds = questions.map((question) => question.id);
    return { form, questions, answers: answers.filter((answer) => questionIds.includes(answer.question_id)) };
  });

  const completed = isCompleted(formsData_, review);

  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-grow h-full flex-col">
        {/* PAGE TITLE */}
        <StyledHeader className="max-w-6xl">
          <div className="flex max-w-6xl flex-row justify-between">
            {submission && (
              <h2 className="font-bold text-xl mr-3">
                {t('Edit review') + `: #${submission.external_id} ${submission.title}`}
              </h2>
            )}

            {canViewFeedback && !completed && (
              <StyledChipMessage message={t('Required questions pending')} colorType="warning" className="mb-1" />
            )}
          </div>
        </StyledHeader>
        <div className="tab-forms-wrapper">
          <TabForms
            data={{ answerable: review, formsData: formsData_ }}
            answerQuestionHandler={(data) => {
              const newAnswers = [...answers];
              const idx = newAnswers.findIndex((answer) => answer.question_id == data.question_id);
              if (idx != -1) {
                newAnswers[idx] = { ...newAnswers[idx], ...data };
              }
              setAnswers(newAnswers);
            }}
            openSnackBarAction={openSnackBarAction}
            validationErrors={validationErrors}
            mode="edit"
            canViewFeedback={canViewFeedback}
          />
        </div>
        {/* FOOTER BUTTONS */}
        {(onCancelClick || onSaveHandler) && (
          <StyledButtonsWrapper>
            {onCancelClick && <CancelButton onClick={onCancelClick}>{t('Cancel')}</CancelButton>}
            {onSaveHandler && (
              <Button className="w-48" onClick={() => onSaveHandler(answers)}>
                {completed ? t('Submit') : t('Save')}
              </Button>
            )}
          </StyledButtonsWrapper>
        )}
      </div>
    </ThemeProvider>
  );
};

export default ReviewForm;

const StyledHeader = styled.div`
  border-bottom: 2px dotted ${(props) => props.theme.palette.secondary.dark};
  width: 100%;
  margin-bottom: 1.75rem;

  h2 {
    margin-right: 0.75rem;
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
  background-color: #fff;
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
`;

const StyledChipMessage = styled(ChipMessage)`
  white-space: nowrap;
  height: fit-content;
`;
