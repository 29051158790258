import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#0044F0' }: IconProps): JSX.Element {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="viewNewIcon"
    >
      <path
        d="M18.9 7.5C18.9 7.5 15.3565 1 9.45 1C3.54349 1 0 7.5 0 7.5C0 7.5 3.54349 14 9.45 14C15.3565 14 18.9 7.5 18.9 7.5ZM1.3819 7.5C1.45296 7.39433 1.52404 7.28653 1.61781 7.15716C2.12993 6.41757 2.70696 5.72546 3.34214 5.08854C4.08005 4.18007 5.01111 3.44775 6.06762 2.9447C7.12412 2.44164 8.27947 2.18042 9.44952 2.18042C10.6196 2.18042 11.7749 2.44164 12.8314 2.9447C13.8879 3.44775 14.819 4.18007 15.5569 5.08854C16.1921 5.72546 16.7692 6.41757 17.2813 7.15716C17.38 7.28653 17.4461 7.39335 17.5171 7.5C17.4461 7.60567 17.3751 7.71345 17.2813 7.84381C16.7692 8.58306 16.192 9.27486 15.5569 9.91146C14.819 10.8199 13.8879 11.5523 12.8314 12.0553C11.7749 12.5584 10.6196 12.8196 9.44952 12.8196C8.27947 12.8196 7.12412 12.5584 6.06762 12.0553C5.01111 11.5523 4.08005 10.8199 3.34214 9.91146C2.707 9.27486 2.12997 8.58306 1.61781 7.84381C1.52404 7.71543 1.45296 7.60567 1.3819 7.5Z"
        fill={color}
      />

      <path
        d="M9.57397 4.59499C8.98221 4.59499 8.40378 4.77013 7.91174 5.0989C7.41971 5.42766 7.03627 5.89496 6.80981 6.44167C6.58336 6.98839 6.52408 7.59029 6.63953 8.17068C6.75497 8.75107 7.03994 9.28398 7.45837 9.70241C7.87681 10.1209 8.40997 10.4058 8.99036 10.5213C9.57075 10.6367 10.1723 10.5773 10.719 10.3509C11.2657 10.1244 11.733 9.74132 12.0618 9.24929C12.3905 8.75726 12.566 8.17845 12.566 7.58669C12.5658 6.79325 12.2504 6.03251 11.6893 5.47146C11.1283 4.91041 10.3674 4.59526 9.57397 4.59499ZM5.38501 7.58669C5.38481 6.75814 5.63028 5.94806 6.09045 5.25906C6.55063 4.57005 7.20479 4.0333 7.97022 3.71609C8.73564 3.39888 9.57797 3.3158 10.3906 3.47732C11.2033 3.63884 11.9498 4.03751 12.5358 4.62331C13.1217 5.20912 13.5208 5.95572 13.6825 6.76833C13.8442 7.58095 13.7612 8.42325 13.4442 9.18874C13.1272 9.95424 12.5903 10.6083 11.9014 11.0686C11.2125 11.529 10.4025 11.7747 9.57397 11.7747C8.46279 11.7736 7.39751 11.3317 6.61206 10.5457C5.82661 9.75967 5.38527 8.69397 5.38501 7.58279V7.58669Z"
        fill={color}
      />
    </svg>
  );
}

export default Icon;
