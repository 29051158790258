import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '../Button';

interface Props extends Omit<ButtonProps, 'variant'> {}

export const CancelButton = ({ className, ...rest }: Props): JSX.Element => {
  return <StyledCancel className={`cancelButton ${className}`} type="button" variant="text" {...rest} />;
};

const StyledCancel = styled(Button)`
  color: ${(props) => props.theme.palette.error.main};
  font-weight: 700 !important;
  cursor: pointer;
`;
