import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '../ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MiniButton } from '../ui/inputs/MiniButton/MiniButton';
import { Notifiable, NotifyDTO } from '../../store/table/types';
import { AppState } from '../../store/state';
import { selectAssignmentState } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';

interface Props extends GridCellProps {
  updatingId: number | null;
  idKey: string;
  handleAssignedClick?: (id: number, assigned: boolean) => void;
  notifiableIds: {
    [id: number]: Notifiable;
  };
  handleNotifyClick: (data: NotifyDTO) => void;
}

const AssignedCell: React.FC<Props> = ({
  updatingId,
  idKey,
  handleAssignedClick,
  notifiableIds,
  handleNotifyClick,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  const field = props.field || '';
  const value = props.dataItem[field];
  const modelId = props.dataItem[idKey];
  const assignmentId: number = props.dataItem.assignment_id ?? 0;
  const notifiable = notifiableIds[assignmentId];

  return (
    <StyledTd
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      {updatingId == modelId ? (
        <p>{t('Working...')}</p>
      ) : (
        <div className="flex items-center">
          <Checkbox
            checked={value}
            onChange={(event) => {
              if (handleAssignedClick) {
                handleAssignedClick?.(modelId, event.target.checked);
              }
            }}
          />
          {notifiable && (
            <MiniButton
              labelText={notifiable.isDone ? t('Notified') : t('Notify')}
              success={notifiable.isDone}
              onButtonClick={notifiable.isDone ? undefined : () => handleNotifyClick({ assignment_id: assignmentId })}
            />
          )}
        </div>
      )}
    </StyledTd>
  );
};

const mapStateToProps = (state: AppState) => ({
  updatingId: selectAssignmentState(state).updatingId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AssignedCell);

const StyledTd = styled.td`
  .notify {
    color: ${(props) => props.theme.palette.primary.main};
    border-color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
  }

  .notified {
    color: #1f9801;
    background-color: #e4f0e1;
  }
`;
