import React from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { InputText } from '../../ui';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props extends SimpleDialogProps {
  onSaveAs: (name: string) => void;
}

const SaveTableSettingsDialog: React.FC<Props> = ({ onSaveAs, ...rest }) => {
  const { t, i18n } = useTranslation();
  const [name, setName] = React.useState<string>('');

  return (
    <SimpleDialog {...rest} title={t('Save current view')} disableOkButton={!name} handleOk={() => onSaveAs(name)}>
      <InputText label={t('Name')} value={name} onChange={(event) => setName(event.target.value)} />
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SaveTableSettingsDialog);
