import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" className="chevronDownButton">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="currentColor"
          fillRule="nonzero"
          stroke="currentColor"
          strokeWidth="0.833"
          transform="translate(-189 -329)"
        >
          <g transform="translate(30 138)">
            <g transform="translate(0 185)">
              <path
                d="M166.878 9.21l-4.167-4.09a.422.422 0 00-.589 0 .404.404 0 000 .578l3.872 3.802-3.872 3.802a.404.404 0 000 .578.42.42 0 00.59 0l4.166-4.09a.405.405 0 000-.58z"
                transform="rotate(90 164.5 9.5)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
