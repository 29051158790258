import { AssignableType, UpdateAssignmentDTO } from './types';
import { selectTrackRoles } from '../selectors';
import { store } from '../store';

export const buildDTO = (
  modelId: number,
  assigned: boolean,
  field: 'person_role_id' | 'assignable_id',
  value: number,
  assignableType: AssignableType,
  toRoleId?: number,
  fromRoleId?: number,
): [UpdateAssignmentDTO, 'person_role_id' | 'assignable_id'] => {
  const dto: UpdateAssignmentDTO = {
    person_role_id: 0,
    assignable_id: 0,
    assignable_type: assignableType,
    assigned,
  };
  let keyBy: 'person_role_id' | 'assignable_id';
  switch (assignableType) {
    case 'App\\Models\\Submission': {
      dto[field] = value;
      keyBy = field == 'person_role_id' ? 'assignable_id' : 'person_role_id'; // Flip key
      dto[keyBy] = modelId;
      break;
    }
    case 'App\\Models\\PersonRole': {
      const roleById = selectTrackRoles(store.getState());
      const fromRole = roleById[fromRoleId ?? 0];
      const toRole = roleById[toRoleId ?? 0];
      if (fromRole.role_id == toRole.id) {
        // Natural direction e.g. AC -> SPC
        dto.person_role_id = value;
        dto.assignable_id = modelId;
      } else {
        // Reverse direction SPC -> AC
        dto.person_role_id = modelId;
        dto.assignable_id = value;
      }
      keyBy = 'person_role_id';
      break;
    }
  }
  return [dto, keyBy];
};
