import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#0044F0' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="none" viewBox="0 0 120 120">
      <path
        fill={color}
        fillRule="evenodd"
        d="M65 90h35c8.284 0 15-6.716 15-15V25c0-8.284-6.716-15-15-15H20c-8.284 0-15 6.716-15 15v50c0 8.284 6.716 15 15 15h35v10H40a5 5 0 0 0 0 10h40a5 5 0 0 0 0-10H65zM20 20a5 5 0 0 0-5 5v50a5 5 0 0 0 5 5h80a5 5 0 0 0 5-5V25a5 5 0 0 0-5-5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
