import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DialogContentText from '@material-ui/core/DialogContentText';
import submissionSlice from '../../../store/submission/slice';
import { Submission } from '../../../store/submission/types';
import { SelectRadioButton } from '../../ui';
import { updatePaperStatusBulkDTO } from '../../../store/info/types';
import { selectPaperStatusState } from '../../../store/selectors';
import { PaperStatus } from '../../../store/paper-status/types';
import { useTranslation } from 'react-i18next';

interface Props extends SimpleDialogProps {
  submission: Submission;
  paperStatusById: { [key: number]: PaperStatus };
  deleteSubmissionAction: (submissionId: number) => void;
  updatePaperStatusBulkAction: (data: updatePaperStatusBulkDTO) => void;
}

type deleteOption = 'withdraw' | 'delete';

const DeleteSubmissionDialog: React.FC<Props> = ({
  submission,
  paperStatusById,
  deleteSubmissionAction,
  updatePaperStatusBulkAction,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const options: { id: string; label: string }[] = [
    { id: 'withdraw', label: t('No, just set its paper status to Withdrawn.') },
    { id: 'delete', label: t(`Yes. This action can't be undone.`) },
  ];

  const [value, setValue] = useState<deleteOption | undefined>(
    paperStatusById[submission.paper_status_id].name == 'Withdrawn' ? 'withdraw' : undefined,
  );
  return (
    <SimpleDialog
      {...rest}
      title={`${t('Delete submission')} #${submission.external_id}`}
      disableOkButton={!value}
      handleOk={() => {
        if (value === 'withdraw') {
          const find = Object.values(paperStatusById).find((paperStatus) => paperStatus.name === 'Withdrawn');
          if (find) {
            updatePaperStatusBulkAction({ submissionIds: [submission.id], paper_status_id: find.id });
          }
        } else if (value === 'delete') {
          deleteSubmissionAction(submission.id);
        } else {
          throw new Error('Invalid value.');
        }
      }}
    >
      <div>
        <StyledDialogContentText id="alert-dialog-description">
          {t('Are you sure you want to delete this submission?')}
        </StyledDialogContentText>
        <StyledRadio className="mt-3">
          <SelectRadioButton
            className="flex flex-col"
            value={value}
            options={options}
            onChange={(value) => setValue(value as deleteOption)}
          />
        </StyledRadio>
      </div>
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  paperStatusById: selectPaperStatusState(state).paperStatusById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deleteSubmissionAction: (submissionId: number) => dispatch(submissionSlice.actions.REMOVE_SUBMISSION(submissionId)),
  updatePaperStatusBulkAction: (data: updatePaperStatusBulkDTO) =>
    dispatch(submissionSlice.actions['UPDATE:PAPER-STATUS:BULK'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSubmissionDialog);

const StyledDialogContentText = styled(DialogContentText)`
  font-size: 0.875rem;
`;

const StyledRadio = styled.div`
  .flex-col > div {
    margin-bottom: 0.5rem;
  }
`;
