import Avatar from '@material-ui/core/Avatar';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PlusIcon from '../../icons/Plus';
import React from 'react';
import useStyles from './Avatar.styles';
import { User } from '../../store/user/types';
import TrashCan from '../../icons/TrashCan';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';

export interface AvatarFormProps {
  user: User;
  save_handler: (avatar: File) => void;
  delete_handler: () => void;
}
const AvatarForm: React.FC<AvatarFormProps> = (props) => {
  const classes = useStyles();
  const fileInput = React.createRef<HTMLInputElement>();
  const { user, save_handler, delete_handler } = props;
  const { t, i18n } = useTranslation();

  const theme = useTheme();

  const on_input_handler = () => {
    if (fileInput.current?.files?.length) {
      const file = fileInput.current.files[0];
      save_handler(file);
    }
  };
  return (
    <div className={classes.paper}>
      <Avatar alt={user.person.first_name} src={user.person.avatar_url ?? undefined} className={classes.large} />
      <div className={classes.root}>
        <input
          accept="image/*"
          className={classes.input}
          id="icon-button-file"
          type="file"
          ref={fileInput}
          onInput={on_input_handler}
        />
        <label htmlFor="icon-button-file">
          <Tooltip title={t('Upload')}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              className="profilePlusWrapper"
              style={{ width: '3rem' }}
            >
              <PlusIcon />
            </IconButton>
          </Tooltip>
        </label>
        <Tooltip title={t('Delete')}>
          <IconButton aria-label="delete" onClick={delete_handler}>
            <TrashCan stroke={theme.palette.primary.main} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default AvatarForm;
