import React from 'react';
import { Card, Link } from '../ui';
import EditIcon from '../../icons/Edit';
import TrashCan from '../../icons/TrashCan';
import { Role } from '../../store/conference/types';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { doesHandleReviewers } from '../../helpers/role';

interface Props {
  role: Role;
  handleEdit: () => void;
  handleDeleteRole?: () => void;
  canAccessSummarizedViews?: boolean;
  roleById?: { [key: string]: Role };
}

const RoleCard: React.FC<Props> = ({ role, handleEdit, handleDeleteRole, canAccessSummarizedViews, roleById }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const roleDescription = role.role_id ? roleById?.[role.role_id ?? 0].description : '';

  return (
    <Card>
      <h3 className="font-medium text-base">
        <Link className="flex items-center cursor-pointer capitalize" onClick={handleEdit}>
          {role.description}
        </Link>
      </h3>

      <ul style={{ minHeight: '71px' }}>
        {role.description !== 'author' && (
          <li>
            <span className="font-bold">{t('Reviewer type: ')}</span>
            {doesHandleReviewers(role)
              ? t('Handles reviewers of type {{role}}', { role: roleDescription })
              : t('Handles submissions')}
          </li>
        )}
        {role.description !== 'author' && (
          <li>
            <span className="font-bold">{t('Access to summarized views: ')}</span>
            {canAccessSummarizedViews ? t('Yes') : t('No')}
          </li>
        )}
      </ul>

      <div className="flex justify-between w-full left-0 px-5 text-sm absolute bottom-4">
        <Link className="flex items-center cursor-pointer" onClick={handleEdit}>
          <EditIcon color={theme.palette.primary.main} className="mr-1" />
          {t('Edit')}
        </Link>

        {handleDeleteRole && (
          <div className="cursor-pointer w-4" onClick={handleDeleteRole}>
            <TrashCan stroke={theme.palette.primary.main} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default RoleCard;
