import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { Submission } from '../store/submission/types';
import submissionSlice from '../store/submission/slice';
import {
  selectBidState,
  selectInfo,
  selectKeywordState,
  selectPaperStatusState,
  selectSubmissionState,
  selectTrackRoles,
} from '../store/selectors';
import { connect } from 'react-redux';
import Loading from '../components/Loading/Loading';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../store/info/types';
import { getSubmissionRow } from '../helpers/table';
import { getMenuItemName } from '../helpers/translations';
import { PaperStatus } from '../store/paper-status/types';
import MyGridRedux from '../components/MyGridRedux/MyGridRedux';
import { useTranslation } from 'react-i18next';
import HeaderEditBiddingAssignment from '../components/HeaderEditBiddingAssignment/HeaderEditBiddingAssignment';
import { Role } from '../store/conference/types';
import infoSlice from '../store/info/slice';
import { getRouteByName } from '../router/routes';
import history from '../store/history';
import { fillRoutePath } from '../helpers/path';
import { MenuItemName } from '../layouts/types';
import { Bid, BidOption, ReviewerTableRow } from '../store/bid/types';
import { getOtherBids } from '../store/bid/sagas';
import { getReviewer } from '../helpers/users';

interface Props {
  getSubmissionsAction: () => void;
  submissionsById: { [key: string]: Submission };
  loading: boolean;
  keywordById: { [key: number]: Keyword };
  paperStatusById: { [key: number]: PaperStatus };
  info: Info;
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  bidOptionsById: { [key: number]: BidOption };
}

const ReviewerBidsPage = ({
  getSubmissionsAction,
  submissionsById,
  loading,
  keywordById,
  paperStatusById,
  info,
  roleById,
  getInfoAction,
  bidOptionsById,
}: Props) => {
  const { t, i18n } = useTranslation();
  const params: any = useParams();
  const id = parseInt(params.id);

  const [bids, setBids] = useState<Bid[]>([]);
  const [bidsLoading, setBidsLoading] = useState<boolean>(true);

  const friendlyName: TableFriendlyName = 'reviewer_bids';

  const chairInfo = info as ChairInfo;
  const reviewer: ReviewerTableRow | undefined = getReviewer(id, chairInfo.users);

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      getInfoAction({ friendlyName: 'users' });
    } else if (reviewer) {
      /* Fetch reviewer bids */
      getOtherBids({ person_role_id: id }).then((result) => {
        if (result.type == 'ok') {
          setBids(result.value.data);
          setBidsLoading(false);
        }
      });
    }
  }, [info['users'].byId]);

  if (loading || bidsLoading) {
    return <Loading />;
  } else if (!reviewer) {
    return <div>Reviewer #{id} not found</div>;
  }

  /* Generate data for table */
  const inputData: unknown[] = [];
  bids.forEach((bid) => {
    const newRegister = {
      ...getSubmissionRow(submissionsById[bid.submission_id], true, keywordById, paperStatusById, t),
      bid: bid.bid_option_id ? bidOptionsById[bid.bid_option_id].name : undefined,
      conflict: bid.conflict,
    };

    inputData.push(newRegister);
  });

  /* Generate header */
  const headerContent = {
    title: reviewer.full_name ?? '',
    row1: {
      title: t('Role'),
      content: roleById[reviewer.role_id].description,
    },
    row2: {
      title: t('Email'),
      content: reviewer?.email ?? '',
    },
    row3: {
      title: t('Main area'),
      content:
        reviewer && reviewer?.keyword_ids.length > 0
          ? (reviewer?.keyword_ids
              .map((id) => {
                const parentId = keywordById[id].parent_id;
                return parentId && keywordById[parentId].name + '. ';
              })
              .filter((item, index, self) => self.indexOf(item) === index) as string[]) //to avoid duplicate main areas
          : t('The reviewer has not selected keywords.'),
    },
    row4: {
      title: t('Keywords'),
      content:
        reviewer && reviewer.keyword_ids.length > 0
          ? (reviewer?.keyword_ids.map((id) => keywordById[id].name + '. ') as string[])
          : t('The reviewer has not selected keywords.'),
    },
  };

  const parentRoute = getRouteByName('RouteAllReviewersBids');

  return (
    <div className="flex flex-col h-full">
      <HeaderEditBiddingAssignment
        onPageTitleClick={() => history.push(fillRoutePath(parentRoute.path))}
        originPageTitle={getMenuItemName(parentRoute.main_menu_option as MenuItemName, t)}
        headerContent={headerContent}
      />
      <div className="mt-8" />
      <MyGridRedux friendlyName={friendlyName} inputData={inputData as Record<string, unknown>[]} />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  submissionsById: selectSubmissionState(state).submissionsById,
  loading: selectSubmissionState(state).loading || selectInfo(state).loading,
  keywordById: selectKeywordState(state).keywordById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  info: state.info.info,
  roleById: selectTrackRoles(state),
  bidOptionsById: selectBidState(state).bidOptions.byId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: () => dispatch(submissionSlice.actions.GET_SUBMISSIONS()),
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerBidsPage);
