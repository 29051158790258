import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { selectInfo, selectKeywordState, selectTable, selectTrackRoles } from '../../store/selectors';
import { Role } from '../../store/conference/types';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import infoSlice from '../../store/info/slice';
import Loading from '../../components/Loading/Loading';
import { formatKeywordColumn } from '../../helpers/table';
import { useTranslation } from 'react-i18next';
import { CustomColumnsDataGet } from '../../store/table/types';
import tableSlice from '../../store/table/slice';

interface Props {
  getInfoAction: (data: InfoGetDTO) => void;
  info: Info;
  loading: boolean;
  roleById: { [key: string]: Role };
  keywordById: { [key: number]: Keyword };
  customColumnsData: { [key: string]: { [key: number]: { [key: string]: any } } };
  getCustomColumnsDataAction: (data: CustomColumnsDataGet) => void;
}

const AllReviewersBiddingPage: React.FC<Props> = ({
  getInfoAction,
  info,
  loading,
  roleById,
  keywordById,
  customColumnsData,
  getCustomColumnsDataAction,
}) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'reviewers_bids';

  const fetchData = () => {
    getInfoAction({ friendlyName: 'users' });
    getInfoAction({ friendlyName });
  };

  const fetchCustomColumnsData = () => {
    getCustomColumnsDataAction({ tableFriendlyName: friendlyName });
  };

  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      fetchData();
    } else if (!customColumnsData[friendlyName]) {
      fetchCustomColumnsData();
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  const chairInfo = info as ChairInfo;

  /* Build input data */
  const inputData: unknown[] = [];
  Object.values(chairInfo.users.byId).forEach((register) => {
    register.role_ids.forEach((roleId, index) => {
      const role = roleById[roleId];
      const personRoleId = register.person_role_ids[index];
      if (role.type == 'reviewer') {
        const newRegister = {
          ...register,
          id: personRoleId,
          role_id: roleId,
          person_role_id: personRoleId,
        };

        // Add Role description
        // @ts-ignore
        newRegister.role = roleById[roleId].description;

        formatKeywordColumn(newRegister, keywordById);

        inputData.push(newRegister);
      }
    });
  });

  return (
    <div className="flex flex-col h-full">
      <div className="h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={fetchData}
            getCustomColumnsData={fetchCustomColumnsData}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  info: state.info.info,
  loading: selectInfo(state).loading,
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
  customColumnsData: selectTable(state).customColumnsData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  getCustomColumnsDataAction: (data: CustomColumnsDataGet) =>
    dispatch(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllReviewersBiddingPage);
