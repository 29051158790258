import React from 'react';
import '../../components/Dashboard/Dashboard.styles.css';
import DashboardPage from './DashboardPage';
import history from '../../store/history';
import { fillRoutePath } from '../../helpers/path';
import { getRouteByName } from '../../router/routes';
import { AppState } from '../../store/state';
import { selectCurrentRole } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { can } from '../../helpers/permissions';

interface Props {}

const DashboardPageController: React.FC<Props> = () => {
  if (can('ACCESS_SUMMARIZED_VIEWS')) {
    return <DashboardPage />;
  } else {
    history.push(fillRoutePath(getRouteByName('RouteConferenceHome').path));
    return <></>;
  }
};

const mapStateToProps = (state: AppState) => ({
  role: selectCurrentRole(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageController);
