import React, { useEffect, useState } from 'react';
import { UserAlias } from '../../store/user/types';
import { Button, Link, RadioButton, CancelButton } from '../ui';
import styled from 'styled-components';
import { AddButton } from '../ui/inputs/AddButton/AddButton';
import useTheme from '@material-ui/core/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import AddProfileEmailDialog from '../dialogs/AddProfileEmailDialog/AddProfileEmailDialog';
import TrashCan from '../../icons/TrashCan';
import SimpleDialog from '../dialogs/SimpleDialog/SimpleDialog';
import { connect } from 'react-redux';
import userSlice from '../../store/user/slice';
import { AppDispatch } from '../../store/store';
import { AppState } from '../../store/state';
import { useTranslation, Trans } from 'react-i18next';

export interface VerifyCode {
  email: string;
  code: string;
}

export interface MainEmailFormProps {
  user_alias_by_id: { [key: number]: UserAlias };
  main_user_id: number;
  save_handler: (user_id: number) => void;
  onCancelClick: () => void;
  deleteUserAliasAction: (userAliasId: number) => void;
}

const MainEmailForm: React.FC<MainEmailFormProps> = ({
  user_alias_by_id,
  main_user_id,
  save_handler,
  onCancelClick,
  deleteUserAliasAction,
}) => {
  const { t, i18n } = useTranslation();

  const [checked, setChecked] = React.useState<number>(main_user_id);
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  const theme = useTheme();

  useEffect(() => {
    setChecked(main_user_id);
  }, [user_alias_by_id]);

  // Function to handle the delete email address dialog
  const handleDeleteAlias = (userAlias: UserAlias) => {
    const email = userAlias.email;
    setDialog(
      <SimpleDialog
        open={true}
        handleClose={() => setDialog(undefined)}
        handleOk={() => {
          deleteUserAliasAction(userAlias.id);
        }}
        title={t('Remove email address?')}
      >
        <p className="text-sm mb-2">
          <Trans i18nKey="emailRemoveWarning">
            Are you sure you want to remove the address <span className="font-bold">{{ email }}</span>? Take into
            account that this action can't be undone.
          </Trans>
        </p>
      </SimpleDialog>,
    );
  };

  const listItems = Object.values(user_alias_by_id).map((userAlias, index) => {
    const { id: value, email, email_verified_at } = userAlias;

    return (
      <div key={index} className={`mb-4 w-max relative flex items-center ${!email_verified_at ? 'itemDisabled' : ''}`}>
        <RadioButton
          name="options"
          label={email}
          checked={value === checked}
          value={value}
          onChange={(e) => setChecked(parseInt(e.target.value))}
          disabled={!email_verified_at}
        />
        {value === main_user_id && (
          <div
            className="rounded-full text-xs text-white px-1.5 py-0.5"
            style={{
              backgroundColor: `${theme.palette.primary.main}`,
            }}
          >
            {t('Main')}
          </div>
        )}
        {!email_verified_at && (
          <Link
            onClick={() => {
              setDialog(<AddProfileEmailDialog initEmail={email} handleClose={() => setDialog(undefined)} />);
            }}
            className="text-sm underline ml-2 cursor-pointer"
          >
            <Tooltip title={t('Click to verify email')} placement="right">
              <div className="text-xs">{t('Verify email')}</div>
            </Tooltip>
          </Link>
        )}
        {value !== main_user_id && (
          <div className="ml-2 cursor-pointer" onClick={() => handleDeleteAlias(userAlias)}>
            <TrashCan />
          </div>
        )}
      </div>
    );
  });

  return (
    <>
      <StyledProfileContentWrapper>
        <div className="mb-9">
          <p className="text-2xl mt-14 mb-2 text-center font-semibold text-gray-600">{t('E-mail')}</p>
          <p className="mb-3 text-center text-gray-600">{t('Change main email.')}</p>
        </div>

        <div className="flex flex-col mb-4">
          <div className="mb-3">{listItems}</div>

          <AddButton
            label={t('Add email address')}
            onClick={() => {
              setDialog(<AddProfileEmailDialog handleClose={() => setDialog(undefined)} />);
            }}
          />
        </div>
      </StyledProfileContentWrapper>

      <StyledButtons>
        <CancelButton
          onClick={() => {
            setChecked(main_user_id);
            onCancelClick();
          }}
        >
          {t('Cancel')}
        </CancelButton>

        <Button
          className="w-48 place-self-center"
          onClick={() => save_handler(checked)}
          disabled={main_user_id === checked}
        >
          <span className="px-14">{t('Save')}</span>
        </Button>
      </StyledButtons>

      {dialog}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deleteUserAliasAction: (userAliasId: number) => dispatch(userSlice.actions['DELETE:ALIAS'](userAliasId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainEmailForm);

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  padding: 1rem 0;
  position: sticky;
  bottom: 0;
  background-color: rgb(237, 243, 255);
  z-index: 10;
`;

const StyledProfileContentWrapper = styled.div`
  max-width: 384px;
  margin: 0 auto;
  flex-grow: 1;

  .addButton {
    margin-left: 0;
  }
  .itemDisabled {
    input,
    label {
      opacity: 0.4;
    }
    label {
      margin-right: 0;
    }
  }
`;
