import React, { useState } from 'react';
import { Menu, MenuItem, MenuSubItem } from '../components/ui';
import { MainItems, MenuItems } from './types';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import history from '../store/history';
import { getRouteByName, getRouteByPath } from '../router/routes';
import { Link } from '../components/ui';
import { Conference, Track } from '../store/conference/types';
import { AddButton } from '../components/ui/inputs/AddButton/AddButton';
import { useTranslation, Trans } from 'react-i18next';
import { getMenuItemName } from '../helpers/translations';
import Message from '../components/ui/display/Message/Message';
import MonitorNoArrows from '../icons/MonitorNoArrows';
import { useWindowSize } from '../helpers/window';

type Props = {
  main_items: MainItems;
  selectedOption: string | null;
  doClick: (item: MenuItems, isMenuSubitem: boolean) => void;
  logo: React.ReactNode;
  header: React.ReactNode;
  menuOpen: boolean;
  handleAvatarClick?: () => void;
  userName: string;
  children: React.ReactNode;
  isImpersonated?: boolean;
  currentConference?: Conference | null;
  leaveImpersonationAction?: () => void;
  avatarImageExists?: string | null;
  currentTrack: Track | null;
};

const MainLayout: React.FC<Props> = ({
  main_items,
  selectedOption,
  doClick,
  logo,
  header,
  menuOpen,
  userName,
  children,
  isImpersonated,
  leaveImpersonationAction,
  currentTrack,
}) => {
  const { t, i18n } = useTranslation();
  const betaVersion = true;

  const isMobile = useWindowSize() < 800;

  const parentName = main_items.find(
    (parent) => parent.subitems && parent.subitems.some((subitem) => subitem.name === selectedOption),
  )?.name;

  const [expandedItems, setExpandedItems] = useState<string[]>([parentName !== undefined ? parentName : '']);

  const theme = useTheme();
  const route = getRouteByPath(window.location.pathname);
  const menuItems = main_items.map((item, index) => {
    const selected =
      selectedOption === item.name || item.subitems?.find((value) => value.name === selectedOption) !== undefined;
    const iconProps = selected ? { stroke: theme.palette.primary.main } : {};

    return (
      <MenuItem
        key={index}
        // @ts-ignore
        icon={React.createElement(item.icon, iconProps)}
        label={getMenuItemName(item.name, t)}
        selected={selected}
        color="primary"
        onClick={() => {
          setExpandedItems((itemsList) => {
            if (itemsList.includes(item.name)) {
              return itemsList.filter((i) => i !== item.name);
            } else {
              return [item.name];
            }
          });
          doClick(item, false);
        }}
        expanded={expandedItems.includes(item.name)}
      >
        {item.subitems?.map((subitem) => {
          return (
            <MenuSubItem
              key={subitem.name}
              label={getMenuItemName(subitem.name, t)}
              selected={selectedOption === subitem.name}
              color="primary"
              onClick={() => {
                setExpandedItems((prevExpandedItems) => [item.name]);
                doClick(subitem, true);
              }}
            />
          );
        })}
      </MenuItem>
    );
  });

  const mainBackground =
    route.name === 'RouteConferences' ||
    route.name === 'RouteEditSubmission' ||
    route.name === 'RouteDetailSubmission' ||
    route.name === 'RouteNewSubmission' ||
    route.name === 'RouteEditReview'
      ? '#ffffff'
      : '#edf3ff';

  return (
    <>
      {isMobile ? (
        <div className="ressolutionMessageWrapper">
          <StyledMessage handleClose={() => {}} className="ressolutionMessage mt-auto mb-10 mx-auto w-full">
            <MonitorNoArrows />
            <p className="title mb-4">
              <span className="font-bold">{t('chairingtool')}</span> {t('is a desktop-first platform')}
            </p>
            <p className="text">
              {t(
                'We’re glad you’re here! However, our tool is designed exclusively for desktop use (not available on mobile devices). To access all features and get started, please visit us on a desktop computer.',
              )}
            </p>
          </StyledMessage>
        </div>
      ) : (
        <StyledMainContent className="flex h-full flex-col">
          {/* IMPERSONATED BAR */}
          {isImpersonated && (
            <StyledImpersonatedBar>
              <Trans i18nKey="userImpersonatedBanner">
                You are impersonated as <strong>{{ userName }}</strong>. To leave impersonation,{' '}
                <Link onClick={(e) => leaveImpersonationAction?.()} className="cursor-pointer ml-1 underline">
                  click here
                </Link>
                .
              </Trans>
            </StyledImpersonatedBar>
          )}

          <div className={`flex ${isImpersonated ? 'mainContentImpersonation' : 'h-full'}`}>
            {currentTrack && route.name !== 'RouteConferences' && (
              <>
                {/* LATERAL MENU */}
                <StyledMenuBarWrapper
                  className="flex flex-col menuWrapper"
                  style={{ backgroundColor: theme.palette.secondary.main }}
                >
                  {/* LOGO WRAPPER */}
                  <div className="h-16 flex items-center border-b border-gray-200">
                    <div className="relative logoWrapper">{logo}</div>
                  </div>

                  {/* LATERAL MENU: INSIDE OF TRACK */}
                  <div className="flex-grow overflow-y-auto relative flex flex-col justify-between">
                    <Menu open={!menuOpen} className="pr-1">
                      {menuItems.length > 0 &&
                        [
                          <div key="hide-menu" className="text-gray-400 mb-3">
                            {/*<MenuItem
                            icon={<MenuLeftIcon />}
                            label={getMenuItemName('Hide menu', t)}
                            onClick={() => {
                              doClick(
                                {
                                  name: 'Hide menu',
                                  route_name: 'RouteConferenceHome',
                                  icon: MenuLeftIcon,
                                },
                                false,
                              );
                            }}
                          />*/}
                          </div>,
                        ].concat(menuItems)}
                    </Menu>

                    {/* REQUEST CONFERENCE BUTTON */}
                    {!isImpersonated && (
                      <StyledRequestConference
                        className="flex items-center pt-4 pb-4"
                        style={{ color: theme.palette.primary.main }}
                      >
                        <AddButton
                          label={t('Request Conference')}
                          menuOpen={menuOpen}
                          onClick={() => history.push(getRouteByName('RouteConferencesCreate').path)}
                        />
                      </StyledRequestConference>
                    )}
                  </div>

                  <StyledFooter className="supportFooter border-t border-gray-50">
                    <p>
                      {t('Please feel free to contact us at')}
                      <a href="mailto:support@chairingtool.com" className="ml-1">
                        support@chairingtool.com
                      </a>
                    </p>
                  </StyledFooter>
                </StyledMenuBarWrapper>
              </>
            )}

            {/* MAIN CONTENT */}
            <div
              className="flex-grow flex flex-col overflow-x-auto relative mainContent"
              style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}
            >
              {/* HEADER */}
              <div className="flex-shrink-0 flex justify-between items-center h-16 px-8 border-b border-gray-200">
                {header}
              </div>

              {/* CONTENT */}
              <StyledChildrenWrapper
                className={`flex-grow overflow-auto h-full flex flex-col ${
                  currentTrack ||
                  route.name == 'RouteSettingsUser' ||
                  route.name == 'RouteConferencesCreate' ||
                  route.name == 'RouteSettingsConference'
                    ? ''
                    : 'homeBackground'
                }`}
                style={{ backgroundColor: mainBackground }}
              >
                {children}
              </StyledChildrenWrapper>
            </div>
          </div>
        </StyledMainContent>
      )}
    </>
  );
};

const StyledMenuBarWrapper = styled.div`
  background: rgb(244, 245, 250);
  background: linear-gradient(0deg, rgb(223, 232, 249) 0%, rgb(228, 235, 251) 13%, rgb(250, 250, 250) 55%);
  max-width: 14.5rem;
`;

const StyledFooter = styled.div`
  z-index: 10;
  background-color: rgb(223, 232, 249);
  width: 13rem;
  font-size: 0.775rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;

  p {
    line-height: 1.4;

    a {
      color: rgb(0, 68, 240);
    }
  }
`;

const StyledImpersonatedBar = styled.div`
  z-index: 100;
  position: relative;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));

  a {
    color: #000 !important;
  }
`;

const StyledMainContent = styled.div`
  .mainContentImpersonation {
    height: calc(100% - 24px);
  }
`;

const StyledChildrenWrapper = styled.div`
  padding: 1.5rem 1.5rem 0 1.5rem;
`;

const StyledRequestConference = styled.div`
  width: 100%;
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;

  .addButton {
    margin-left: 1rem;
  }
`;

const StyledMessage = styled(Message)`
  background-color: #fff !important;
  border-color: rgb(221, 221, 221);
  position: absolute;
  max-width: 470px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 20000;
  padding: 8rem 9rem 3rem 9rem;
  border-radius: 0.7rem;

  .content-wrapper {
    text-align: center;
    margin: 0;
    display: flex;
    flex-direction: column;

    svg {
      align-self: center;
      margin-bottom: 1.5rem;
    }

    .title {
      margin-bottom: 1.5rem;
      font-size: 1.6rem;
      font-weight: 300;
      color: #1e44f0;

      span {
        font-size: 1.6rem;
      }
    }

    .text {
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 2rem;
      margin-bottom: 4.5rem;
    }

    .close {
      font-size: 1.2rem;
      line-height: 2rem;
      color: #f00;
      cursor: pointer;
    }
  }

  .close-button {
    display: none;
  }
`;

export default MainLayout;
