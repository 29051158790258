import React from 'react';
import AddUserForm from '../AddUserForm/AddUserForm';
import styled from 'styled-components';
import TrashCan from '../../icons/TrashCan';
import { useTranslation } from 'react-i18next';
import { UserFormFields } from '../../store/user/types';

interface Props {
  users: UserFormFields[];
  onChange: (users: UserFormFields[]) => void;
}

const AddUserListForm: React.FC<Props> = ({ users, onChange }) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledUsersListWrapper>
      <div className="mt-2">
        <AddUserForm
          users={users}
          save_handler={(user) => {
            onChange([...users, user]);
          }}
        />
      </div>
      {users.map((user, index) => {
        const fullName = `${user.first_name} ${user.last_name}`;
        return (
          <StyledUsersList key={index}>
            <strong className="flex-grow-0">{fullName}</strong>
            <span className="spacer">|</span>
            <span className="flex-grow-0"> {user.email}</span> <span className="spacer">|</span>
            <span className="pr-4">{user.organization}</span>
            <div
              className="pl-5 flex items-center cursor-pointer ml-auto"
              onClick={() => {
                const updatedUsers = users.filter((user, pos) => pos !== index);
                onChange(updatedUsers);
              }}
            >
              <StyledTrash>
                <TrashCan />
              </StyledTrash>
              <div className="ml-1">{t(`Remove`)}</div>
            </div>
          </StyledUsersList>
        );
      })}
    </StyledUsersListWrapper>
  );
};

export default AddUserListForm;

const StyledUsersListWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const StyledUsersList = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  background-color: #e5e6ea;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.dark};
  .spacer {
    margin: 0 0.8rem;
  }
`;

const StyledTrash = styled.div`
  margin-top: -3px;
`;
