import React, { useState } from 'react';
import { InputText } from '../ui';
import useTheme from '@material-ui/core/styles/useTheme';
import { ThemeProvider } from 'styled-components';
import { getUserByEmail } from '../../store/user/sagas';
import SquaredAddButton from '../ui/inputs/SquaredAddButton/SquaredAddButton';
import { useTranslation } from 'react-i18next';
import { isValidEmailFormat } from '../../helpers/email';
import UserNotFoundDialog from '../dialogs/UserNotFoundDialog/UserNotFoundDialog';
import { UserFormFields } from '../../store/user/types';

interface Props {
  users: { email: string }[];
  save_handler: (data: UserFormFields) => void;
}

const AddUserForm: React.FC<Props> = ({ users, save_handler }) => {
  const emptyUser = { first_name: '', last_name: '', organization: '', email: '' };

  const { t, i18n } = useTranslation();
  const [dialog, setDialog] = React.useState<JSX.Element | undefined>(undefined);
  const [FData, setFData] = useState<UserFormFields>(emptyUser);
  const theme = useTheme();

  const handleAddClick = async () => {
    const res = await getUserByEmail(FData.email);
    switch (res.type) {
      case 'ok':
        const { data } = res.value;
        if (data.length) {
          // Case user found
          const foundUser = data[0];
          onSave(foundUser);
        } else {
          // Case user not found>
          setDialog(<UserNotFoundDialog data={FData} handleOk={onSave} handleClose={() => setDialog(undefined)} />);
        }
        break;
      case 'validation-error':
        break;
      case 'error':
        break;
    }
  };

  const isValid = () => {
    const exists = !!users.find((user) => user.email === FData.email);
    return isValidEmailFormat(FData.email) && !exists;
  };

  const onSave = (data: UserFormFields) => {
    setFData(emptyUser);
    save_handler(data);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="flex">
        <InputText
          value={FData.email}
          onChange={(event) => setFData({ ...FData, email: event.target.value })}
          className="mb-3 mr-3 w-72 flex-grow"
          placeholder={t('Enter email address...')}
        />
        <SquaredAddButton isValid={isValid()} handleOnClick={handleAddClick} />
      </div>
      {dialog}
    </ThemeProvider>
  );
};

export default AddUserForm;
