import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { selectAssignmentState, selectInfo, selectKeywordState, selectTrackRoles } from '../../store/selectors';
import { Role } from '../../store/conference/types';
import infoSlice from '../../store/info/slice';
import { formatKeywordColumn } from '../../helpers/table';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading/Loading';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { Review } from '../../store/review/types';

interface Props {
  info: Info;
  loading: boolean;
  roleById: { [key: number]: Role };
  keywordById: { [key: number]: Keyword };
  getInfoAction: (data: InfoGetDTO) => void;
  assignments: Review[];
}

const PeopleReviewerPage: React.FC<Props> = ({ loading, info, roleById, keywordById, getInfoAction, assignments }) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'people_to_review';

  const fetchData = () => {
    getInfoAction({ friendlyName });
  };

  useEffect(() => {
    if (Object.keys(info[friendlyName].byId).length === 0) {
      fetchData();
    }
  }, []);

  const chairInfo = info as ChairInfo;

  const { byId } = chairInfo[friendlyName];
  /* Build input data */
  const inputData: unknown[] = [];
  Object.values(byId).forEach((register) => {
    const role = roleById[register.role_id];

    const newRegister = {
      ...register,
      role: role.description, // Add Role description
    };

    formatKeywordColumn(newRegister, keywordById);

    inputData.push(newRegister);
  });

  return (
    <div className="flex flex-col h-full">
      {
        <div className="mb-2 h-full">
          {loading ? (
            <Loading />
          ) : (
            <MyGridRedux
              friendlyName={friendlyName}
              inputData={inputData as Record<string, unknown>[]}
              getInfoAction={fetchData}
            />
          )}
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  info: state.info.info,
  loading: selectInfo(state).loading,
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
  assignments: selectAssignmentState(state).assignments,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleReviewerPage);
