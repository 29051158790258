import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { selectBidState, selectInfo, selectKeywordState, selectTrackRoles } from '../../store/selectors';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { ChairInfo, Info, InfoDeleteDTO, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import { formatKeywordColumn } from '../../helpers/table';
import { getMenuItemName } from '../../helpers/translations';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { useTranslation } from 'react-i18next';
import HeaderEditBiddingAssignment from '../../components/HeaderEditBiddingAssignment/HeaderEditBiddingAssignment';
import { Role } from '../../store/conference/types';
import infoSlice from '../../store/info/slice';
import { getRouteByName } from '../../router/routes';
import history from '../../store/history';
import { fillRoutePath } from '../../helpers/path';
import { MenuItemName } from '../../layouts/types';
import { BidOption, ReviewerTableRow } from '../../store/bid/types';
import { getReviewer } from '../../helpers/users';
import { buildDTO } from '../../store/review/utils';
import tableSlice from '../../store/table/slice';
import { Assignment, UpdateAssignment } from '../../store/review/types';
import assignmentSlice from '../../store/review/slice';
import { CustomColumnsDataGet } from '../../store/table/types';

interface Props {
  loading: boolean;
  keywordById: { [key: number]: Keyword };
  info: Info;
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  bidOptionsById: { [key: number]: BidOption };
  clearNotifiablesAction: () => void;
  setSelectorValuesAction: (data: { fromRoleId: number; toRoleId: number }) => void;
  updateAssignmentAction: (data: UpdateAssignment) => void;
  deleteInfoAction: (data: InfoDeleteDTO) => void;
  getCustomColumnsDataAction: (data: CustomColumnsDataGet) => void;
}

const ReviewersToReviewersDetailPage = ({
  loading,
  keywordById,
  info,
  roleById,
  getInfoAction,
  bidOptionsById,
  clearNotifiablesAction,
  setSelectorValuesAction,
  updateAssignmentAction,
  deleteInfoAction,
  getCustomColumnsDataAction,
}: Props) => {
  const { t, i18n } = useTranslation();
  const params: any = useParams();
  const id = parseInt(params.id);
  const toRoleId = parseInt(params.roleId);

  const chairInfo = info as ChairInfo;
  const reviewer: ReviewerTableRow | undefined = getReviewer(id, chairInfo.users);

  const friendlyName: TableFriendlyName = 'reviewer_assignments_of_reviewers';
  const queryParams = { person_role_id: id, target_role_id: toRoleId };

  const selectorValues = chairInfo['reviewers_to_reviewers'].selectorValues;

  const fetchCustomColumnsData = () => {
    getCustomColumnsDataAction({ tableFriendlyName: friendlyName, queryParams });
  };

  /* Fetch table data */
  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      getInfoAction({ friendlyName: 'users' });
    } else if (reviewer) {
      if (!selectorValues.fromRoleId && !selectorValues.toRoleId) {
        // If we came to this route directly (i.e. by pasting URL in browser) fill up role selector values
        setSelectorValuesAction({ fromRoleId: reviewer.role_id, toRoleId });
      }
      getInfoAction({ friendlyName, queryParams });
    }
    return () => {
      clearNotifiablesAction(); // Clear notifications at page exit
      deleteInfoAction({ friendlyName });
    };
  }, [info['users'].byId]);

  if (loading) {
    return <Loading />;
  } else if (!reviewer) {
    return <div>Reviewer #{id} not found</div>;
  }

  /* Generate data for table */
  const inputData: unknown[] = [];
  const assignmentData = Object.values(info[friendlyName].byId) as Assignment[];
  assignmentData.forEach((additional) => {
    const reviewer = getReviewer(additional.person_role_id, chairInfo.users);
    if (reviewer) {
      const newRegister = {
        ...reviewer,
        assignment_id: additional.id,
        affinity: additional.affinity,
        bid: additional.bid_option_id ? bidOptionsById[additional.bid_option_id].name : undefined,
        assigned: additional.assigned,
        completed: additional.completed,
      };

      // Add Role description
      newRegister.role = roleById[reviewer.role_id].description;

      formatKeywordColumn(newRegister, keywordById);

      inputData.push(newRegister);
    }
  });

  /* Generate header */
  const headerContent = {
    title: reviewer.full_name ?? '',
    row1: {
      title: t('Role'),
      content: roleById[reviewer.role_id].description,
    },
    row2: {
      title: t('Email'),
      content: reviewer?.email ?? '',
    },
    row3: {
      title: t('Main area'),
      content:
        reviewer && reviewer?.keyword_ids.length > 0
          ? (reviewer?.keyword_ids
              .map((id) => {
                const parentId = keywordById[id].parent_id;
                return parentId && keywordById[parentId].name + '. ';
              })
              .filter((item, index, self) => self.indexOf(item) === index) as string[]) //to avoid duplicate main areas
          : t('The reviewer has not selected keywords.'),
    },
    row4: {
      title: t('Keywords'),
      content:
        reviewer && reviewer.keyword_ids.length > 0
          ? (reviewer?.keyword_ids.map((id) => keywordById[id].name + '. ') as string[])
          : t('The reviewer has not selected keywords.'),
    },
  };

  const parentRoute = getRouteByName('RouteAssignmentsReviewersToReviewers');

  return (
    <div className="flex flex-col h-full">
      <HeaderEditBiddingAssignment
        onPageTitleClick={() => history.push(fillRoutePath(parentRoute.path))}
        originPageTitle={getMenuItemName(parentRoute.main_menu_option as MenuItemName, t)}
        headerContent={headerContent}
      />
      <div className="mt-8" />
      <MyGridRedux
        friendlyName={friendlyName}
        inputData={inputData as Record<string, unknown>[]}
        updateAssignmentAction={(modelId, assigned) => {
          const [dto, keyBy] = buildDTO(
            modelId,
            assigned,
            'person_role_id',
            id,
            'App\\Models\\PersonRole',
            toRoleId,
            reviewer?.role_id,
          );
          updateAssignmentAction({ ...dto, modelId, tableFriendlyName: friendlyName, queryParams });
        }}
        getCustomColumnsData={fetchCustomColumnsData}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading,
  keywordById: selectKeywordState(state).keywordById,
  info: state.info.info,
  roleById: selectTrackRoles(state),
  bidOptionsById: selectBidState(state).bidOptions.byId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  deleteInfoAction: (data: InfoDeleteDTO) => dispatch(infoSlice.actions.DELETE(data)),
  clearNotifiablesAction: () => dispatch(tableSlice.actions['NOTIFIABLES:CLEAR']()),
  setSelectorValuesAction: (data: { fromRoleId: number; toRoleId: number }) =>
    dispatch(infoSlice.actions['ASSIGNMENTS:SET_SELECTOR_VALUES'](data)),
  updateAssignmentAction: (data: UpdateAssignment) => dispatch(assignmentSlice.actions['ASSIGNMENTS:UPDATE'](data)),
  getCustomColumnsDataAction: (data: CustomColumnsDataGet) =>
    dispatch(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewersToReviewersDetailPage);
