import React, { useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import { ThemeProvider } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Card, Checkbox, Link } from '../ui';
import SettingsIcon from '../../icons/Settings';
import { hasRelation } from '../../helpers/role';
import { AppState } from '../../store/state';
import { selectConference, selectPermission, selectTrackRoles } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { Role, RoleDTO, roleRelationDTO } from '../../store/conference/types';
import conferenceSlice from '../../store/conference/slice';
import { connect } from 'react-redux';
import RoleDialog from '../dialogs/RoleDialog/RoleDialog';
import { useTranslation } from 'react-i18next';
import { getRoleName } from '../../helpers/translations';
import { PermissionString } from '../../store/permission/types';

interface Props {
  currentRole: Role;
  roleById: { [key: string]: Role };
  roleRelationsById: { [key: string]: { [key: string]: number[] } };
  updateRoleRelationAction: (data: roleRelationDTO) => void;
  updateRoleAction: (data: RoleDTO) => {};
  onCancel: () => void;
  permissionByRoleId: { [key: number]: PermissionString[] };
}

const RoleManagement: React.FC<Props> = ({
  currentRole,
  roleById,
  roleRelationsById,
  updateRoleRelationAction,
  updateRoleAction,
  onCancel,
  permissionByRoleId,
}) => {
  const { t, i18n } = useTranslation();
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);
  const theme = useTheme();

  const canAccessSummarizedViews = permissionByRoleId[currentRole.id].includes('ACCESS_SUMMARIZED_VIEWS');

  return (
    <ThemeProvider theme={theme}>
      <div className="w-full max-w-screen-2xl">
        {/* Roles tab page title */}
        <div className="flex h-auto flex-col">
          <div className="pb-4 gap-5 grid grid-cols-1 justify-items-start max-w-5xl">
            <div className="flex mb-2 items-center w-full">
              <button onClick={onCancel} className="bg-blue-700 text-white px-1.5 py-0.5 h-6 leading-none">
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>

              <h2 className="font-bold text-xl ml-8 capitalize">{getRoleName(currentRole, t)}</h2>

              {currentRole.type !== 'author' && (
                <Link
                  onClick={() => {
                    setDialog(
                      <RoleDialog
                        initRole={{
                          ...currentRole,
                          summarized_views: canAccessSummarizedViews,
                        }}
                        handleRoleOk={updateRoleAction}
                        open={true}
                        handleClose={() => setDialog(undefined)}
                      />,
                    );
                  }}
                  className="cursor-pointer flex items-center ml-8"
                >
                  <SettingsIcon stroke={'#0e4ff0'} />
                  <span className="ml-2 font-medium text-sm">{t('Settings')}</span>
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start mb-12">
          <div>
            <h3 className="font-semibold text-lg mb-2">{t('Role management and relationships')}</h3>
            <p className="text-sm max-w-4xl mb-8">
              {t(
                `This section provides a high-level view of all roles involved in the review process. Define and manage the relationships between these roles for efficient workflow management.`,
              )}
            </p>
          </div>

          {Object.values(roleById)
            .filter((role) => role.id === currentRole.id)
            .map((fromRole) => (
              <div key={`from-${fromRole.id}`} className="max-w-screen-2xl">
                <div className="grid grid-cols-6 gap-8 mb-6">
                  {Object.values(roleById)
                    .filter((role) => role.type !== 'chair' && role.type !== currentRole.description)
                    .map((toRole) => (
                      <Card key={`from-${fromRole.id}-to-${toRole.id}`} className="max-w-xs min-w-min">
                        <h2 className="font-bold mb-3">
                          {currentRole.description === toRole.description ? t('Other') + ' ' : ''}
                          {getRoleName(toRole, t)}
                        </h2>
                        <Checkbox
                          label={t('View identity')}
                          defaultChecked={hasRelation('identity', fromRole.id, toRole.id, roleRelationsById)}
                          onChange={(e) =>
                            updateRoleRelationAction({
                              from_role_id: fromRole.id,
                              to_role_id: toRole.id,
                              type: 'identity',
                              value: e.target.checked,
                            })
                          }
                        />
                        <Checkbox
                          label={t('Send email')}
                          defaultChecked={hasRelation('send-email', fromRole.id, toRole.id, roleRelationsById)}
                          onChange={(e) =>
                            updateRoleRelationAction({
                              from_role_id: fromRole.id,
                              to_role_id: toRole.id,
                              type: 'send-email',
                              value: e.target.checked,
                            })
                          }
                        />
                      </Card>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>

      {dialog}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  roleById: selectTrackRoles(state),
  roleRelationsById: selectConference(state).roleRelationsById,
  permissionByRoleId: selectPermission(state).byRoleId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateRoleRelationAction: (data: roleRelationDTO) => dispatch(conferenceSlice.actions.UPDATE_ROLE_RELATION(data)),
  updateRoleAction: (data: RoleDTO) => dispatch(conferenceSlice.actions['UPDATE:ROLE'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleManagement);
