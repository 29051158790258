import React from 'react';
import { Role, RoleType } from '../../store/conference/types';
import { Form, FormType, FormVisibilityOption, updateVisibilityAction } from '../../store/form/types';
import { FormTypeEnum } from '../../store/form/types.d';
import TrashCan from '../../icons/TrashCan';
import FormRolePermission from '../FormRolePermission/FormRolePermission';

interface FormDefinition {
  formType: FormType;
  description: string;
  editRoles: RoleType[];
}

export const formDefinition: FormDefinition[] = [
  {
    formType: FormTypeEnum.Submission,
    description: '1 form per submission',
    editRoles: ['author'],
  },
  {
    formType: FormTypeEnum.Review,
    description: 'N forms per submission',
    editRoles: ['reviewer'],
  },
];

const nextVisibility = (
  visibility: FormVisibilityOption,
  roleType: RoleType,
  editRoleTypes: RoleType[],
  form: Form,
): FormVisibilityOption => {
  if (roleType == 'author' && form.is_default) {
    switch (visibility) {
      case 'hidden':
      case 'read':
        return 'write';
      case 'write':
        return 'read';
    }
  } else if (roleType == 'chair' || editRoleTypes.includes(roleType)) {
    switch (visibility) {
      case 'hidden':
        return 'read';
      case 'read':
        return 'write';
      case 'write':
        return 'hidden';
    }
  } else {
    switch (visibility) {
      case 'hidden':
        return 'read';
      case 'read':
      case 'write':
        return 'hidden';
    }
  }
};

interface Props {
  roleById: { [key: string]: Role };
  permissions: { [key: string]: { to_role_id: number | null; visibility: FormVisibilityOption }[] | undefined };
  form: Form;
  handleOnClick?: (data: updateVisibilityAction) => void;
  handleOnDelete?: (roleId: number, toRoleId: number | null) => void;
}

const FormPermissions: React.FC<Props> = ({ roleById, permissions, form, handleOnClick, handleOnDelete }) => {
  const definition = formDefinition.find((value) => value.formType == form.type);
  if (!definition) throw Error('Form definition not found.');

  const permissionElement: JSX.Element[] = [];

  return (
    <div>
      {Object.keys(permissions)
        .map((roleId) => roleById[roleId])
        .map((role, index) => {
          let el;

          permissions[role.id]?.map((permissionsData, index) => {
            const { to_role_id, visibility } = permissionsData;

            el = (
              <div key={`permission-${index}`} className="flex justify-between">
                <div
                  onClick={() => {
                    handleOnClick?.({
                      form_id: form.id,
                      target_role_id: role.id,
                      visibility: nextVisibility(visibility, role.type, definition.editRoles, form),
                      to_role_id: to_role_id,
                    });
                  }}
                  className="cursor-pointer"
                >
                  <FormRolePermission
                    role={role}
                    roleById={roleById}
                    permissionsData={permissionsData}
                    form={form}
                    renderIcons={!!handleOnClick}
                  />
                </div>

                {handleOnDelete && (
                  <div className={`k-cursor-pointer trash-wrapper`} onClick={() => handleOnDelete(role.id, to_role_id)}>
                    <TrashCan />
                  </div>
                )}
              </div>
            );

            permissionElement.push(el);
          });

          return (
            <div className={`flex flex-col`} key={`x${form.id}-${index}`}>
              {permissionElement}
            </div>
          );
        })}
    </div>
  );
};

export default FormPermissions;
