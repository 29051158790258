import React, { useEffect } from 'react';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import { Submission } from '../../store/submission/types';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { AppState } from '../../store/state';
import {
  selectInfo,
  selectKeywordState,
  selectPaperStatusState,
  selectSubmissionState,
  selectTable,
} from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import submissionSlice from '../../store/submission/slice';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { PaperStatus } from '../../store/paper-status/types';
import { getSubmissionRow } from '../../helpers/table';
import { useTranslation } from 'react-i18next';
import infoSlice from '../../store/info/slice';
import { CustomColumnsDataGet } from '../../store/table/types';
import tableSlice from '../../store/table/slice';

interface Props {
  loading: boolean;
  submissionsById: { [key: string]: Submission };
  getSubmissionsAction: () => void;
  keywordById: { [key: number]: Keyword };
  paperStatusById: { [key: number]: PaperStatus };
  getInfoAction: (data: InfoGetDTO) => void;
  info: Info;
  customColumnsData: { [key: string]: { [key: number]: { [key: string]: any } } };
  getCustomColumnsDataAction: (data: CustomColumnsDataGet) => void;
}

const AllSubmissionsBiddingPage: React.FC<Props> = ({
  loading,
  submissionsById,
  getSubmissionsAction,
  keywordById,
  paperStatusById,
  getInfoAction,
  info,
  customColumnsData,
  getCustomColumnsDataAction,
}) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'submissions_bids';

  const fetchData = () => {
    getSubmissionsAction();
    getInfoAction({ friendlyName });
  };

  const fetchCustomColumnsData = () => {
    getCustomColumnsDataAction({ tableFriendlyName: friendlyName });
  };

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      fetchData();
    } else if (!customColumnsData[friendlyName]) {
      fetchCustomColumnsData();
    }
  }, []);

  const chairInfo = info as ChairInfo;

  /* Build input data */
  const inputData: unknown[] = [];
  Object.values(submissionsById).forEach((submission, index) => {
    // Add bidding info
    const submissionBid = chairInfo.submissions_bids.byId[submission.id] ?? {
      id: submission.id,
      positive: 0,
      negative: 0,
      bids: 0,
    };
    const newRegister = { ...getSubmissionRow(submission, true, keywordById, paperStatusById, t), ...submissionBid };

    inputData.push(newRegister);
  });

  return (
    <>
      <div className="h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={fetchData}
            getCustomColumnsData={fetchCustomColumnsData}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading || selectInfo(state).loading,
  submissionsById: selectSubmissionState(state).submissionsById,
  keywordById: selectKeywordState(state).keywordById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  info: state.info.info,
  customColumnsData: selectTable(state).customColumnsData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: () => dispatch(submissionSlice.actions.GET_SUBMISSIONS()),
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  getCustomColumnsDataAction: (data: CustomColumnsDataGet) =>
    dispatch(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllSubmissionsBiddingPage);
