import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#000' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.15 6.433 3.033 30a3.333 3.333 0 0 0 2.85 5h28.234a3.334 3.334 0 0 0 2.85-5L22.85 6.433a3.333 3.333 0 0 0-5.7 0M20 15v6.667M20 28.333h.017"
      ></path>
    </svg>
  );
}

export default Icon;
