import SimpleDialog from '../SimpleDialog/SimpleDialog';
import React, { useState } from 'react';
import { ValidatedField } from '../../../store/api.d';
import { Role } from '../../../store/conference/types';
import { AppState } from '../../../store/state';
import { selectTrackRoles } from '../../../store/selectors';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import infoSlice from '../../../store/info/slice';
import AddPersonForm from '../../AddPersonForm/AddPersonForm';
import { getUserByEmail } from '../../../store/user/sagas';
import { AddPersonDTO } from '../../../store/info/types';
import { validationErrorTransformer } from '../../../store/api';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClose: () => void;
  roleById: { [key: string]: Role };
  addPersonAction: (data: AddPersonDTO) => void;
}

const AddPersonDialog: React.FC<Props> = ({ handleClose, roleById, addPersonAction }) => {
  const { t, i18n } = useTranslation();
  const [FData, setFData] = useState<AddPersonDTO>({
    first_name: '',
    last_name: '',
    email: '',
    role: 0,
  });
  const [showExtraFields, setShowExtraFields] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidatedField[]>([]);

  const handleOk = async () => {
    const res = await getUserByEmail(FData.email);
    switch (res.type) {
      case 'ok':
        const { data } = res.value;
        if (data.length > 0 || showExtraFields) {
          const foundUser = data[0];
          // Case user found
          addPersonAction({ ...FData, ...foundUser });
        } else {
          // Case user not found. Ask for extra fields
          setShowExtraFields(true);
          setValidationErrors([]);
        }
        break;
      case 'validation-error':
        setValidationErrors(res.value.validation);
        break;
      case 'error':
        break;
    }
  };

  const errors = validationErrors ? validationErrorTransformer(validationErrors) : {};

  return (
    <SimpleDialog
      title={t('Add person')}
      open={true}
      handleClose={handleClose}
      handleOk={handleOk}
      closeOnOk={false}
      disableOkButton={!FData.email || !FData.role || (showExtraFields && (!FData.first_name || !FData.last_name))}
    >
      <AddPersonForm
        FData={FData}
        onChange={(value) => setFData(value)}
        showExtraFields={showExtraFields}
        roleById={roleById}
        errors={errors}
      />
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  roleById: selectTrackRoles(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addPersonAction: (data: AddPersonDTO) => dispatch(infoSlice.actions['USER:ADD'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonDialog);
