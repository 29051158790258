import React, { useState } from 'react';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import '../../components/Dashboard/Dashboard.styles.css';
import { Role } from '../../store/conference/types';
import Map from '../../components/ui/surfaces/Map/Map';
import { useTranslation } from 'react-i18next';
import EmptyDashboard from './EmptyDashboard';
import { getInvitationStatusName } from '../../helpers/translations';

interface DashboardReviewersProps {
  dashboardState: DashboardState;
  keywordById: { [key: number]: Keyword };
  roleSelect: JSX.Element;
  role: Role;
}

const DashboardReviewers: React.FC<DashboardReviewersProps> = ({ dashboardState, keywordById, roleSelect, role }) => {
  const { t, i18n } = useTranslation();
  const [selectedAreaId, setSelectedAreaId] = useState<any>(0);
  const invitations_per_status = dashboardState.data.invitations_per_status ?? [];
  const reviewers_per_type = dashboardState.data.reviewers_per_type ?? [];
  const reviewers_per_keyword = dashboardState.data.reviewers_per_keyword ?? [];

  const totalInvitationsSent = invitations_per_status.reduce(
    (sum: number, status: { value: number }) => sum + status.value,
    0,
  );

  const totalReviewers = reviewers_per_type.reduce((sum: number, status: { value: number }) => sum + status.value, 0);
  const totalReviewersPerArea = reviewers_per_keyword.reduce(
    (sum: number, area: { value: number }) => sum + area.value,
    0,
  );

  const reviewersWithoutCountry = (dashboardState.data.reviewers_per_country ?? [])
    .find((item: { id: string; value: number }) => item.id === 'UNKNOWN')
    ?.value.toFixed(2);

  return (
    <>
      <div className="flex flex-row justify-end mb-12 w-full">
        <StyledSelectWrapper className="flex flex-row justify-end items-baseline">{roleSelect}</StyledSelectWrapper>
      </div>
      {role.type == 'chair' && (
        <div
          className="flex flex-row justify-between mb-12 w-full invitationsPerStatusChart"
          style={{ height: '32rem', minWidth: 540 }}
        >
          {/* INVITATIONS PER STATUS */}
          <div className="relative bg-white rounded-lg shadow-md mr-4 p-8 w-6/12">
            <h2 className="font-bold text-xl text-center">{t('Total invitations per status')}</h2>

            {Object.keys(invitations_per_status).length > 0 ? (
              <>
                <ResponsivePie
                  data={(invitations_per_status as any[]).map((value) => {
                    // Just overwrite label with according translation
                    return { ...value, label: getInvitationStatusName(value.id, t) };
                  })}
                  sortByValue={true}
                  margin={{ top: 50, right: 80, bottom: 80, left: 80 }}
                  innerRadius={0.65}
                  padAngle={1}
                  cornerRadius={1}
                  colors={['#5ca8f9', '#f9a80e', '#86e6fb']}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.5]],
                  }}
                  arcLinkLabel="label"
                  arcLinkLabelsSkipAngle={15}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  tooltip={(point) => {
                    return (
                      <div className="text-sm bg-white px-2 py-1 shadow-md flex flex-row">
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: point.datum.color,
                            marginTop: '4px',
                            marginRight: '8px',
                          }}
                        ></div>
                        <div>
                          {point.datum.label}: <strong>{point.datum.value}</strong>
                        </div>
                      </div>
                    );
                  }}
                  layers={['arcs', 'arcLabels', 'arcLinkLabels']}
                />

                <StyledTotal>
                  <span className="title">{totalInvitationsSent}</span>
                  <span className="text">{t('invitations sent')}</span>
                </StyledTotal>
              </>
            ) : (
              <EmptyDashboard className={'mt-36'} />
            )}
          </div>

          {/* REVIEWERS PER TYPE */}
          <div className="relative bg-white rounded-lg shadow-md p-8 w-6/12">
            <h2 className="font-bold text-xl text-center">{t('Reviewers per type')}</h2>

            {Object.keys(reviewers_per_type).length > 0 ? (
              <>
                <ResponsivePie
                  data={reviewers_per_type}
                  sortByValue={true}
                  margin={{ top: 50, right: 80, bottom: 80, left: 80 }}
                  padAngle={0}
                  cornerRadius={1}
                  colors={['#4f91e0', '#6abdfb', '#78d2f3', '#7fdcff', '#9bffef', '#a2f9fb', '#a9f5ff']}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.5]],
                  }}
                  arcLinkLabel="label"
                  arcLinkLabelsSkipAngle={2}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsStraightLength={25}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  enableArcLabels={false}
                  arcLabelsSkipAngle={5}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  tooltip={(point) => {
                    return (
                      <div className="text-sm bg-white px-2 py-1 shadow-md flex flex-row">
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: point.datum.color,
                            marginTop: '4px',
                            marginRight: '8px',
                          }}
                        ></div>
                        <div>
                          {point.datum.label}: <strong>{point.datum.value}</strong>
                        </div>
                      </div>
                    );
                  }}
                  layers={['arcs', 'arcLabels', 'arcLinkLabels']}
                />

                <StyledTotal>
                  <span className="title">{totalReviewers}</span>
                  <span className="text">{t('reviewers')}</span>
                </StyledTotal>
              </>
            ) : (
              <EmptyDashboard className={'mt-36'} />
            )}
          </div>
        </div>
      )}

      {/* REVIEWERS PER KEYWORDS */}
      {Object.keys(keywordById).length > 0 && (
        <div
          className="flex flex-row w-full mb-12 justify-center mx-auto reviewersPerKeywordsChart"
          style={{ height: '40rem', minWidth: 540 }}
        >
          <div className="relative my-0 bg-white rounded-lg shadow-md mr-4 pt-8 px-4 w-6/12">
            <h2 className="font-bold text-xl text-center">{t('Reviewers per area')}</h2>
            {Object.keys(reviewers_per_keyword).length > 0 ? (
              <>
                <p className="text-center text-sm mt-2 mb-4">
                  {t('Number of reviewers considering the keyword in the first position (the main keyword).')}
                </p>

                <ResponsivePie
                  data={reviewers_per_keyword}
                  sortByValue={true}
                  margin={{ top: 50, right: 80, bottom: 220, left: 80 }}
                  innerRadius={0.65}
                  padAngle={1}
                  cornerRadius={1}
                  colors={['#fba605', '#fbbf14', '#fcc82d', '#fdc145', '#fdd95c', '#fde88a', '#fdefa1', '#fdf4b8']}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.5]],
                  }}
                  arcLinkLabel="label"
                  arcLinkLabelsSkipAngle={15}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  tooltip={(point) => {
                    return (
                      <div className="text-sm bg-white px-2 py-1 shadow-md flex flex-row">
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: point.datum.color,
                            marginTop: '4px',
                            marginRight: '8px',
                          }}
                        ></div>
                        <div>
                          {point.datum.label}: <strong>{point.datum.value}</strong>
                        </div>
                      </div>
                    );
                  }}
                  layers={['arcs', 'arcLabels', 'arcLinkLabels']}
                  onClick={(data, event) => {
                    setSelectedAreaId(data.id);
                  }}
                />
                <StyledTotal>
                  <span className="title">{totalReviewersPerArea}</span>
                  <span className="text">{t('reviewers with keywords')}</span>
                </StyledTotal>
              </>
            ) : (
              <EmptyDashboard className={'mt-48'} />
            )}
          </div>

          <div className="relative my-0 bg-white rounded-lg shadow-md pt-8 px-4 w-6/12">
            {selectedAreaId > 0 && (
              <>
                <div
                  onClick={() => setSelectedAreaId(0)}
                  className="absolute top-3 right-3 text-3xl text-blue-600 cursor-pointer"
                >
                  <FontAwesomeIcon icon={faCircleXmark} />
                </div>
                <h2 className="font-bold text-xl text-center">
                  {reviewers_per_keyword.find((dades: { id: any }) => dades.id === selectedAreaId)?.label}:
                  {' ' + t('reviewers per topic')}
                </h2>
                <p className="text-center text-sm mt-2 mb-4">
                  {t('Number of reviewers considering the keyword in the first position (the main keyword).')}
                </p>

                <ResponsivePie
                  data={reviewers_per_keyword.find((dades: { id: any }) => dades.id === selectedAreaId)?.topics}
                  sortByValue={true}
                  margin={{ top: 50, right: 80, bottom: 220, left: 80 }}
                  innerRadius={0.65}
                  padAngle={1}
                  cornerRadius={1}
                  colors={['#fba605', '#fbbf14', '#fcc82d', '#fdc145', '#fdd95c', '#fde88a', '#fdefa1', '#fdf4b8']}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  arcLinkLabel="label"
                  arcLinkLabelsSkipAngle={9}
                  arcLinkLabelsOffset={0}
                  arcLinkLabelsStraightLength={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={6}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  tooltip={(point) => {
                    return (
                      <div className="text-sm bg-white px-2 py-1 shadow-md flex flex-row">
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: point.datum.color,
                            marginTop: '4px',
                            marginRight: '8px',
                          }}
                        ></div>
                        <div>
                          {point.datum.label}: <strong>{point.datum.value}</strong>
                        </div>
                      </div>
                    );
                  }}
                  layers={['arcs', 'arcLabels', 'arcLinkLabels']}
                />

                <StyledTotal>
                  <span className="title">
                    {reviewers_per_keyword
                      .find((dades: { id: any }) => dades.id === selectedAreaId)
                      ?.topics.reduce((sum: number, area: any) => sum + area.value, 0)}
                  </span>
                  <span className="text">{t('reviewers in this area')}</span>
                </StyledTotal>
              </>
            )}

            {!(Object.keys(reviewers_per_keyword).length > 0) && <EmptyDashboard className={'mt-56'} />}

            {Object.keys(reviewers_per_keyword).length > 0 && !selectedAreaId && (
              <div className="flex flex-col justify-center h-full pb-8">
                <p className="text-md font-semibold self-center">{t('Choose an area from Submissions per area')}</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* REVIEWERS PER COUNTRY */}
      <div
        className="bg-white mb-12 mx-auto mb-4 w-full p-8 rounded-lg shadow-md reviewersPerCountryChart"
        style={{ height: '35rem' }}
      >
        <h2 className="font-bold text-xl mb-1 mr-5 text-center">{t('Reviewers per country')}</h2>
        {dashboardState.data.reviewers_per_country ? (
          <Map
            id="mapAuthorsPerCountry"
            mapData={dashboardState.data.reviewers_per_country}
            width="100%"
            height="27rem"
            margin="0 auto"
            maxWidth={1920}
            zoom={1.6}
            totalWithoutCountry={reviewersWithoutCountry}
          />
        ) : (
          <EmptyDashboard className={'mt-36'} />
        )}
      </div>
    </>
  );
};

const StyledTotal = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 1.1;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  color: #333333;

  .title {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
  .text {
    font-size: 0.775rem;
  }
`;

const StyledSelectWrapper = styled.div`
  > div {
    margin-right: 0.85rem;
    min-width: inherit;
  }
`;

export default DashboardReviewers;
