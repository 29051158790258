import { Action, combineReducers, Reducer } from '@reduxjs/toolkit';
import authSlice from './auth/slice';
import conferenceSlice from './conference/slice';
import dashboardSlice from './dashboard/slice';
import errorSlice from './error/slice';
import infoSlice from './info/slice';
import permissionSlice from './permission/slice';
import sidemenuSlice from './sidemenu/slice';
import { AppState } from './state';
import tableSlice from './table/slice';
import userSlice from './user/slice';
import submissionSlice from './submission/slice';
import keywordSlice from './keyword/slice';
import formSlice from './form/slice';
import assignmentSlice from './review/slice';
import labelSlice from './label/slice';
import screenSlice from './screen/slice';
import emailSlice from './email/slice';
import bidSlice from './bid/slice';
import paperStatusSlice from './paper-status/slice';
import phaseSlice from './phase/slice';
import recruitmentSlice from './recruitment/slice';

const appReducer = combineReducers({
  auth: authSlice.reducer,
  conference: conferenceSlice.reducer,
  dashboard: dashboardSlice.reducer,
  sidemenu: sidemenuSlice.reducer,
  table: tableSlice.reducer,
  permission: permissionSlice.reducer,
  info: infoSlice.reducer,
  error: errorSlice.reducer,
  user: userSlice.reducer,
  submission: submissionSlice.reducer,
  keyword: keywordSlice.reducer,
  form: formSlice.reducer,
  assignment: assignmentSlice.reducer,
  label: labelSlice.reducer,
  screen: screenSlice.reducer,
  email: emailSlice.reducer,
  bid: bidSlice.reducer,
  paperStatus: paperStatusSlice.reducer,
  phase: phaseSlice.reducer,
  recruitment: recruitmentSlice.reducer,
});

export const rootReducer: Reducer = (state: AppState, action: Action) => {
  return action.type === authSlice.actions['LOGOUT:OK'].type
    ? appReducer(undefined, action)
    : appReducer(state, action);
};
