import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { InputText } from '../../ui';
import { Track } from '../../../store/conference/types';
import { useTranslation } from 'react-i18next';
import { generateSlug } from '../../../helpers/slug';
import AddUserListForm from '../../AddUserListForm/AddUserListForm';

interface Props extends Omit<SimpleDialogProps, 'handleOk'> {
  track: Track;
  handleOk: (data: Track) => void;
}

const TrackDialog: React.FC<Props> = ({ track, handleOk, ...rest }) => {
  const { t, i18n } = useTranslation();

  const [trackData, setTrackData] = useState<Track>(track);
  const isDisabled = !trackData.name || !trackData.chairs.length;

  return (
    <SimpleDialog
      {...rest}
      title={!trackData.id ? t('Add track') : t('Edit track')}
      handleOk={() => handleOk(trackData)}
      disableOkButton={isDisabled}
    >
      <div>
        <InputText
          placeholder={t('Enter name')}
          label={t('Name')}
          value={trackData.name}
          onChange={(e) => setTrackData({ ...trackData, name: e.target.value, slug: generateSlug(e.target.value) })}
          className="text-sm mb-4"
        />
      </div>
      <div>
        <InputText
          placeholder={t('Slug')}
          label={t('Slug (automatically generated)')}
          value={trackData.slug}
          disabled={true}
          onChange={(e) => {}}
          className="text-sm mb-4"
        />
      </div>
      <div>
        <span>{t('Chairs')}</span>
        <AddUserListForm users={trackData.chairs} onChange={(chairs) => setTrackData({ ...trackData, chairs })} />
      </div>
    </SimpleDialog>
  );
};

export default TrackDialog;
