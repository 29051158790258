import React from 'react';
import tw from 'twin.macro';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: boolean;
  ref?: any;
};

export const Checkbox = ({ id, label, className, required, error, ...inputProps }: CheckboxProps): JSX.Element => {
  const inputId = id || nanoid();
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledDiv className={`${cn(className, { error })} checkbox`}>
        <input {...inputProps} id={inputId} type="checkbox" required={required} />
        <span className="checkmark"></span>
        {label && (
          <label htmlFor={inputId} className="ml-2">
            {required ? `${label} *` : label}
          </label>
        )}
      </StyledDiv>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  ${tw`inline-flex items-center justify-start`}
  position: relative;

  label {
    ${tw`leading-none`}
    color: ${(props) => props.theme.palette.text.primary};
  }

  input {
    ${tw`rounded focus:border-2  focus:ring-transparent focus:ring-0`}
    z-index: 20;
    opacity: 0;
    width: 22px;
    height: 22px;
    position: inherit;
    cursor: pointer;

    &.focus {
      border: ${(props) => props.theme.palette.text.primary};
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -2px;
    left: -2px;
    height: 22px;
    width: 23px;
    background-color: #fff;
    border-radius: 6px;
    z-index: 0;
    border: 1px solid ${(props) => props.theme.palette.text.secondary};
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #2563eb;
    border: 1px solid transparent;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  /* Style the checkmark/indicator */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.error {
    label {
      color: ${(props) => props.theme.palette.error.main};
    }

    input {
      ${tw`rounded focus:border-2`}
      border-color: ${(props) => props.theme.palette.error.main};
    }
  }
`;
