import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import useStyles from './ConferenceRequestForm.styles';
import { Controller, useForm } from 'react-hook-form';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { Button, CancelButton } from '../ui';
import history from '../../store/history';
import tw from 'twin.macro';
import { getUserOffset } from '../../helpers/timezone';
import DateTimePickerWithOffset from '../DateTimePickerWithOffset/DateTimePickerWithOffset';
import { Conference } from '../../store/conference/types';
import Loading from '../Loading/Loading';
import { useTranslation } from 'react-i18next';

export interface ConferenceRequestFormFields {
  id?: number;
  name: string;
  short_name: string;
  start_date: string;
  end_date: string;
  location: string;
  url: string;
}

interface ConferenceRequestFormProps {
  loading: boolean;
  timezone: string | null;
  dateFormat: string | null;
  initConference: Conference | null;
  validation_error?: ConferenceRequestFormFields;
  save_handler: (form: ConferenceRequestFormFields) => void;
  clearValidationErrors: () => void;
}

const ConferenceRequestForm: React.FC<ConferenceRequestFormProps> = ({
  loading,
  timezone,
  dateFormat,
  initConference,
  validation_error,
  save_handler,
  clearValidationErrors,
}) => {
  // useState hooks
  const [conferenceFields, setConferenceFields] = useState<Conference>(initConference ?? ({} as Conference));

  useEffect(() => {
    return () => {
      // This function cleans the validation errors
      clearValidationErrors();
    };
  }, []);

  const classes = useStyles();
  const { register, handleSubmit, errors, control } = useForm();
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const limitStartDateYear = initConference?.start_date
    ? new Date(initConference?.start_date).getFullYear()
    : new Date().getFullYear();
  const limitEndDateYear = initConference?.end_date
    ? new Date(initConference?.end_date).getFullYear()
    : new Date().getFullYear();

  // Compare objects with JSON.stringify.
  // Only compare some attributes because the value of updated_at, for example, always change
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#using_an_array_as_replacer
  const isValid =
    conferenceFields.name &&
    conferenceFields.short_name &&
    conferenceFields.start_date &&
    conferenceFields.end_date &&
    JSON.stringify(conferenceFields, ['name', 'short_name', 'start_date', 'end_date', 'location', 'url']) !==
      JSON.stringify(initConference, ['name', 'short_name', 'start_date', 'end_date', 'location', 'url']);

  let nameProps = {};
  let shortNameProps = {};
  let startDateProps = {};
  let endDateProps = {};
  let locationProps = {};
  let urlProps = {};

  const frontend_validation_rules = {
    name: { required: t('Enter name') },
    short_name: { required: t('Enter short name') },
    start_date: { required: t('Enter start date') },
    end_date: { required: t('Enter end date') },
    location: { required: false },
    url: { required: false },
  };

  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.name) {
      nameProps = {
        ...nameProps,
        error: true,
        helperText: errors.name.message,
      };
    }
    if (errors.short_name) {
      shortNameProps = {
        ...shortNameProps,
        error: true,
        helperText: errors.short_name.message,
      };
    }
    if (errors.start_date) {
      startDateProps = {
        ...startDateProps,
        error: true,
        helperText: errors.start_date.message,
      };
    }
    if (errors.end_date) {
      endDateProps = {
        ...endDateProps,
        error: true,
        helperText: errors.end_date.message,
      };
    }
    if (errors.location) {
      locationProps = {
        ...locationProps,
        error: true,
        helperText: errors.location.message,
      };
    }
    if (errors.url) {
      urlProps = {
        ...urlProps,
        error: true,
        helperText: errors.url.message,
      };
    }
  }
  // Backend Validation
  else if (validation_error) {
    if (validation_error.name) {
      nameProps = {
        ...nameProps,
        error: true,
        helperText: validation_error.name,
      };
    }
    if (validation_error.short_name) {
      shortNameProps = {
        ...shortNameProps,
        error: true,
        helperText: validation_error.short_name,
      };
    }
    if (validation_error.start_date) {
      startDateProps = {
        ...startDateProps,
        error: true,
        helperText: validation_error.start_date,
      };
    }
    if (validation_error.end_date) {
      endDateProps = {
        ...endDateProps,
        error: true,
        helperText: validation_error.end_date,
      };
    }
    if (validation_error.location) {
      locationProps = {
        ...locationProps,
        error: true,
        helperText: validation_error.location,
      };
    }
    if (validation_error.url) {
      urlProps = {
        ...urlProps,
        error: true,
        helperText: validation_error.url,
      };
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledDiv className="h-full">
        <Container component="main" maxWidth="xs" className="flex flex-col h-full">
          <div className={classes.paper} style={{ flexGrow: 1, marginTop: 0 }}>
            <form className={`conferenceForm ${classes.form}`} noValidate>
              <div className="fieldsContainer max-w-6xl">
                <TextField
                  id="name"
                  label={t('Name')}
                  name="name"
                  value={conferenceFields?.name ?? undefined}
                  autoComplete="off" //https://stackoverflow.com/questions/48304062/material-ui-textfield-disable-browser-autocomplete
                  variant="outlined"
                  margin="normal"
                  inputRef={register(frontend_validation_rules.name)}
                  required
                  fullWidth
                  placeholder="e.g. 32nd International Joint Conference on Artificial Intelligence"
                  {...nameProps}
                  onChange={(e) => {
                    setConferenceFields({ ...conferenceFields, name: e.target.value });
                  }}
                />
                <TextField
                  id="short_name"
                  label={t('Short name')}
                  name="short_name"
                  value={conferenceFields?.short_name ?? undefined}
                  autoComplete="off"
                  variant="outlined"
                  margin="normal"
                  inputRef={register(frontend_validation_rules.short_name)}
                  required
                  fullWidth
                  placeholder="e.g. IJCAI 2023"
                  {...shortNameProps}
                  onChange={(e) => {
                    setConferenceFields({ ...conferenceFields, short_name: e.target.value });
                  }}
                />
                <Controller
                  name="start_date"
                  control={control}
                  defaultValue={conferenceFields?.start_date}
                  rules={frontend_validation_rules.start_date}
                  render={({ onChange, value }) => {
                    return (
                      <DateTimePickerWithOffset
                        defaultValue={value ? new Date(value) : undefined}
                        required={true}
                        utcOffset={getUserOffset(timezone)}
                        format={dateFormat ?? undefined}
                        width={225}
                        onChange={(val) => {
                          onChange(val.toISOString());
                          setConferenceFields({ ...conferenceFields, start_date: val.toISOString() });
                        }}
                        label={t('Start date')}
                        className="mt-4 mb-2"
                        //@ts-ignore
                        error={startDateProps.error}
                        //@ts-ignore
                        errors={startDateProps.helperText}
                        min={
                          initConference && initConference?.id !== 0 ? new Date(limitStartDateYear, 0, 1) : undefined
                        }
                        max={
                          initConference && initConference?.id !== 0 ? new Date(limitStartDateYear, 11, 31) : undefined
                        }
                        appendToElement={true}
                      />
                    );
                  }}
                />
                <Controller
                  name="end_date"
                  control={control}
                  defaultValue={conferenceFields?.end_date}
                  rules={frontend_validation_rules.end_date}
                  render={({ onChange, value }) => {
                    return (
                      <DateTimePickerWithOffset
                        defaultValue={value ? new Date(value) : undefined}
                        required={true}
                        utcOffset={getUserOffset(timezone)}
                        format={dateFormat ?? undefined}
                        width={225}
                        onChange={(val) => {
                          onChange(val.toISOString());
                          setConferenceFields({ ...conferenceFields, end_date: val.toISOString() });
                        }}
                        label={t('End date')}
                        className="mt-4 mb-2"
                        //@ts-ignore
                        error={endDateProps.error}
                        //@ts-ignore
                        errors={endDateProps.helperText}
                        min={initConference && initConference?.id !== 0 ? new Date(limitEndDateYear, 0, 1) : undefined}
                        max={
                          initConference && initConference?.id !== 0 ? new Date(limitEndDateYear, 11, 31) : undefined
                        }
                        appendToElement={true}
                      />
                    );
                  }}
                />
                <TextField
                  id="location"
                  label={t('Location')}
                  name="location"
                  value={conferenceFields?.location ?? undefined}
                  autoComplete="off"
                  variant="outlined"
                  margin="normal"
                  inputRef={register(frontend_validation_rules.location)}
                  fullWidth
                  placeholder="e.g. Macau, China"
                  {...locationProps}
                  onChange={(e) => {
                    setConferenceFields({ ...conferenceFields, location: e.target.value });
                  }}
                />
                <TextField
                  id="url"
                  label={t('URL')}
                  name="url"
                  value={conferenceFields?.url ?? undefined}
                  autoComplete="off"
                  variant="outlined"
                  margin="normal"
                  inputRef={register(frontend_validation_rules.url)}
                  fullWidth
                  placeholder="e.g. https://ijcai-23.org"
                  {...urlProps}
                  onChange={(e) => {
                    setConferenceFields({ ...conferenceFields, url: e.target.value });
                  }}
                />
              </div>
              {/* FOOTER BUTTONS */}
              <StyledButtonsWrapper
                className={initConference && initConference?.id !== 0 ? 'editConferenceWrapper' : ''}
              >
                {initConference?.id === 0 && (
                  <CancelButton onClick={() => history.goBack()}>{t('Cancel')}</CancelButton>
                )}
                <Button
                  className="w-48 mx-2"
                  type="button"
                  disabled={!isValid}
                  onClick={handleSubmit<ConferenceRequestFormFields>(save_handler)}
                >
                  {t('Save')}
                </Button>
              </StyledButtonsWrapper>
            </form>
          </div>
        </Container>
      </StyledDiv>
    </ThemeProvider>
  );
};

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
  background-color: rgb(237, 243, 255);
  z-index: 10;
`;

const StyledDiv = styled.div`
  flex-grow: 1;
  width: 100%;

  label {
    ${tw`mb-1.5 ml-0.5 text-sm`}
    font-weight: normal;
    color: ${(props) => props.theme.palette.text.primary};
  }

  input {
    ${tw`rounded`}
    padding: 9px;
    background-color: ${(props) => props.theme.palette.background.paper};

    border: ${(props) => '1px solid ' + props.theme.palette.secondary.dark}};
    --tw-ring-color: ${(props) => props.theme.palette.text.primary};

    &.focus {
      border-color: ${(props) => props.theme.palette.text.primary};
      --tw-ring-color: ${(props) => props.theme.palette.text.primary};
    }
  }

  .helperText {
    ${tw`text-sm m-0.5 mb-1 leading-4 h-4`}
    /* display: none;*/
    color: ${(props) => props.theme.palette.error.main};
    margin-left: 10.3rem;
    font-size: 0.75rem;

    &.error {
      color: ${(props) => props.theme.palette.error.main};
      display: block;
    }
  }

  &.error {
    label {
      color: ${(props) => props.theme.palette.error.main};
    }

    input {
      ${tw`rounded border-2`}
      border-color: ${(props) => props.theme.palette.error.main};
    }
  }

  &.fullWidth {
    ${tw`flex flex-row`}

    label {
      width: 10rem;
      padding-top: 0.375rem;
    }
  }

  .MuiInputLabel-root {
    position: static;
    transform: inherit;
  }
  .MuiContainer-root {
    display: flex;
    max-width: 100%;
    padding: 0;

    .makeStyles-paper-1,
    .fieldsContainer,
    .conferenceForm {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    .conferenceForm {
      margin-top: 0;
    }
  }
  fieldset {
    display: none;
  }

  .MuiTextField-root {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    label,
    label > span {
      width: 10rem;
      margin-top: 0.5rem;
      color: #000 !important;
    }
    .MuiOutlinedInput-root {
      flex-grow: 1;
      width: auto;
      
      input {
        border: 1px solid #AFB0B1;
      }
      input:focus {
        border: 2px solid #2563eb;
      }
      input:hover .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.87);
      }
    }
    .MuiFormHelperText-root {
      width: 100%;
      margin: 0.2rem 0 0 10.2rem;
    }
  }
  .MuiTextField-root .Mui-error input {
    border-color: #f44336;
  }
  #start_date, #end_date {
    max-width: fit-content;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: none;
  }
  .date-wrapper {
    justify-content: flex-start;
    margin-top: 16px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;

    label {
      width: 10rem;
      margin: 0 3px 0 0;
    }
  }
  .errorText {
    margin: 0 0 0 10.2rem;
    color: #f44336;
    font-size: 0.75rem;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    letter-spacing: 0.03333em;
    line-height: 1;
  }
    .editConferenceWrapper {
      justify-content: inherit !important;
      align-items: inherit !important;
      position: inherit !important;
      bottom: inherit !important;
      padding: 1rem 0 3rem 9.7rem !important;
      border-top: none !important;
    }
`;

export default ConferenceRequestForm;
