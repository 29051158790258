import { formatKeyword } from './keywords';
import { getPaperStatusName, getValidationStatusName } from './translations';
import { TFunction } from 'i18next';
import { Submission } from '../store/submission/types';
import { PaperStatus } from '../store/paper-status/types';
import { getFullName } from './users';
import { columnFilterType, columnOperator } from '../store/table/types';
import i18next from '../i18n';

export const COLUMN_OPERATORS: { [key in columnOperator]: { label: string; filter: columnFilterType } } = {
  avg_word_count: {
    label: i18next.t('Average word count'),
    filter: 'text',
  },
  aggregate: {
    label: i18next.t('Aggregate'),
    filter: 'text',
  },
  average: {
    label: i18next.t('Average'),
    filter: 'numeric',
  },
  min: {
    label: i18next.t('Min'),
    filter: 'numeric',
  },
  max: {
    label: i18next.t('Max'),
    filter: 'numeric',
  },
  reviewer_names: {
    label: i18next.t('Name of reviewers'),
    filter: 'text',
  },
  num_assignments: {
    label: i18next.t('Number of assignments'),
    filter: 'numeric',
  },
  num_reviews_completed: {
    label: i18next.t('Number of reviews completed'),
    filter: 'numeric',
  },
  pct_reviews_completed: {
    label: i18next.t('Percentage of reviews completed'),
    filter: 'numeric',
  },
  num_bids: {
    label: i18next.t('Number of bids'),
    filter: 'numeric',
  },
  bidding_completed: {
    label: i18next.t('Bidding completed'),
    filter: 'boolean',
  },
};

export const isOtherColumnOperator = (operator: columnOperator): boolean => {
  return [
    'num_assignments',
    'num_reviews_completed',
    'pct_reviews_completed',
    'reviewer_names',
    'num_bids',
    'bidding_completed',
  ].includes(operator);
};

export const formatKeywordColumn = (newRegister: Record<any, any>, keywordById: { [key: number]: Keyword }): void => {
  newRegister.keywords = newRegister.keyword_ids.map((id: number) => formatKeyword(id, keywordById)).join(';');
  // Add main area field
  if (newRegister.keyword_ids.length > 0) {
    const firstKeyword = keywordById[newRegister.keyword_ids[0]];
    if (firstKeyword.parent_id) {
      newRegister.main_area = keywordById[firstKeyword.parent_id].name;
    }
  }
};

export const isQuestionColumn = (fieldId: string): boolean => {
  return fieldId.startsWith('question_');
};

export const isCustomColumn = (fieldId: string): boolean => {
  return fieldId.startsWith('custom_');
};

export const getSubmissionRow = (
  submission: Submission,
  canViewAuthors: boolean,
  keywordById: { [key: number]: Keyword },
  paperStatusById: { [key: number]: PaperStatus },
  t: TFunction<'translation', undefined>,
) => {
  const row = { ...submission };

  // Fill keyword information
  formatKeywordColumn(row, keywordById);

  // @ts-ignore
  row.authors_str = canViewAuthors ? row.authors.map((author) => getFullName(author)).join(', ') : t('Not visible');

  row.paper_status = getPaperStatusName(paperStatusById[row.paper_status_id].name, t);

  // @ts-ignore
  row.file_upload_str = row.file_upload?.filename_original;

  // @ts-ignore
  row.validation_status = getValidationStatusName(row.validation_status, t);

  return row;
};
