import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, CancelButton } from '../../ui';
import { gridSettingsProps } from '../../MyGridCore/types';
import { KlistBox as ListBox } from '../../ui/inputs/KListBox/KListBox';
import {
  ListBoxDragEvent,
  ListBoxItemClickEvent,
  ListBoxToolbar,
  ListBoxToolbarClickEvent,
  processListBoxData,
  processListBoxDragAndDrop,
} from '@progress/kendo-react-listbox';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import tw from 'twin.macro';
import { TableSettings } from '../../MyGridRedux/types';
import { nanoid } from '@reduxjs/toolkit';
import { isCustomColumn, isQuestionColumn } from '../../../helpers/table';
import { useTranslation } from 'react-i18next';
import { getTableColumnName } from '../../../helpers/translations';

interface showColumnsInterface {
  id: string;
  name: string;
  selected: boolean;
  show?: boolean;
}

type stateInferfaceColumns = 'hiddenColumns' | 'shownColumns';

interface stateInterface {
  hiddenColumns: showColumnsInterface[];
  shownColumns: showColumnsInterface[];
  draggedItem: showColumnsInterface | {};
}

// https://www.telerik.com/kendo-react-ui/components/listbox/customization/
const MyCustomItem = (props: any) => {
  const { dataItem, ...others } = props;
  return (
    <li id={nanoid()} {...others}>
      <div className="flex items-start justify-between mb-1">
        <span style={{ maxWidth: '22.6rem' }}>{props.dataItem.name}</span>
        {(isQuestionColumn(dataItem.id) || isCustomColumn(dataItem.id)) && (
          <div className="rounded-full bg-gray-200 py-0.5 text-center" style={{ width: '5.7rem', fontSize: '0.65rem' }}>
            {dataItem.id.includes('question') ? 'Form question' : 'Aggregate'}
          </div>
        )}
      </div>
    </li>
  );
};

interface Props {
  tableSettings: TableSettings;
  handleOk: (gridSettings: gridSettingsProps, doRefresh: boolean) => void;
  onClose: () => void;
}

const ColumnSelectionDialog: React.FC<Props> = ({ tableSettings, handleOk, onClose }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const getName = (column: any) => {
    return (column.parent ? '[' + column.parent + ']: ' : '') + (getTableColumnName(column.id, t) ?? column.title);
  };

  const initState = () => {
    const gridColumnProps = [...tableSettings.gridSettings.gridColumnProps];

    const data: showColumnsInterface[] = gridColumnProps
      .sort((a, b) => {
        return a.orderIndex > b.orderIndex ? 1 : -1;
      })
      .map((column) => {
        return {
          id: column.id,
          name: getName(column),
          selected: column.orderIndex > 0,
        };
      });

    return {
      hiddenColumns: data.filter((a) => !a.selected),
      shownColumns: data.filter((a) => a.selected).map((a) => ({ ...a, selected: false })),
      draggedItem: {},
    };
  };

  const SELECTED_FIELD = 'selected';

  const [state, setState] = React.useState<stateInterface>(initState);

  const acHiddenColumns = state.hiddenColumns.map((a) => a.id);
  const acShownColumns = state.shownColumns.map((a) => a.id);

  const handleItemClick = (
    event: ListBoxItemClickEvent,
    data: stateInferfaceColumns,
    connectedData: stateInferfaceColumns,
  ) => {
    setState({
      ...state,
      [data]: state[data].map((item) => {
        if (item.id === event.dataItem.id) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const applyChanges = () => {
    let settingsChanged = false;
    let doRefresh = false;
    let targetPos;

    const newGridColumnProps: any[] = state.hiddenColumns.concat(state.shownColumns).map((columnInterface) => {
      const { id } = columnInterface;
      const find = tableSettings.gridSettings.gridColumnProps.find((element) => element.id === id);
      const element = find ? { ...find } : columnInterface; // If find is undefined it means it is a columns just recently created by the user

      // Track hidden/show change
      let targetVal = false;
      if (acHiddenColumns.includes(id)) targetVal = false;
      if (acShownColumns.includes(id)) targetVal = true;
      const show = element.orderIndex > 0;
      if (show !== targetVal) {
        if (!targetVal) {
          // Case when the column went from shown => hidden
          element.orderIndex = 0;
          settingsChanged = true;
        } else if (isCustomColumn(element.id)) {
          // Case when the column is custom and went from hidden => shown
          doRefresh = true;
        }
      }

      // Track change in order
      targetPos = acShownColumns.findIndex((a) => a == id);
      if (element.orderIndex - 1 !== targetPos) {
        // orderIndex is 1-based index
        element.orderIndex = targetPos + 1;
        settingsChanged = true;
      }

      return element;
    });

    if (settingsChanged) {
      handleOk({ ...tableSettings.gridSettings, gridColumnProps: newGridColumnProps }, doRefresh);
    }
    onClose();
  };

  const handleToolBarClick = (e: ListBoxToolbarClickEvent) => {
    const toolName: string = e.toolName || '';
    const result: any = processListBoxData(state.hiddenColumns, state.shownColumns, toolName, SELECTED_FIELD);
    setState({
      ...state,
      hiddenColumns: result.listBoxOneData,
      shownColumns: result.listBoxTwoData,
    });
  };

  const handleDragStart = (e: ListBoxDragEvent) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const handleDrop = (e: ListBoxDragEvent) => {
    const result: any = processListBoxDragAndDrop(
      state.hiddenColumns,
      state.shownColumns,
      state.draggedItem,
      e.dataItem,
      'name',
    );
    setState({
      ...state,
      hiddenColumns: result.listBoxOneData,
      shownColumns: result.listBoxTwoData,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        style={{ minWidth: 1024 }}
        className="h-5/6 manageColumnsWrapper"
      >
        <StyledDiv>
          <div className="flex">
            <div className="title">{t('Table columns visibility')}</div>
          </div>
          <div className="flex ">
            <div className="flex-1">
              <div className="settingscolhdr mb-2">{t('Hidden columns')}</div>
              <StyledListBox
                style={{ height: 250, width: '100%' }}
                data={state.hiddenColumns}
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, 'hiddenColumns', 'shownColumns')}
                onDragStart={handleDragStart}
                onDrop={handleDrop}
                toolbar={() => {
                  return (
                    <ListBoxToolbar
                      tools={['moveUp', 'moveDown', 'transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']}
                      data={state.hiddenColumns}
                      dataConnected={state.shownColumns}
                      onToolClick={handleToolBarClick}
                    />
                  );
                }}
                item={MyCustomItem}
              />
            </div>
            <div className="m-1"> </div>
            <div className="flex-1">
              <div className="settingscolhdr mb-2">{t('Shown columns')}</div>
              <StyledListBox
                style={{ height: 250, width: '100%' }}
                data={state.shownColumns}
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, 'shownColumns', 'hiddenColumns')}
                onDragStart={handleDragStart}
                onDrop={handleDrop}
                item={MyCustomItem}
              />
            </div>
          </div>
        </StyledDiv>

        <StyledButtonsWrapper>
          <DialogActions className="mb-3">
            <CancelButton onClick={onClose}>{t('Cancel')}</CancelButton>
            <Button onClick={applyChanges}>{t('OK')}</Button>
          </DialogActions>
        </StyledButtonsWrapper>
      </Dialog>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  ${tw`px-10 pt-3 pb-6`}

  .title {
    ${tw`font-bold my-2 pt-5`}
    color: ${(props) => props.theme.palette.primary.main};
  }
  .settingsbtn {
    width: 5em;
    padding: 0px;
    background-color: lightblue;
    text-align: center;
    ${tw`mx-2`}
  }
  .settingscolhdr {
    ${tw`text-sm font-bold`}
  }
  .flex-2 {
    flex-grow: 2;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 96%;
  bottom: -1.75rem;
  padding: 0.9rem 0;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
  margin-right: auto;
  margin-left: auto;
`;

const StyledListBox = styled(ListBox)`
  .k-list-ul {
    padding-top: 0.7rem;
  }
  .k-list-item:hover {
    cursor: pointer;
  }
`;

export default ColumnSelectionDialog;
