import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

interface Props {
  open: boolean;
  panelWidthMax?: string;
  panelWidthMin?: string;
}

const FlexSidePanel: React.FC<Props> = ({ open, panelWidthMin = '0', panelWidthMax = '100%', children }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(!open);

  return (
    <StyledLateralInfo
      className="flexSidePanel shadow-lg"
      style={{ width: isCollapsed ? panelWidthMin : panelWidthMax }}
    >
      <div
        className={`${isCollapsed ? 'panelCollapsed' : ''}`}
        style={{ width: isCollapsed ? panelWidthMin : panelWidthMax }}
      >
        <FontAwesomeIcon
          icon={isCollapsed ? faAnglesLeft : faAnglesRight}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
          className={`cursor-pointer`}
        />
        {!isCollapsed && children}
      </div>
    </StyledLateralInfo>
  );
};

export default FlexSidePanel;

const StyledLateralInfo = styled.div`
  width: 100%;
  max-width: 38rem;
  transform: translate(24px, -28px);
  height: calc(100% + 28px);
  background-color: #ffffff;

  .k-editor {
    border: none;
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px;
    width: 38rem;
  }

  iframe {
    height: 100%;
  }

  svg {
    margin-top: 32px;
    margin-left: -31px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .k-editor-content > .k-iframe {
    height: 100% !important;
  }

  .panelCollapsed {
    padding: 0;
  }
`;
