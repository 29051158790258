import { Author } from '../submission/types';

/*
Format string reference: https://github.com/telerik/kendo-intl/blob/master/docs/date-formatting/index.md
Why this options?: https://iso.mit.edu/americanisms/date-format-in-the-united-states/
*/
export const DateFormatEnum = {
  DMY12: 'dd/MM/y h:mm a',
  DMY24: 'dd/MM/y HH:mm',
  MDY12: 'MM/dd/y h:mm a',
  MDY24: 'MM/dd/y HH:mm',
  YMD12: 'y/MM/dd h:mm a',
  YMD24: 'y/MM/dd HH:mm',
};

type DateFormat =
  | DateFormatEnum.DMY12
  | DateFormatEnum.DMY24
  | DateFormatEnum.MDY12
  | DateFormat.MDY24
  | DateFormat.YMD12
  | DateFormat.YMD24;

interface UserState {
  data: User;
  loading: boolean;
}

interface Person {
  id: number;
  person_id: number;
  full_name: string;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  country: string | null;
  language: string | null; // 2 letters language code ISO 639
  timezone: string | null;
  date_format: string | null;
  organization: string | null;
  cmt_id: string | null;
  dblp_key: string | null;
  google_scholar_id: string | null;
  orcid_id: string | null;
  avatar_url: string | null;
}

interface UserAlias {
  id: number;
  email: string;
  email_verified_at: string | null;
}

interface User {
  id: number;
  person: Person;
  user_alias_by_id: { [key: number]: UserAlias };
  password_set: boolean;
  keyword_ids?: number[];
}

interface UserDTO {
  id: number;
  person: Person;
  user_alias_by_id: UserAlias[];
  password_set: boolean;
  impersonated: number | null;
  is_superadmin: boolean;
}

interface UserFormFields {
  first_name: string;
  last_name: string;
  organization: string | null;
  email: string;
}

interface getUserResponse {
  data: UserDTO;
}

interface updateUserResponse {
  data: UserDTO;
}

interface updateUserAvatarResponse {
  data: {
    avatar_url: string;
  };
}

interface deleteUserAvatarResponse {
  data: any;
}

interface postChangePasswordResponse {
  data: any;
}

interface updateMainAliasResponse {
  data: {
    token: string;
  };
}

interface UpdateAvatarDTO {
  avatar_url: string;
}

interface UpdateUserDTO {
  first_name: string;
  middle_name: string;
  last_name: string;
  country: string;
  language: string;
  dblp_key: string;
  google_scholar_id: string;
  orcid_id: string;
  timezone: string | null;
}

interface UpdateUserAvatarDTO {
  avatar: File;
}

interface PostChangePasswordDTO {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}

interface UpdateMainAliasDTO {
  user_id: number;
}

interface getUserKeywordsResponse {
  data: {
    keyword_ids: number[];
  };
}

interface postUserKeywordsDTO {
  keyword_ids: number[];
}

interface getUserByEmailResponse {
  data: {
    email: string;
    first_name: string;
    last_name: string;
    organization: string | null;
  }[];
}

interface postAliasUserResponse {
  [key: number]: UserAlias;
}

interface verifyEmailRequest {
  email: string;
  code: string;
}
