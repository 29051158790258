import { PersonTableRow } from '../store/info/types';
import { ReviewerTableRow } from '../store/bid/types';
import { Author } from '../store/submission/types';
import { TFunction } from 'i18next';
import { Role } from '../store/conference/types';
import { getRoleName } from './translations';
import { User } from '../store/user/types';

export const getReviewer = (
  personRoleId: number,
  users: {
    byId: { [key: number]: PersonTableRow };
    personRoleIdMap: { [key: number]: number };
  },
): ReviewerTableRow | undefined => {
  const person_id = users.personRoleIdMap[personRoleId];
  const reviewer = users.byId[person_id];
  if (!reviewer) return undefined;
  const index = reviewer.person_role_ids.findIndex((value) => value == personRoleId);
  return {
    ...reviewer,
    id: personRoleId,
    person_role_id: personRoleId,
    role_id: reviewer.role_ids[index],
  } as ReviewerTableRow;
};

export const getFullName = (author: Author): string => {
  return author.first_name + (author.middle_name ? ` ${author.middle_name}` : '') + ` ${author.last_name}`;
};

export const getUserAndRoleDisplayName = (
  role: Role,
  external_id: number,
  full_name: string,
  isYou: boolean,
  t: TFunction<'translation', undefined>,
): string => {
  return `${getRoleName(role, t)} ${external_id ? `#${external_id}` : ''} ${
    isYou ? t('(you)') : full_name ? full_name : ''
  }`;
};

export const isProfileCompleted = (user: User): boolean => {
  const person = user.person;
  return (
    !!person.first_name &&
    !!person.last_name &&
    !!person.country &&
    !!person.dblp_key &&
    !!person.google_scholar_id &&
    !!person.orcid_id
  );
};
