import { put, select, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, APIValidationError } from '../api';
import { Await, Result, ValidatedField } from '../api.d';
import errorSlice from '../error/slice';
import history from '../history';
import { setRoleId, setToken } from '../local-storage';
import authSlice from './slice';
import {
  ForgotPasswordDTO,
  ForgotPasswordReponse,
  GetInvitationRequest,
  GetInvitationResponse,
  ImpersonateRequest,
  ImpersonateResponse,
  LeaveImpersonationResponse,
  LoginOAuthDTO,
  LoginOAuthReponse,
  LoginReponse,
  LoginRequest,
  LogoutResponse,
  OAuthFields,
  ResetPasswordDTO,
  ResetPasswordReponse,
  RespondInvitationReponse,
  RespondInvitationRequest,
  SendVerificationEmailReponse,
  SignUpDTO,
  SignUpInvitationRequest,
  SignUpInvitationResponse,
  SignUpOAuthReponse,
  SignUpReponse,
  VerifyEmailDTO,
  VerifyEmailReponse,
} from './types';
import { handleLoginOk } from './commons';
import { selectAuthLoggedIn } from './selector';
import conferenceSlice from '../conference/slice';
import { baseURL } from '../root-saga';
import { getRouteByName } from '../../router/routes';
import { handleUnselectTrack } from '../conference/commons';
import { generatePath } from 'react-router';
import i18next from '../../i18n';
import { selectImpersonatedOriginLocation } from '../selectors';

const login = async (data: LoginRequest): Promise<Result<LoginReponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return apiCall<LoginReponse>(`${baseURL}/api/login`, init);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loginSaga(action: PayloadAction<LoginRequest>): Generator<any, void, any> {
  try {
    const result = (yield login({
      email: action.payload.email,
      password: action.payload.password,
    })) as Await<ReturnType<typeof login>>;

    switch (result.type) {
      case 'ok':
        yield handleLoginOk(result.value.data.token);
        return;

      case 'validation-error':
        yield put(authSlice.actions['LOGIN:KO'](result.value.validation));
        return;
    }
  } catch (e) {
    // yield put(loginKO(e));
  }
}

const loginOAuth = async (data: LoginOAuthDTO): Promise<Result<LoginOAuthReponse, APIValidationError>> => {
  const { queryParams, provider } = data;
  const init = {
    method: 'GET',
  };

  return apiCall<LoginOAuthReponse>(`${baseURL}/api/oauth/login/${provider}${queryParams}`, init);
};

function* loginOAuthSaga(action: PayloadAction<LoginOAuthDTO>): Generator<any, void, any> {
  try {
    const result = (yield loginOAuth(action.payload)) as Await<ReturnType<typeof loginOAuth>>;

    switch (result.type) {
      case 'ok':
        // Case when user has been found in our records
        if (result.value.data.token !== null) {
          yield handleLoginOk(result.value.data.token);
        } else {
          history.push('/signup-oauth');
          yield put(authSlice.actions['SIGNUP_FILL_FORM'](result.value.data.oauth));
        }
        return;

      case 'validation-error':
        history.push('/login');
        yield put(authSlice.actions['LOGIN:KO']());
        yield put(
          errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
        );
        return;
    }
  } catch (e) {
    // yield put(loginKO(e));
  }
}

const logout = async (): Promise<Result<LogoutResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
  };

  return apiCall<LogoutResponse>(`${baseURL}/api/logout`, init);
};

function* logoutSaga() {
  try {
    const result = (yield logout()) as Await<ReturnType<typeof logout>>;
    switch (result.type) {
      case 'ok':
        yield setToken(null);
        yield setRoleId(null);
        yield put(authSlice.actions['LOGOUT:OK']());
        return;
    }
  } catch (e) {
    // error
  }
  return;
}

const forgotPassword = async (data: ForgotPasswordDTO): Promise<Result<ForgotPasswordReponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return apiCall<ForgotPasswordReponse>(`${baseURL}/api/forgot-password`, init);
};

function* forgotPasswordSaga(action: PayloadAction<ForgotPasswordDTO>): Generator<any, void, any> {
  try {
    const data = { email: action.payload.email };
    const result = (yield forgotPassword(data)) as Await<ReturnType<typeof forgotPassword>>;
    switch (result.type) {
      case 'ok':
        yield put(
          errorSlice.actions['OPEN:SNACKBAR']({
            message: i18next.t('Reset password link was sent.'),
            severity: 'success',
          }),
        );
        break;
      case 'validation-error':
        yield put(
          errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
        );
        break;
    }
  } catch (e) {
    // error
  }
  return;
}

const resetPassword = async (data: ResetPasswordDTO): Promise<Result<ResetPasswordReponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return apiCall<ResetPasswordReponse>(`${baseURL}/api/reset-password`, init);
};

function* resetPasswordSaga(action: PayloadAction<ResetPasswordDTO>) {
  try {
    const result = (yield resetPassword(action.payload)) as Await<ReturnType<typeof forgotPassword>>;
    switch (result.type) {
      case 'ok':
        const data = {
          email: action.payload.email,
          password: action.payload.password,
        };
        yield put(authSlice.actions['LOGIN'](data));
        return;

      case 'error':
        yield put(
          errorSlice.actions['OPEN:SNACKBAR']({
            message: i18next.t('Password could not be reset.'),
            severity: 'error',
          }),
        );
        return;
    }
  } catch (e) {
    // error
  }
  return;
}

const signUp = async (data: SignUpDTO): Promise<Result<SignUpReponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return apiCall<SignUpReponse>(`${baseURL}/api/signup`, init);
};

function* signUpSaga(action: PayloadAction<SignUpDTO>) {
  try {
    const result = (yield signUp(action.payload)) as Await<ReturnType<typeof signUp>>;
    switch (result.type) {
      case 'ok':
        yield put(authSlice.actions['SIGNUP:OK']());
        yield put(authSlice.actions.LOADING());
        yield handleLoginOk(result.value.data.token);
        return;

      case 'validation-error':
        yield put(authSlice.actions['SIGNUP:KO'](result.value.validation));
        return;

      case 'error':
        yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: result.value, severity: 'error' }));
        return;
    }
  } catch (e) {
    // error
  }
  return;
}

const signUpOAuth = async (data: OAuthFields): Promise<Result<SignUpOAuthReponse, APIValidationError>> => {
  const { provider, ...rest } = data;
  const init = {
    method: 'POST',
    body: JSON.stringify(rest),
  };

  return apiCall<SignUpOAuthReponse>(`${baseURL}/api/oauth/signup/${provider}`, init);
};

function* signUpOAuthSaga(action: PayloadAction<OAuthFields>) {
  try {
    const result = (yield signUpOAuth(action.payload)) as Await<ReturnType<typeof signUpOAuth>>;
    switch (result.type) {
      case 'ok':
        yield put(authSlice.actions['SIGNUP:OK']());
        yield put(authSlice.actions.LOADING());
        yield handleLoginOk(result.value.data.token);
        return;

      case 'validation-error':
        yield put(authSlice.actions['SIGNUP_OAUTH:KO'](result.value.validation));
        return;

      case 'error':
        yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: result.value, severity: 'error' }));
        return;
    }
  } catch (e) {
    // error
  }
  return;
}

const sendVerificationEmail = async (
  email: string | undefined,
): Promise<Result<SendVerificationEmailReponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ email }),
  };
  return apiCall<SendVerificationEmailReponse>(`${baseURL}/api/email/verification-notification`, init);
};

function* sendVerificationEmailSaga(action: PayloadAction<string | undefined>) {
  try {
    const result = (yield sendVerificationEmail(action.payload)) as Await<ReturnType<typeof sendVerificationEmail>>;
    switch (result.type) {
      case 'ok':
        const email = action.payload;
        // If not email means that we are verifying an alias with verification code. Not need snackbar in this case.
        if (!email) {
          yield put(
            errorSlice.actions['OPEN:SNACKBAR']({
              message: i18next.t('Email verification link was sent.'),
              severity: 'success',
            }),
          );
        }
        return;

      case 'validation-error':
        yield put(
          errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
        );
        break;

      case 'error':
        return;
    }
  } catch (e) {
    // error
  }
  return;
}

const verifyEmail = async (data: VerifyEmailDTO): Promise<Result<VerifyEmailReponse, APIValidationError>> => {
  const { id, token, queryParams } = data;
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<VerifyEmailReponse>(`${baseURL}/api/email/verify/${id}/${token}${queryParams}`, init);
};

function* verifyEmailSaga(action: PayloadAction<VerifyEmailDTO>): Generator<any, void, any> {
  try {
    const result = (yield verifyEmail(action.payload)) as Await<ReturnType<typeof verifyEmail>>;
    switch (result.type) {
      case 'ok':
        yield handleLoginOk(result.value.data.token);
        return;

      case 'validation-error':
        return;

      case 'error':
        return;
    }
  } catch (e) {
    // error
  }
  return;
}

const respondInvitation = async (
  data: RespondInvitationRequest,
): Promise<Result<RespondInvitationReponse, APIValidationError>> => {
  const { token, ...rest } = data;

  const init = {
    method: 'POST',
    auth: false,
    body: JSON.stringify(rest),
  };
  return apiCall<RespondInvitationReponse>(`${baseURL}/api/invitation-response/${token}`, init);
};

function* respondInvitationSaga(action: PayloadAction<RespondInvitationRequest>): Generator<any, void, any> {
  try {
    const result = (yield respondInvitation(action.payload)) as Await<ReturnType<typeof respondInvitation>>;
    switch (result.type) {
      case 'ok':
        /*
         Invitation Declined: end of the process
         Invitation Accepted:
          Case 1: If user does not exist, then show a form asking for first name, last name and the password
          Case 2: If user exists
            Case 2.1: If logged in, redirect to conference list
            Case 2.2: Otherwise, redirect to login page
         */
        const { status, comment } = action.payload;
        const { is_user_new } = result.value.data;
        if (status === 'Declined' || is_user_new) {
          yield put(authSlice.actions['RESPOND_INVITATION:OK']({ status, comment, is_user_new }));
        } else {
          yield put(
            errorSlice.actions['OPEN:SNACKBAR']({
              message: i18next.t('Invitation responded.'),
              severity: 'success',
            }),
          );
          const loggedIn = yield select(selectAuthLoggedIn);
          if (loggedIn) {
            // Case 2.1
            yield put(conferenceSlice.actions.GET_CONFERENCES());
            history.push(getRouteByName('RouteConferences').path);
          } else {
            // Case 2.2
            history.push(getRouteByName('RouteLogin').path);
          }
        }

        return;

      case 'validation-error':
        const errors: ValidatedField[] = result.value.validation;
        yield put(authSlice.actions['RESPOND_INVITATION:KO'](errors));
        yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: errors[0].message, severity: 'error' }));
        return;
    }
  } catch (e) {
    // error
  }
  return;
}

const getInvitation = async (
  data: GetInvitationRequest,
): Promise<Result<GetInvitationResponse, APIValidationError>> => {
  const { token } = data;

  const init = {
    method: 'GET',
    auth: false,
  };
  return apiCall<GetInvitationResponse>(`${baseURL}/api/invitation/${token}`, init);
};

function* getInvitationSaga(action: PayloadAction<GetInvitationRequest>): Generator<any, void, any> {
  try {
    const result = (yield getInvitation(action.payload)) as Await<ReturnType<typeof getInvitation>>;
    switch (result.type) {
      case 'ok':
        yield put(authSlice.actions['GET_INVITATION:OK'](result.value.data));
        return;

      case 'validation-error':
        yield put(authSlice.actions['GET_INVITATION:KO'](result.value.validation));
        return;
    }
  } catch (e) {
    // error
  }
  return;
}

const signUpInvitation = async (
  data: SignUpInvitationRequest,
): Promise<Result<SignUpInvitationResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: false,
    body: JSON.stringify(data),
  };
  return apiCall<SignUpInvitationResponse>(`${baseURL}/api/invitation/signup`, init);
};

function* signUpInvitationSaga(action: PayloadAction<SignUpInvitationRequest>): Generator<any, void, any> {
  try {
    const result = (yield signUpInvitation(action.payload)) as Await<ReturnType<typeof signUpInvitation>>;
    switch (result.type) {
      case 'ok':
        yield put(authSlice.actions['SIGNUP_INVITATION:OK']());
        yield put(
          errorSlice.actions['OPEN:SNACKBAR']({
            message: i18next.t('Invitation responded.'),
            severity: 'success',
          }),
        );
        const { token, track_slug, conference_slug } = result.value.data;
        // Left user within the conference
        history.push(
          generatePath(getRouteByName('RouteConferenceHome').path, { conference: conference_slug, track: track_slug }),
        );
        yield handleLoginOk(token);
        return;

      case 'validation-error':
        const errors: ValidatedField[] = result.value.validation;
        yield put(authSlice.actions['SIGNUP_INVITATION:KO'](errors));
        yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: errors[0].message, severity: 'error' }));
        return;
    }
  } catch (e) {
    // error
  }
  return;
}

const impersonate = async (data: ImpersonateRequest): Promise<Result<ImpersonateResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  const { user_id, track_id } = data;
  const urlStr = `${baseURL}/api/auth/impersonate`;
  const url = new URL(urlStr);
  url.searchParams.set('user_id', user_id ? user_id.toString() : '');
  url.searchParams.set('track_id', track_id ? track_id.toString() : '');
  return apiCall<ImpersonateResponse>(url.toString(), init);
};

function* impersonateSaga(action: PayloadAction<ImpersonateRequest>): Generator<any, void, any> {
  try {
    const result = (yield impersonate(action.payload)) as Await<ReturnType<typeof impersonate>>;
    switch (result.type) {
      case 'ok':
        yield handleUnselectTrack();
        yield handleLoginOk(result.value.data.token);
        break;
      case 'validation-error':
        yield put(authSlice.actions['IMPERSONATE:KO']());
        yield put(
          errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
        );
        break;
    }
  } catch (e) {
    // error
  }
  return;
}

const leaveImpersonation = async (): Promise<Result<LeaveImpersonationResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<LeaveImpersonationResponse>(`${baseURL}/api/auth/leave-impersonation`, init);
};

function* leaveImpersonationSaga(): Generator<any, void, any> {
  try {
    const result = (yield leaveImpersonation()) as Await<ReturnType<typeof leaveImpersonation>>;
    switch (result.type) {
      case 'ok':
        yield handleUnselectTrack();
        // Write the location where user was when impersonation was done in the next variable so that we force redirection after login
        let href = yield select(selectImpersonatedOriginLocation);
        if (!href) {
          const url = new URL(window.location.href);
          url.pathname = getRouteByName('RouteConferences').path;
          href = url.href;
        }
        localStorage.setItem('next', href);
        yield handleLoginOk(result.value.data.token);
        yield put(authSlice.actions['LEAVE_IMPERSONATION:OK']());
        break;
      case 'validation-error':
        yield put(
          errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
        );
        break;
    }
  } catch (e) {
    // error
  }
  return;
}

export default [
  takeLatest(authSlice.actions['LOGIN'].type, loginSaga),
  takeLatest(authSlice.actions['LOGIN_OAUTH'].type, loginOAuthSaga),
  takeLatest(authSlice.actions['LOGOUT'].type, logoutSaga),
  takeLatest(authSlice.actions['FORGOT_PASSWORD'].type, forgotPasswordSaga),
  takeLatest(authSlice.actions['RESET_PASSWORD'].type, resetPasswordSaga),
  takeLatest(authSlice.actions['SIGNUP'].type, signUpSaga),
  takeLatest(authSlice.actions['SIGNUP_OAUTH'].type, signUpOAuthSaga),
  takeLatest(authSlice.actions['SEND_VERIFICATION_EMAIL'].type, sendVerificationEmailSaga),
  takeLatest(authSlice.actions['VERIFY_EMAIL'].type, verifyEmailSaga),
  takeLatest(authSlice.actions['RESPOND_INVITATION'].type, respondInvitationSaga),
  takeLatest(authSlice.actions['GET_INVITATION'].type, getInvitationSaga),
  takeLatest(authSlice.actions['SIGNUP_INVITATION'].type, signUpInvitationSaga),
  takeLatest(authSlice.actions['IMPERSONATE'].type, impersonateSaga),
  takeLatest(authSlice.actions['LEAVE_IMPERSONATION'].type, leaveImpersonationSaga),
];
