import React, { useState } from 'react';
import { BasicInvitationFormFields } from '../../store/recruitment/types';
import { validationErrorTransformer } from '../../store/api';
import { Button, SelectRadioButton, CancelButton } from '../ui';
import FileUploadUpdater from '../FileUploadUpdater/FileUploadUpdater';
import { validateFile, validateInvitationFormFields } from '../../store/recruitment/sagas';
import { Role } from '../../store/conference/types';
import { ValidatedField } from '../../store/api.d';
import styled from 'styled-components';
import { getUserByEmail } from '../../store/user/sagas';
import AddPersonForm from '../AddPersonForm/AddPersonForm';
import { useTranslation } from 'react-i18next';

export type INVITATION_INPUT_TYPE = 'single' | 'many' | 'ids';

const initFields = {
  role: '',
  email: '',
  first_name: '',
  last_name: '',
};

interface Props {
  roleById: { [key: string]: Role };
  onValid: (input: BasicInvitationFormFields[], type: INVITATION_INPUT_TYPE) => void;
  onClose: () => void;
}

const InvitationRecipientPicker: React.FC<Props> = ({ roleById, onValid, onClose }) => {
  const { t, i18n } = useTranslation();
  const [fields, setFields] = useState<BasicInvitationFormFields[]>([initFields]);
  const [mode, setMode] = useState<INVITATION_INPUT_TYPE | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<ValidatedField[]>([]);

  const errors = validationErrors ? validationErrorTransformer(validationErrors) : {};

  const [showNext, setShowNext] = useState<boolean>(false);

  const [showExtraFields, setShowExtraFields] = useState<boolean>(false);

  let content = undefined;

  if (mode === 'single') {
    content = (
      <AddPersonForm
        FData={{
          first_name: fields[0].first_name,
          last_name: fields[0].last_name,
          email: fields[0].email,
          role: Object.values(roleById).find((role) => role.description === fields[0].role)?.id ?? 0,
        }}
        onChange={(value) => {
          setFields([
            {
              first_name: value.first_name,
              last_name: value.last_name,
              email: value.email,
              role: value.role in roleById ? roleById[value.role].description : '',
            },
          ]);
        }}
        showExtraFields={showExtraFields}
        roleById={roleById}
        errors={errors}
      />
    );
  } else if (mode === 'many') {
    content = (
      <FileUploadUpdater
        doPreview={(file) => {
          return validateFile(file);
        }}
        templateName="invitations"
        error={errors.file}
        onFeedbackHandler={(data) => {
          const filtered = data.data.rows.filter((value) => !value.has_error);
          setShowNext(filtered.length > 0);
          setFields(
            filtered.map((value) => {
              return {
                email: value.values[0],
                role: value.values[1],
                first_name: value.values[2],
                last_name: value.values[3],
              };
            }),
          );
        }}
      />
    );
  } else {
    // mode === 'ids'
    content = undefined;
  }

  return (
    <>
      <StyledFieldsetWrapper>
        <fieldset className={`text-sm`}>
          <StyledSelectRadioButton
            label={t('How many invitations do you want to create? ')}
            options={[
              { id: 'single', label: t('Just one') },
              { id: 'many', label: t('Many') },
            ]}
            value={mode}
            onChange={(value) => {
              setMode(value as INVITATION_INPUT_TYPE);
              setValidationErrors([]);
              setShowExtraFields(false);
            }}
          />
        </fieldset>
      </StyledFieldsetWrapper>

      {content}

      <StyledButtons>
        <CancelButton onClick={onClose}>{t('Cancel')}</CancelButton>

        {((mode === 'many' && showNext) || mode === 'single' || mode === 'ids') && (
          <Button
            onClick={async () => {
              if (mode === 'single') {
                let tempFields: BasicInvitationFormFields = {
                  ...fields[0],
                  first_name: fields[0].first_name || 'placeholder',
                  last_name: fields[0].last_name || 'placeholder',
                };

                // Call API here to validate input
                validateInvitationFormFields(tempFields).then(async (resp) => {
                  switch (resp.type) {
                    case 'ok':
                      setValidationErrors([]);
                      const res = await getUserByEmail(tempFields.email);
                      switch (res.type) {
                        case 'ok':
                          const { data } = res.value;
                          if (data.length > 0) {
                            // Case user found
                            tempFields = {
                              ...tempFields,
                              first_name: data[0].first_name,
                              last_name: data[0].last_name,
                            };
                            onValid([tempFields], mode);
                          } else if (!showExtraFields) {
                            // Case user not found. Ask for extra fields
                            setShowExtraFields(true);
                          } else {
                            onValid([fields[0]], mode);
                          }
                          break;
                        case 'validation-error':
                          break;
                        case 'error':
                          break;
                      }
                      break;
                    case 'validation-error':
                      setValidationErrors(Object.values(resp.value.validation));
                      break;
                  }
                });
              } else if (mode === 'many') {
                onValid(fields, mode);
              }
            }}
            disabled={
              fields[0].email === '' ||
              fields[0].role === '' ||
              (showExtraFields && (fields[0].first_name === '' || fields[0].last_name === ''))
            }
          >
            {t('Next')}
          </Button>
        )}
      </StyledButtons>
    </>
  );
};

const StyledFieldsetWrapper = styled.div`
  fieldset {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 2rem auto 1rem auto;
    padding: 1rem;
  }
  .disabled {
    > div {
      label {
        color: #ccc;
        pointer-events: none;
      }
      > div {
        input {
          border-color: #ccc;
          pointer-events: none;
        }
      }
    }
  }
`;

const StyledSelectRadioButton = styled(SelectRadioButton)`
  label {
    margin-right: 1rem;
    vertical-align: text-bottom;
  }
`;

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  padding: 1rem 0 0 0;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  padding-bottom: 1.5rem;
`;

export default InvitationRecipientPicker;
