import React from 'react';
import styled from 'styled-components';
import '../../components/Dashboard/Dashboard.styles.css';
import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from 'react-i18next';
import EmptyDashboard from './EmptyDashboard';

interface DashboardBiddingProps {
  dashboardState: DashboardState;
  roleSelect: JSX.Element;
}

const DashboardBidding: React.FC<DashboardBiddingProps> = ({ dashboardState, roleSelect }) => {
  const { t, i18n } = useTranslation();

  const getBiddingStatusName = (id: 'yet-to-start' | 'finished' | 'in-progress') => {
    switch (id) {
      case 'yet-to-start':
        return t('Yet to start');
      case 'finished':
        return t('Finished');
      case 'in-progress':
        return t('In progress');
    }
  };

  const biddingState = dashboardState.data.bidding_state ?? [];

  /*
  const biddingState = [
    {
      id: 'yet-to-start',
      label: 'Yet to start',
      value: 1000,
    },
    {
      id: 'finished',
      label: 'Finished',
      value: 100,
    },
    {
      id: 'in-progress',
      label: 'In progress',
      value: 50,
    },
  ];
  */
  const totalBidding = biddingState.reduce((sum: number, bidding: { value: number }) => sum + bidding.value, 0);

  return (
    <>
      <div className="flex flex-row justify-end mb-12 w-full">
        <StyledSelectWrapper className="flex flex-row justify-end items-baseline">{roleSelect}</StyledSelectWrapper>
      </div>

      <div
        className="flex flex-row justify-between mb-4 w-full biddingPerTypeChart"
        style={{ height: '32rem', minWidth: 540 }}
      >
        {/* BIDDING PER TYPE */}
        <div className="relative bg-white rounded-lg shadow-md p-8 w-full">
          <h2 className="font-bold text-xl text-center">{t('Bidding state')}</h2>

          {Object.keys(biddingState).length > 0 && totalBidding > 0 ? (
            <>
              <ResponsivePie
                data={(biddingState as any[]).map((value) => {
                  // Just overwrite label with according translation
                  return { ...value, label: getBiddingStatusName(value.id) };
                })}
                sortByValue={true}
                margin={{ top: 50, right: 80, bottom: 80, left: 80 }}
                padAngle={0}
                cornerRadius={1}
                colors={['#4f91e0', '#6abdfb', '#78d2f3', '#7fdcff', '#9bffef', '#a2f9fb', '#a9f5ff']}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.5]],
                }}
                arcLinkLabel="label"
                arcLinkLabelsSkipAngle={2}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsStraightLength={25}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabelsSkipAngle={5}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]],
                }}
                tooltip={(point) => {
                  return (
                    <div className="text-sm bg-white px-2 py-1 shadow-md flex flex-row">
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: point.datum.color,
                          marginTop: '4px',
                          marginRight: '8px',
                        }}
                      ></div>
                      <div>
                        {point.datum.label}: <strong>{point.datum.value}</strong>
                      </div>
                    </div>
                  );
                }}
                layers={['arcs', 'arcLabels', 'arcLinkLabels']}
              />
            </>
          ) : (
            <EmptyDashboard className={'mt-36'} />
          )}
        </div>
      </div>
    </>
  );
};

const StyledSelectWrapper = styled.div`
  > div {
    margin-right: 0.85rem;
    min-width: inherit;
  }
`;

export default DashboardBidding;
