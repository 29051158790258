import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="none" viewBox="0 0 120 120">
      <g fill="#D3E1FF" clipPath="url(#clip0_3_59)">
        <path
          fillRule="evenodd"
          d="M45 10c-13.807 0-25 11.193-25 25s11.193 25 25 25 25-11.193 25-25-11.193-25-25-25M30 35c0-8.284 6.716-15 15-15s15 6.716 15 15-6.716 15-15 15-15-6.716-15-15"
          clipRule="evenodd"
        ></path>
        <path d="M25 70A25 25 0 0 0 0 95v10a5 5 0 0 0 10 0V95a15 15 0 0 1 15-15h40a15 15 0 0 1 15 15v10a5 5 0 0 0 10 0V95a25 25 0 0 0-25-25zM95.16 74.4a5 5 0 0 1 6.091-3.591 25 25 0 0 1 18.75 24.187V105a5 5 0 1 1-10 0v-9.996a15.005 15.005 0 0 0-11.25-14.513A5 5 0 0 1 95.16 74.4M81.241 10.806a5 5 0 1 0-2.48 9.688 15 15 0 0 1 0 29.062 5 5 0 1 0 2.48 9.688 25 25 0 0 0 0-48.438"></path>
      </g>
      <defs>
        <clipPath id="clip0_3_59">
          <path fill="#fff" d="M0 0h120v120H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
