import React from 'react';
import { Button, CancelButton } from '../ui';
import { Submission } from '../../store/submission/types';
import KeywordPicker from '../KeywordPicker/KeywordPicker';
import { Answer, Form, Question } from '../../store/form/types.d';
import { OpenSnackBarDTO } from '../../store/error/types';
import TabForms from '../TabForms/TabForms';
import GridTabLayout from '../../layouts/GridTabLayout';
import { User } from '../../store/user/types';
import useTheme from '@material-ui/core/styles/useTheme';
import { computeDefaultQuestionsNumber, isCompleted } from '../../helpers/form';
import { ValidatedField } from '../../store/api.d';
import { validationErrorTransformer } from '../../store/api';
import styled, { ThemeProvider } from 'styled-components';
import ChipMessage from '../ui/display/Chip/ChipMessage';
import { Role } from '../../store/conference/types';
import { useTranslation } from 'react-i18next';
import DefaultSubmissionForm from '../DefaultSubmissionForm/DefaultSubmissionForm';

interface Props {
  initSubmission: Submission;
  onSaveHandler?: (data: Submission) => void;
  onCancelClick?: () => void;
  keywordState: KeywordState;
  user: User;
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
  formsData: { form: Form; questions: Question[]; answers: Answer[] }[];
  userRole: Role;
  validationErrors?: ValidatedField[];
  canViewFeedback: boolean;
}

type TabOption = 'submission' | 'keywords';

const SubmissionForm: React.FC<Props> = ({
  initSubmission,
  onSaveHandler,
  onCancelClick,
  keywordState,
  user,
  openSnackBarAction,
  formsData,
  userRole,
  validationErrors,
  canViewFeedback,
}) => {
  const { t, i18n } = useTranslation();
  const [tab, setTab] = React.useState<TabOption>('submission');
  const [submission, setSubmission] = React.useState<Submission>(initSubmission);
  const theme = useTheme();

  const errors = validationErrors ? validationErrorTransformer(validationErrors) : {};

  const defaultSubmissionFormData = formsData.find(({ form }) => form.is_default);

  /* Here is where we replace answers by those in the current form */
  const formsData_ = formsData.map(({ form, questions }) => {
    const questionIds = questions.map((question) => question.id);
    return {
      form,
      questions,
      answers: (submission.answers ?? []).filter((answer) => questionIds.includes(answer.question_id)),
    };
  });

  const completed = isCompleted(formsData_, submission);

  let content;

  const canViewAuthors = true;

  switch (tab) {
    case 'submission':
      // Main page content
      content = (
        <ThemeProvider theme={theme}>
          <div className="flex flex-grow flex-col">
            {/* MAIN FORM */}
            <fieldset className="TEST flex flex-col w-full max-w-6xl text-sm mb-4 h-full flex-grow">
              <StyledTitleWrapper className="flex justify-between">
                {/* PAGE TITLE */}
                <legend>
                  {initSubmission.id
                    ? t(`Edit Submission #{{externalId}}`, { externalId: submission.external_id })
                    : t('Create submission')}
                </legend>

                {canViewFeedback && !completed && (
                  <ChipMessage message={t('Required questions pending')} colorType="warning" className="mb-1" />
                )}
              </StyledTitleWrapper>

              {/* TAB FORMS */}
              <StyledTabFormsWrapper className="mt-8 tab-forms-wrapper">
                <TabForms
                  data={{ answerable: submission, formsData: formsData_ }}
                  answerQuestionHandler={(data) => {
                    const answers = [...(submission.answers ?? [])];
                    const idx = answers.findIndex((answer) => answer.question_id == data.question_id);
                    if (idx != -1) {
                      answers[idx] = { ...answers[idx], ...data };
                    }
                    setSubmission({
                      ...submission,
                      answers: answers,
                    });
                  }}
                  openSnackBarAction={openSnackBarAction}
                  mode="edit"
                  validationErrors={validationErrors}
                  canViewFeedback={canViewFeedback}
                  defaultSubmissionQuestions={
                    defaultSubmissionFormData &&
                    defaultSubmissionFormData.form.default_questions && (
                      <DefaultSubmissionForm
                        submission={submission}
                        default_questions={defaultSubmissionFormData.form.default_questions}
                        canViewAuthors={canViewAuthors}
                        keywordById={keywordState.keywordById}
                        setSubmission={setSubmission}
                        errors={errors}
                        canViewFeedback={canViewFeedback}
                        userRole={userRole}
                        personId={user.person.id}
                        onKeywordEditClick={() => setTab('keywords')}
                        openSnackBarAction={openSnackBarAction}
                      />
                    )
                  }
                  numDefaultQuestions={computeDefaultQuestionsNumber(
                    keywordState.keywordById,
                    canViewAuthors,
                    defaultSubmissionFormData?.form.default_questions?.abstract.hidden ?? false,
                  )}
                />
              </StyledTabFormsWrapper>
            </fieldset>

            {/* FOOTER BUTTONS */}
            {(onCancelClick || onSaveHandler) && (
              <StyledButtonsWrapper>
                {onCancelClick && <CancelButton onClick={onCancelClick}>{t('Cancel')}</CancelButton>}
                {onSaveHandler && (
                  <Button className="w-48 mx-2" onClick={() => onSaveHandler(submission)}>
                    {completed ? t('Submit') : t('Save')}
                  </Button>
                )}
              </StyledButtonsWrapper>
            )}
          </div>
        </ThemeProvider>
      );
      break;
    case 'keywords':
      content = (
        <StyledGridTabLayoutWrapper>
          <GridTabLayout onBackClick={() => setTab('submission')} tabTitle={t('Keywords')} backButton={false}>
            <KeywordPicker
              selectedIds={submission.keyword_ids}
              keywordById={keywordState.keywordById}
              keywordTree={keywordState.keywordTree}
              maxAllowed={6}
              onSelected={(selectedIds) => setSubmission({ ...submission, keyword_ids: selectedIds })}
              openSnackBarAction={openSnackBarAction}
              sectionTitle={true}
              onBackClick={() => setTab('submission')}
            />
          </GridTabLayout>
        </StyledGridTabLayoutWrapper>
      );
      break;
  }

  return <>{content}</>;
};

export default SubmissionForm;

const StyledTitleWrapper = styled.div`
  border-bottom: 2px dotted ${(props) => props.theme.palette.secondary.dark};

  legend {
    margin-right: 0.75rem;
    font-size: 1.25rem;
    font-weight: 700;
  }

  .status-submission-button {
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 0.5rem;
  }

  .complete-submission {
    background-color: rgb(31, 152, 1);
  }

  .incomplete-submission {
    background-color: rgb(220, 38, 38);
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
  z-index: 2;
`;

const StyledGridTabLayoutWrapper = styled.div`
  flex-grow: 1;
  display: flex;

  .gridtab-layout-wrapper,
  .gridtab-layout-children {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
`;

const StyledTabFormsWrapper = styled.div`
  .fuploaderWrapper {
    .text-black {
      >div: first-child span {
        color: rgb(0, 68, 240);
      }
    }
  }
  .fullWidth {
    display: block;

    label {
      font-weight: 600;
      margin-bottom: 0.5rem;
      display: block;
    }
  }
`;
