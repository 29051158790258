import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppDispatch } from '../store/store';
import { Submission } from '../store/submission/types';
import {
  selectCurrentRole,
  selectCurrentUser,
  selectErrorState,
  selectKeywordState,
  selectAssignmentState,
  selectSubmissionState,
  selectTrackRoles,
} from '../store/selectors';
import { Answer, Form, FormTypeEnum, Question } from '../store/form/types.d';
import Loading from '../components/Loading/Loading';
import { Prompt, useParams } from 'react-router-dom';
import { emptyAnswer, getFormsData } from '../helpers/form';
import { OpenSnackBarDTO } from '../store/error/types';
import errorSlice from '../store/error/slice';
import SubmissionForm from '../components/SubmissionForm/SubmissionForm';
import submissionSlice from '../store/submission/slice';
import { AppState } from '../store/state';
import { Role } from '../store/conference/types';
import { User } from '../store/user/types';
import { goBackFromSubmissionDetail } from '../components/SubmissionDetail/SubmissionDetail';
import { SubmissionPermissionManager } from '../helpers/permissions';
import { ValidatedField } from '../store/api.d';
import { difference } from '../helpers/set';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  submissionsById: { [key: string]: Submission };
  keywordState: KeywordState;
  user: User;
  roleId?: number;
  getSubmissionsAction: () => void;
  updateSubmissionAction: (data: Submission) => void;
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
  roleById: { [key: string]: Role };
  validationErrors?: ValidatedField[];
  clearValidationErrorsAction: () => void;
}

export const initializeAnswers = (formsData: { form: Form; questions: Question[]; answers: Answer[] }[]): Answer[] => {
  const initAnswers = formsData
    .map(({ answers }) => answers)
    .flat()
    .map((answer) => ({ ...answer }));

  // Add empty answer for question without answer object
  formsData.map(({ questions, answers }) => {
    const missingIds: Set<number> = difference(
      questions.map((question) => question.id),
      initAnswers.map((answer) => answer.question_id),
    );
    const emptyAnswers = Array.from(missingIds).map((questionId) => emptyAnswer(questionId));
    initAnswers.push(...emptyAnswers);
  });

  return initAnswers;
};

const SubmissionEditPage: React.FC<Props> = ({
  loading,
  submissionsById,
  keywordState,
  user,
  roleId,
  getSubmissionsAction,
  updateSubmissionAction,
  openSnackBarAction,
  roleById,
  validationErrors,
  clearValidationErrorsAction,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<Submission | undefined>(undefined);
  const isBlocking = data === undefined || validationErrors !== undefined; // Initial behaviour is to be blocking

  const isChair = roleId ? roleById[roleId].type == 'chair' : false;

  useEffect(() => {
    if (isChair) {
      if (Object.keys(submissionsById).length === 0) {
        getSubmissionsAction();
      }
    }

    // Clear any validation error on exit
    return () => clearValidationErrorsAction();
  }, []);

  useEffect(() => {
    if (data) {
      // Save click
      updateSubmissionAction(data);
    }
  }, [data]);

  const params: any = useParams();
  const { id: external_id } = params;

  if (!roleId || loading) {
    return <Loading />;
  }

  // Given id must match with external_id field
  const findSubmission = Object.values(submissionsById).find((submission) => submission.external_id == external_id);
  if (!findSubmission) {
    return <div>Submission #{external_id} not found</div>;
  }

  const authorRole = Object.values(roleById).find((role) => role.type == 'author');
  if (!authorRole) throw Error('Author role does not exist.');

  const currentRole = roleById[roleId];

  // Get Submission type forms
  const isOwner = new SubmissionPermissionManager(findSubmission).isPrimaryAuthor(user.person.id, currentRole);
  const submissionFormsData = getFormsData(
    FormTypeEnum.Submission,
    ['write'],
    roleId,
    isOwner ? null : authorRole.id,
    user.person.id,
    findSubmission,
  );

  const submission = submissionFormsData.answerable as Submission;

  const canEdit = new SubmissionPermissionManager(submission).canEdit(
    user,
    currentRole,
    submissionFormsData.formsData.map((value) => value.form),
  );

  return canEdit ? (
    <>
      <Prompt when={isBlocking} message={t('Are you sure you want to leave?')} />
      <SubmissionForm
        initSubmission={data ?? { ...submission, answers: initializeAnswers(submissionFormsData.formsData) }}
        onSaveHandler={(data) => setData(data)}
        onCancelClick={() => {
          // Cancel click
          if (roleId) {
            const currentRoleType = roleById[roleId].type;
            goBackFromSubmissionDetail(currentRoleType);
          }
        }}
        keywordState={keywordState}
        user={user}
        openSnackBarAction={openSnackBarAction}
        formsData={submissionFormsData.formsData}
        userRole={currentRole}
        canViewFeedback={canEdit}
        validationErrors={validationErrors}
      />
    </>
  ) : (
    <div>Forbidden</div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading || selectAssignmentState(state).loading,
  submissionsById: selectSubmissionState(state).submissionsById,
  keywordById: selectKeywordState(state).keywordById,
  keywordState: selectKeywordState(state),
  user: selectCurrentUser(state),
  roleId: selectCurrentRole(state)?.id,
  roleById: selectTrackRoles(state),
  validationErrors: selectErrorState(state).validationErrors,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: () => dispatch(submissionSlice.actions.GET_SUBMISSIONS()),
  updateSubmissionAction: (data: Submission) => dispatch(submissionSlice.actions['UPDATE_SUBMISSION'](data)),
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
  clearValidationErrorsAction: () => dispatch(errorSlice.actions['CLEAR:VALIDATION_ERRORS']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionEditPage);
