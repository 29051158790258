import React from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { AppState } from '../../../store/state';
import { selectTrackRoles } from '../../../store/selectors';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import { Role } from '../../../store/conference/types';
import MultiSelectCheckbox from '../../ui/inputs/MultiSelectCheckbox/MultiSelectCheckbox';
import { useTranslation } from 'react-i18next';
import { getRoleName } from '../../../helpers/translations';

interface Props extends SimpleDialogProps {
  title: string;
  initialOption: string[];
  roleById: { [key: string]: Role };
  doOkClick: (selectedOption: string[]) => void;
}

const RolesDialog: React.FC<Props> = ({ title, initialOption, roleById, doOkClick, ...rest }) => {
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = React.useState<string[]>(initialOption);
  return (
    <SimpleDialog {...rest} title={title} handleOk={() => doOkClick(selectedOption)}>
      <MultiSelectCheckbox
        className="grid justify-items-start space-y-3"
        value={selectedOption}
        options={Object.values(roleById)
          .filter((value) => value.type != 'author')
          .map((value) => ({ id: value.id.toString(), label: getRoleName(value, t) }))}
        onChange={(value) => setSelectedOption(value)}
      />
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  roleById: selectTrackRoles(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RolesDialog);
