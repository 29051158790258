import React, { useState } from 'react';
import { Button, InputText, CancelButton } from '../ui';
import { OptionsData } from '../FormBuilder/FormBuilder';
import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';

interface Props {
  initialOption?: OptionsData;
  saveHandler: (option: OptionsData) => void;
  cancelHandler: () => void;
  withNumericalValue?: boolean;
}

const OptionInput: React.FC<Props> = ({ initialOption, saveHandler, cancelHandler, withNumericalValue }) => {
  const { t, i18n } = useTranslation();
  const emptyOption: OptionsData = { id: '', label: '', value: undefined };
  const [option, setOption] = useState<OptionsData>(initialOption ?? emptyOption);
  const theme = useTheme();

  const isValid = () => {
    let ok = !!option.label;
    if (withNumericalValue) {
      ok = ok && typeof option.value === 'number';
    }
    return ok;
  };

  return (
    <div className="w-full">
      <div className="flex items-end">
        <InputText
          label={t('Name')}
          className="option-input"
          value={option.label}
          onChange={(e) => setOption({ ...option, label: e.target.value })}
        />
        {withNumericalValue && (
          <InputText
            label={t('Number')}
            type="number"
            value={option.value}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setOption({ ...option, value: isNaN(value) ? undefined : value });
            }}
            className="ml-2 w-16"
          />
        )}
        <Button
          className="ml-3 mr-3"
          variant="text"
          onClick={() => saveHandler(option)}
          style={{
            color: `${theme.palette.primary.main}`,
            padding: '0',
          }}
          disabled={!isValid()}
        >
          {option.id ? t('Update') : t('Add')}
        </Button>
        <CancelButton onClick={() => cancelHandler()}>{t('Cancel')}</CancelButton>
      </div>
    </div>
  );
};

export default OptionInput;
