import { getReviewsResponse, AssignmentState, ValidationStatusDTO, viewModeType, UpdateAssignment } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: AssignmentState = {
  loading: false,
  assignments: [],
  viewMode: 'cards',
  updatingId: null,
};

const assignmentSlice = createSlice({
  name: 'REVIEW',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET_REVIEWS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET_REVIEWS:OK']: (state, action: PayloadAction<getReviewsResponse>) => ({
      ...state,
      loading: false,
      assignments: action.payload.data.assignments,
    }),
    ['GET_ASSIGNMENT_DETAILS']: (state, action: PayloadAction<number>) => ({
      ...state,
      loading: true,
    }),
    ['UPDATE_STATUS']: (state, action: PayloadAction<ValidationStatusDTO>) => ({
      ...state,
      assignments: state.assignments.map((assignment) => {
        return {
          ...assignment,
          validation_status:
            assignment.id == action.payload.review_id ? action.payload.validation_status : assignment.validation_status,
        };
      }),
    }),
    ['CHANGE_VIEWMODE']: (state, action: PayloadAction<viewModeType>) => {
      return {
        ...state,
        viewMode: action.payload,
      };
    },
    ['ASSIGNMENTS:UPDATE']: (state, action: PayloadAction<UpdateAssignment>) => ({
      ...state,
      updatingId: action.payload.modelId,
    }),
    ['ASSIGNMENTS:UPDATE:OK']: (state) => ({
      ...state,
      updatingId: initialState.updatingId,
    }),
  },
});

export default assignmentSlice;
