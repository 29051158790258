import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { selectCurrentTrack, selectKeywordState, selectUser } from '../store/selectors';
import Loading from '../components/Loading/Loading';
import KeywordPicker from '../components/KeywordPicker/KeywordPicker';
import { UserState, postUserKeywordsDTO } from '../store/user/types';
import userSlice from '../store/user/slice';
import { OpenSnackBarDTO } from '../store/error/types';
import errorSlice from '../store/error/slice';
import styled from 'styled-components';

interface Props {
  keywordState: KeywordState;
  userState: UserState;
  postUserKeywordsAction: (data: postUserKeywordsDTO) => void;
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
}

const KeywordsPage: React.FC<Props> = ({ keywordState, userState, postUserKeywordsAction, openSnackBarAction }) => {
  if (userState.loading) return <Loading />;

  const user = userState.data;

  return (
    <StyledKeywordPickerWrapper className="h-full">
      <KeywordPicker
        selectedIds={user.keyword_ids ?? []}
        keywordById={keywordState.keywordById}
        keywordTree={keywordState.keywordTree}
        maxAllowed={6}
        onSelected={(selectedIds) => postUserKeywordsAction({ keyword_ids: selectedIds })}
        openSnackBarAction={openSnackBarAction}
      />
    </StyledKeywordPickerWrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  userState: selectUser(state),
  track: selectCurrentTrack(state),
  keywordState: selectKeywordState(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  postUserKeywordsAction: (data: postUserKeywordsDTO) => dispatch(userSlice.actions['POST:KEYWORDS'](data)),
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
});

const StyledKeywordPickerWrapper = styled.div`
  .keywordsFooterButtons {
    background-color: rgb(237, 243, 255);
    max-width: 56rem;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(KeywordsPage);
