import React from 'react';
import tw from 'twin.macro';
import styled, { ThemeProvider } from 'styled-components';
import cn from 'classnames';
import useTheme from '@material-ui/core/styles/useTheme';

export type MenuSubItemProps = {
  label: string;
  selected?: boolean;
  color?: 'primary' | 'secondary';
  onClick?: () => void;
};

export const MenuSubItem = ({
  label,
  selected = false,
  color = 'secondary',
  onClick,
}: MenuSubItemProps): JSX.Element => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledDiv className={cn({ selected })} onClick={onClick} color={color}>
        <div className="label labelSubItem">{label}</div>
      </StyledDiv>
    </ThemeProvider>
  );
};

const getTxtColor = (props: any) => {
  let selectedColor = props.theme.palette.text.secondary;
  if (props.color == 'primary') {
    selectedColor = props.theme.palette.text.primary;
  }
  return selectedColor;
};

const StyledDiv = styled.div`
  ${tw`flex items-center py-1 cursor-pointer`}
  color: color: ${(props) => getTxtColor(props)};

  .label {
    ${tw`ml-12 select-none`}
  }

  .labelSubItem {
    white-space: nowrap;
    margin-right: 0.5rem;
  }

  &.selected .label {
    ${tw`font-semibold`}
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
