import React, { useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import { useTranslation } from 'react-i18next';

am4core.useTheme(am4themes_animated);

interface MapProps {
  id: string;
  mapData: DashboardState[];
  width?: string;
  height?: string;
  margin?: string;
  maxWidth?: number;
  zoom?: number;
  mouseWheelZoom?: boolean;
  totalWithoutCountry?: number;
}

const Map: React.FC<MapProps> = ({
  id,
  mapData,
  width = '100%',
  height = '32rem',
  margin,
  maxWidth = 1200,
  zoom = 1.4,
  mouseWheelZoom = false,
  totalWithoutCountry = 0,
}) => {
  const { t, i18n } = useTranslation();
  const chart = useRef<am4maps.MapChart | null>(null);

  React.useLayoutEffect(() => {
    const x = am4core.create(id, am4maps.MapChart);

    if (x.logo) {
      x.logo.disabled = true;
    }
    x.homeZoomLevel = zoom;

    x.homeGeoPoint = {
      latitude: 15,
      longitude: 11,
    };

    x.geodata = am4geodata_worldLow;
    x.projection = new am4maps.projections.Miller();

    const polygonSeries = x.series.push(new am4maps.MapPolygonSeries());

    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: x.colors.getIndex(1).brighten(1),
      max: x.colors.getIndex(1).brighten(-0.3),
      key: 'fill',
      logarithmic: true,
    });

    polygonSeries.useGeodata = true;
    if (mapData) {
      polygonSeries.data = JSON.parse(JSON.stringify(mapData));
    }
    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.adapter.add('tooltipText', function (text, target) {
      if (target.dataItem) {
        if (!target.dataItem.value) {
          return '{name}: No value';
        }
      }
      return '{name}: {value.formatNumber("#.00")}%';
    });

    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    const hs = polygonTemplate.states.create('hover');
    hs.properties.fill = am4core.color('#3c5bdc');

    const restoreContinents = function () {
      x.goHome();
    };

    // Zoom control
    x.zoomControl = new am4maps.ZoomControl();
    x.chartContainer.wheelable = mouseWheelZoom;

    const homeButton = new am4core.Button();
    homeButton.events.on('hit', restoreContinents);

    homeButton.icon = new am4core.Sprite();
    homeButton.padding(7, 5, 7, 5);
    homeButton.width = 30;
    homeButton.icon.path = 'M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8';
    homeButton.marginBottom = 10;
    homeButton.parent = x.zoomControl;
    homeButton.insertBefore(x.zoomControl.plusButton);

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [mapData]);

  return (
    <>
      {totalWithoutCountry > 0 && (
        <p className="text-sm text-center">
          {totalWithoutCountry}% {t('without country')}
        </p>
      )}
      <div className="mb-4"></div>
      <div id={id} style={{ width, height, maxWidth, margin }}></div>
    </>
  );
};

export default Map;
