import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { InputText } from '../../ui';
import { useTranslation } from 'react-i18next';
import { UserFormFields } from '../../../store/user/types';

interface Props extends Omit<SimpleDialogProps, 'handleOk'> {
  data: UserFormFields;
  handleOk: (data: UserFormFields) => void;
}

const UserNotFoundDialog: React.FC<Props> = ({ data, handleOk, ...rest }) => {
  const { t, i18n } = useTranslation();
  const [FData, setFData] = useState<UserFormFields>(data);

  return (
    <SimpleDialog
      handleOk={() => handleOk(FData)}
      title={t('User not found')}
      disableOkButton={!FData.first_name || !FData.last_name}
      {...rest}
    >
      <>
        <p className="text-sm mb-2">{t(`We don't have {{email}} in our records yet.`, { email: FData.email })}</p>
        <p className="text-sm mb-2">
          {t('Please, provide us with some information about the user so that we can add it.')}
        </p>
        <div className="mt-4">
          <InputText
            label={t('First name')}
            value={FData.first_name}
            required
            onChange={(event) => setFData({ ...FData, first_name: event.target.value })}
            className="mb-3 mr-3 w-80 flex-grow"
          />
          <InputText
            label={t('Last name')}
            value={FData.last_name}
            required
            onChange={(event) => setFData({ ...FData, last_name: event.target.value })}
            className="mb-3 mr-3 w-80 flex-grow"
          />
          <InputText
            label={t('Organization')}
            value={FData.organization}
            onChange={(event) => setFData({ ...FData, organization: event.target.value })}
            className="mb-3 mr-3 w-80 flex-grow"
          />
        </div>
      </>
    </SimpleDialog>
  );
};

export default UserNotFoundDialog;
