import React, { useState } from 'react';
import { Button, InputText } from '../ui';
import FormBuilderCard from '../../components/FormBuilderCard/FormBuilderCard';
import PlusIcon from '../../icons/Plus';
import {
  Answer,
  DefaultQuestions,
  Form,
  FormDTO,
  FormTypeEnum,
  FormVisibilityOption,
  Question,
} from '../../store/form/types.d';
import { FormType } from '../../store/form/types';
import { AppState } from '../../store/state';
import {
  selectTrackRoles,
  selectPaperStatusState,
  selectCurrentUser,
  selectCurrentRole,
  selectKeywordState,
} from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import formSlice from '../../store/form/slice';
import { connect } from 'react-redux';
import { FormAdapter } from '../../helpers/form';
import { Role } from '../../store/conference/types';
import { Switch, Tooltip } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import styled, { ThemeProvider } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import FormVisibilityTimeline from '../FormVisibilityTimeline/FormVisibilityTimeline';
import SidePanel from '../ui/utils/SidePanel/SidePanel';
import { MultiSelect, CancelButton } from '../ui';
import { CategoryEnum, PaperStatus } from '../../store/paper-status/types.d';
import FormBuilderSubmissionDefault from '../FormBuilderSubmissionDefault/FormBuilderSubmissionDefault';
import ViewNewIcon from '../../icons/ViewNew';
import SimpleDialog from '../dialogs/SimpleDialog/SimpleDialog';
import { User } from '../../store/user/types';
import { emptySubmission } from '../../pages/SubmissionNewPage';
import SubmissionForm from '../SubmissionForm/SubmissionForm';
import ReviewForm from '../ReviewForm/ReviewForm';
import { Review } from '../../store/review/types';
import { useTranslation } from 'react-i18next';
import { getFormName, getPaperStatusName, getRoleName } from '../../helpers/translations';

export const FTypeEnum = {
  Select: 'Select',
  MultipleSelect: 'MultipleSelect',
  LikertScale: 'LikertScale',
  Numeric: 'Numeric',
  ShortText: 'ShortText',
  Text: 'Text',
  File: 'File',
  LinearScale: 'LinearScale',
};

export interface FData {
  id: number;
  title: string;
  paper_status_ids: number[];
  description?: string;
  type: FormType;
  questions: QuestionData[];
  is_default: boolean;
  default_questions?: DefaultQuestions;
  position?: number;
  track_id?: number;
  form_id?: number | null;
  visibility?: FormVisibilityOption;
  has_answers: boolean;
}
export interface OptionDatas {
  textLength?: string;
  markdown?: Boolean;
  latex?: Boolean;
  fileSize?: string;
  fileType?: OptionsData[];
  pdfFile?: string;
  asSelect?: boolean;
  asRadioButton?: boolean;
  min?: string;
  max?: string;
  num_decimals?: string;
}
export interface QuestionData {
  id: any;
  questionTitle?: string;
  questionDescriptionOption?: boolean;
  questionDescription?: string;
  questionType?: OptionsData;
  required?: boolean;
  Options?: OptionsData[];
  Option?: OptionDatas;
  visibilityOption?: boolean;
  visibility?: OptionsData[];
}
export interface OptionsData {
  id: string;
  label: string;
  value?: number;
}

const initQuestionOptions = (type: string): { Options: OptionsData[]; Option: OptionDatas } => {
  const options: { Options: OptionsData[]; Option: OptionDatas } = { Options: [], Option: {} };
  switch (type) {
    case FTypeEnum.Select:
    case FTypeEnum.LikertScale:
      options.Option.asSelect = true;
      break;
    case FTypeEnum.Text:
      options.Option.textLength = '65535';
      break;
    case FTypeEnum.ShortText:
      options.Option.textLength = '1024';
      break;
    case FTypeEnum.File:
      options.Option.fileSize = '10';
      options.Option.fileType = [];
      options.Option.pdfFile = '';
      break;
    case FTypeEnum.LinearScale:
      options.Option.min = '1';
      options.Option.max = '10';
      break;
    case FTypeEnum.Numeric:
      options.Option.min = '1';
      options.Option.max = '10';
      options.Option.num_decimals = '0';
      break;
  }
  return options;
};

interface Props {
  initFormData: FData;
  roleById: { [key: string]: Role };
  paperStatusById: { [key: number]: PaperStatus };
  user: User;
  roleId?: number;
  keywordState: KeywordState;
  updateFormAction: (data: FormDTO) => void;
  onCancel: () => void;
}

const FormBuilder: React.FC<Props> = ({
  initFormData,
  roleById,
  paperStatusById,
  user,
  roleId,
  keywordState,
  updateFormAction,
  onCancel,
}): any => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState<FData>(initFormData);
  const [focusIndex, setFocusIndex] = useState<number | undefined>(undefined);
  const [dialog, setDialog] = React.useState<JSX.Element | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);
  const [isPaperStatusChecked, setIsPaperStatusChecked] = React.useState<boolean>(
    initFormData.paper_status_ids.length > 0,
  );
  const [createdCounter, setCreatedCounter] = React.useState<number>(0);

  const theme = useTheme();

  const emptyQuestion: QuestionData = {
    id: 0,
    questionTitle: '',
    questionDescriptionOption: false,
    questionDescription: '',
    questionType: { id: FTypeEnum.Select, label: '' },
    required: false,
    ...initQuestionOptions(FTypeEnum.Select),
    visibilityOption: false,
    visibility: [],
  };

  const generateIdForNewQuestions = (): string => {
    // When adds a new question, increment the counter and change the id to 0-0, 0-1, etc for the new questions
    setCreatedCounter(createdCounter + 1);
    return `0-${createdCounter}`;
  };

  const deleteHandler = (index: number) => {
    setFormData({ ...formData, questions: formData.questions.filter((question, i) => index != i) });
  };
  const duplicateHandler = (id: number) => {
    const questionData = formData.questions.find((x, index) => index == id);
    if (questionData !== undefined) {
      const newQuestions = [...formData.questions];
      const newIndex = id + 1;
      newQuestions.splice(newIndex, 0, { ...questionData, id: generateIdForNewQuestions() });
      setFormData({ ...formData, questions: newQuestions });
      setFocusIndex(newIndex);
    }
  };
  const switchHandler = (event: any, id: number) => {
    setFormData({
      ...formData,
      questions: formData.questions.map((x, index) => {
        if (index == id) {
          return { ...x, required: event.target.checked };
        } else {
          return x;
        }
      }),
    });
  };
  const descriptionHandler = (id: number, text: string) => {
    setFormData({
      ...formData,
      questions: formData.questions.map((x, index) => {
        if (index == id) {
          if (text == 'Description') {
            return { ...x, questionDescriptionOption: !x.questionDescriptionOption };
          } else {
            return { ...x, visibilityOption: !x.visibilityOption };
          }
        } else {
          return x;
        }
      }),
    });
    setFocusIndex(id);
  };
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setFormData({
      ...formData,
      questions: formData.questions.map((x, index) => {
        if (index == id) {
          return { ...x, [event.target.name]: event.target.value };
        } else {
          return x;
        }
      }),
    });
  };
  const addQuestionHandler = () => {
    const newQuestions = [...formData.questions, { ...emptyQuestion, id: generateIdForNewQuestions() }];
    setFormData({ ...formData, questions: newQuestions });
    setFocusIndex(newQuestions.length - 1);
  };
  const visibilityHandler = (visible: any, indexFind: number, visibilityOptions: any) => {
    const visibilityFilter: OptionsData[] = visibilityOptions.filter(({ id }: any) => visible.includes(id));

    setFormData({
      ...formData,
      questions: formData.questions.map((x, index) => {
        if (index == indexFind) {
          return { ...x, visibility: visibilityFilter, visibilityOption: !!visibilityFilter.length };
        } else {
          return x;
        }
      }),
    });
  };
  const swapPosition = (from: number, to: number) => {
    const fromQ = formData.questions[from];
    const toQ = formData.questions[to];

    const newQuestions = formData.questions.slice();
    newQuestions.splice(from, 1, toQ);
    newQuestions.splice(to, 1, fromQ);

    setFormData({
      ...formData,
      questions: newQuestions,
    });
    setFocusIndex(to);
  };
  const selectHandler = (event: string, index: number) => {
    const questionOptions = initQuestionOptions(event);

    setFormData({
      ...formData,
      questions: formData.questions.map((x, i) => {
        if (index == i) {
          return { ...x, questionType: { id: event, label: '' }, ...questionOptions };
        } else {
          return x;
        }
      }),
    });
  };
  const editOption = (index: number, option: OptionsData) => {
    const questionData = formData.questions.find((x: any, i) => index == i);

    setFormData({
      ...formData,
      questions: formData.questions.map((x, i) => {
        if (index == i) {
          return {
            ...x,
            Options: questionData?.Options?.map((y: OptionsData) =>
              y.id == option.id
                ? {
                    ...y,
                    label: option.label,
                    value: option.value,
                  }
                : y,
            ),
          };
        } else {
          return x;
        }
      }),
    });
  };
  const addOption = (index: number | undefined, option: OptionsData) => {
    setFormData({
      ...formData,
      questions: formData.questions.map((x, i) => {
        if (index == i) {
          const newOptions = [...(x.Options ?? [])];
          newOptions.push({
            id: `${
              newOptions
                .map((value) => parseInt(value.id || ''))
                .reduce((previousValue, currentValue) => Math.max(previousValue, currentValue), 0) + 1
            }`,
            label: option.label,
            value: option.value,
          });
          return { ...x, Options: newOptions };
        } else {
          return x;
        }
      }),
    });
  };
  const deleteOptionHandler = (OptionIndex: number | undefined, index: number | undefined) => {
    setFormData({
      ...formData,
      questions: formData.questions.map((x, i) => {
        if (index == i) {
          return { ...x, Options: x?.Options?.filter((y, ind) => ind != OptionIndex) };
        } else {
          return x;
        }
      }),
    });
  };
  const textHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number | undefined) => {
    setFormData({
      ...formData,
      questions: formData.questions.map((x, i) => {
        if (index == i) {
          return { ...x, Option: { ...x.Option, [event.target.name]: event.target.value } };
        } else {
          return x;
        }
      }),
    });
  };

  const checkboxHandler = (event: any, index: number | undefined) => {
    setFormData({
      ...formData,
      questions: formData.questions.map((x, i) => {
        if (index == i) {
          const targetName = event.target.name;
          let Option;
          if (targetName == 'asSelect') {
            Option = { asSelect: event.target.checked, asRadioButton: !event.target.checked };
          } else if (targetName == 'asRadioButton') {
            Option = { asRadioButton: event.target.checked, asSelect: !event.target.checked };
          } else {
            Option = { [event.target.name]: event.target.checked };
          }
          return { ...x, Option: { ...x.Option, ...Option } };
        } else {
          return x;
        }
      }),
    });
  };
  const fileHandler = (file: any, indexFind: number | undefined, fileOptions: any) => {
    const fileFilter = fileOptions.filter(({ id }: any) => file.includes(id));

    setFormData({
      ...formData,
      questions: formData.questions.map((x, index) => {
        if (index == indexFind) {
          const hasPdfSelected = fileFilter.some((option: { id: string; label: string }) => option.label === 'pdf');
          const maxPages = hasPdfSelected ? x.Option?.pdfFile : undefined;

          return { ...x, Option: { ...x.Option, fileType: fileFilter, pdfFile: maxPages } };
        } else {
          return x;
        }
      }),
    });
  };
  const selectNumberHandler = (event: string, index: number | undefined, optionName: string) => {
    setFormData({
      ...formData,
      questions: formData.questions.map((x, i) => {
        if (index == i) {
          return { ...x, Option: { ...x.Option, [optionName]: event } };
        } else {
          return x;
        }
      }),
    });
  };
  const getVisibilityOptions = (): OptionsData[] => {
    const options: OptionsData[] = [];
    Object.values(roleById)
      .filter((role) => role.type != 'chair')
      .forEach((role) => {
        options.push({ id: role.id.toString(), label: getRoleName(role, t) });
        if (role.type === 'reviewer') {
          options.push({ id: `${role.id.toString()}:other`, label: `${t('Other')} ${role.description}` });
        }
      });
    return options;
  };

  // onChangeHandler for default submission form fields
  const onChangeHandler = (targetName: string, targetValue: any) => {
    // TargetName is paper_upload.description, for example, to identify the field with its question in default questions
    // Then we do and split to have type of question and field name
    const split = targetName.split('.');
    const questionType = split[0] as keyof DefaultQuestions;
    const questionFieldName = split[1];

    const questionValue = targetValue;

    if (formData.default_questions) {
      setFormData({
        ...formData,
        default_questions: {
          ...formData.default_questions,
          [questionType]: {
            ...formData.default_questions[questionType],
            [questionFieldName]: questionValue,
            ...(questionValue.length === 0 || (questionValue.length > 0 && !questionValue.includes('pdf'))
              ? { max_pages: null } // When no pdf option selected reset max_pages to null
              : {}),
          },
        },
      });
    }
  };

  const formName = getFormName(formData?.title, formData.is_default, t);

  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-grow flex-col">
        <StyledFormsWrapper className="mb-12 grid grid-cols-1 justify-items-start max-w-5xl">
          <div className="flex mb-7 items-center w-full">
            <button onClick={onCancel} className="bg-blue-700 text-white px-1.5 py-0.5 h-6 leading-none">
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>

            <h2 className="font-bold text-xl ml-8">{formName}</h2>

            {/*
            TEMPORARILY DISABLED
            
            <Link onClick={() => setIsPanelOpen(true)} className="cursor-pointer flex items-center ml-8">
              <ViewIcon />
              <span className="ml-2 font-medium text-sm">Visibility summary</span>
            </Link>
            */}
          </div>

          <p className="mb-8">
            {formData.is_default ? (
              <span>
                {t(
                  `Modify the descriptions for default questions and if is required for paper upload question. Define more questions for the submission form and view the visibility summary by role throughout the timeline phase. You can establish if an extra question is required or visible.`,
                )}
              </span>
            ) : (
              <span>
                {t(
                  `Define questions for the forms and view the visibility summary by role throughout the timeline phase. You can establish if a question is required or visible.`,
                )}
              </span>
            )}
          </p>

          <div className="p-5 h-auto w-3/4 bg-gray-100 rounded-lg border border-solid border-gray-300 w-full mb-4">
            <div className="flex flex-row items-center mb-3">
              <InputText
                key={`${formData.id}-title`}
                className="border-0 font-bold formName mb-2 w-full"
                name="title"
                label={t('Name')}
                placeholder={t('Enter name')}
                value={formName}
                onChange={(e) => setFormData({ ...formData, id: formData.id, title: e.target.value })}
                disabled={formData.is_default}
              />

              {formData.is_default && (
                <Tooltip title={t('You cannot edit the name of submission form.')} placement="right">
                  <span
                    className="font-semibold px-1.5 rounded-full text-sm ml-1.5 text-white cursor-default"
                    style={{ backgroundColor: '#2563eb', height: 'max-content' }}
                  >
                    ?
                  </span>
                </Tooltip>
              )}
            </div>

            {formData.type === FormTypeEnum.Submission && isPaperStatusChecked && !formData.is_default && (
              <div className="paperStatus">
                <MultiSelect
                  className="flex text-sm mb-2"
                  fullWidth
                  placeholder={t('Choose Status')}
                  value={formData.paper_status_ids.map((value) => value.toString())}
                  options={Object.values(paperStatusById)
                    .filter((paperStatus) => paperStatus.category != CategoryEnum.Rejection)
                    .map((paperStatus) => {
                      return {
                        id: paperStatus.id.toString(),
                        label: getPaperStatusName(paperStatus.name, t),
                      };
                    })}
                  onChange={(selectedOptions) => {
                    setFormData({
                      ...formData,
                      id: formData.id,
                      paper_status_ids: selectedOptions.map((value) => parseInt(value)),
                    });
                  }}
                  label={t('Paper status')}
                />
              </div>
            )}
            {formData.type === FormTypeEnum.Submission && !formData.is_default && (
              <>
                {/* TOOLBOX */}
                <hr className="mx-2 mt-8 mb-2" />
                <div className="flex justify-between items-center h-6">
                  <div className="flex w-full">
                    <div className="flex py-2  cursor-pointer items-center text-sm">
                      <Switch
                        onChange={() => {
                          const checked = !isPaperStatusChecked;
                          setIsPaperStatusChecked(checked);
                          if (!checked) {
                            setFormData({ ...formData, paper_status_ids: [] });
                          }
                        }}
                        checked={isPaperStatusChecked}
                        color="primary"
                      />
                      <span className="font-medium hover:text-blue-600 text-sm">{t('Paper status')}</span>

                      <Tooltip title={t('Only if you need a paper status dependant form')} placement="right">
                        <span
                          className="font-semibold px-1.5 rounded-full text-sm ml-1.5 text-white"
                          style={{ backgroundColor: '#2563eb' }}
                        >
                          ?
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {formData.type === 'Submission' && formData.is_default && formData.default_questions && (
            <div className="flex flex-grow flex-col w-full">
              <FormBuilderSubmissionDefault
                defaultQuestions={formData.default_questions}
                onChange={onChangeHandler}
                is_editable={!formData.has_answers}
              />
            </div>
          )}

          {formData?.questions.length > 0 &&
            formData?.questions.map((x, index) => {
              return (
                <FormBuilderCard
                  key={x.id}
                  duplicateHandler={duplicateHandler}
                  deleteHandler={deleteHandler}
                  values={x}
                  handleChange={switchHandler}
                  index={index}
                  changeHandler={changeHandler}
                  descriptionHandler={descriptionHandler}
                  visibilityHandler={visibilityHandler}
                  swapPosition={swapPosition}
                  valueslength={formData.questions.length}
                  selectHandler={selectHandler}
                  editOption={editOption}
                  deleteOptionHandler={deleteOptionHandler}
                  addOption={addOption}
                  textHandler={textHandler}
                  checkboxHandler={checkboxHandler}
                  fileHandler={fileHandler}
                  selectNumberHandler={selectNumberHandler}
                  visibilityOptionsData={getVisibilityOptions()}
                  isFocus={index === focusIndex}
                  focusHandler={(index: number) => setFocusIndex(index)}
                  defaultQuestionsLength={
                    (formData.default_questions && Object.entries(formData.default_questions).length) ?? 0
                  }
                  is_editable={!formData.has_answers}
                />
              );
            })}

          <div className={`flex justify-end pt-2 pb-8 w-full justify-center`}>
            <Tooltip
              title={`${
                formData.has_answers ? t('You cannot add questions because this form has already stored answers') : ''
              }`}
              placement="right"
            >
              <div
                className={`flex items-center ${formData.has_answers ? 'opacity-60 cursor-default' : 'cursor-pointer'}`}
                style={{ color: '#0044f0' }}
                onClick={!formData.has_answers ? addQuestionHandler : undefined}
              >
                <PlusIcon /> <span className="ml-2">{t('Add question')}</span>
              </div>
            </Tooltip>
          </div>
        </StyledFormsWrapper>
      </div>

      {/* FOOTER BUTTONS */}
      <StyledButtonsWrapper>
        <CancelButton onClick={onCancel}>{t('Cancel')}</CancelButton>
        <Button
          className="w-48 mx-2"
          onClick={() => {
            // Before save, change the ids of the new questions to zero as backend needs
            const updatedFormData = {
              ...formData,
              questions: formData.questions.map((question) => {
                if (question.id.toString().includes('0-')) {
                  return { ...question, id: 0 };
                }
                return question;
              }),
            };
            updateFormAction(new FormAdapter().reverse(updatedFormData));
            onCancel(); // It goes back
          }}
        >
          {t('Save')}
        </Button>

        <Button
          variant="outlined"
          roundedEdges="rounded-full"
          className="cursor-pointer mx-2"
          onClick={() => {
            const currentRole = roleById[roleId ?? 0];
            const initSubmission = emptySubmission(user);
            const initAssignment: Review = {
              id: 0,
              external_id: 0,
              person_role_id: roleId ?? 0,
              person_id: user.person.id,
              person: user.person,
              role_id: roleId ?? 0,
              submission_id: initSubmission.id,
              validation_status: 'Pending',
            };

            const form = new FormAdapter().reverse(formData);

            // Questions adapted as SubmissionForm and ReviewForm need
            const adaptedQuestions = form.questions?.map((question) => {
              return {
                ...question,
                role_visibility: {},
              };
            });

            // FormsData array as SubmissionForm and ReviewForm need
            // The result of formData reverse adapter form is not equal that the format
            // needed here so we do a manual form adapter
            const formsData: { form: Form; questions: Question[]; answers: Answer[] }[] = [
              {
                form: {
                  id: formData.id,
                  name: formData.title,
                  type: formData.type,
                  description: formData.description ?? '',
                  position: 0,
                  is_default: formData.is_default,
                  track_id: 0,
                  form_id: null,
                  paper_status_ids: [],
                  visibility: 'write',
                  default_questions: formData.default_questions,
                  has_answers: false,
                },
                questions: adaptedQuestions ?? [],
                answers: [],
              },
            ];

            const formRender =
              formData.type === 'Submission' ? (
                <SubmissionForm
                  initSubmission={initSubmission}
                  keywordState={keywordState}
                  user={user}
                  openSnackBarAction={() => {}}
                  formsData={formsData}
                  userRole={currentRole}
                  canViewFeedback={false}
                />
              ) : (
                <ReviewForm review={initAssignment} formsData={formsData} canViewFeedback={false} />
              );

            setDialog(
              <SimpleDialog
                open={true}
                handleClose={() => setDialog(undefined)}
                handleOk={undefined}
                cancelButtonText="Close"
              >
                <div className="relative pt-4">
                  <StyledPreviewLabel className="absolute">{t('Preview form')}</StyledPreviewLabel>
                  <StyledForm style={{ width: '60vw' }}>{formRender}</StyledForm>
                </div>
              </SimpleDialog>,
            );
          }}
        >
          <div className="flex items-center">
            <ViewNewIcon /> <span className="font-bold ml-1">{t('Preview')}</span>
          </div>
        </Button>
      </StyledButtonsWrapper>

      {isPanelOpen && (
        <SidePanel open={isPanelOpen} onClose={() => setIsPanelOpen(false)}>
          <FormVisibilityTimeline formId={formData.id} />
        </SidePanel>
      )}

      {dialog}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  roleById: selectTrackRoles(state),
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  user: selectCurrentUser(state),
  roleId: selectCurrentRole(state)?.id,
  keywordState: selectKeywordState(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateFormAction: (data: FormDTO) => dispatch(formSlice.actions['UPDATE_FORM'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  background-color: rgb(237, 243, 255);
  border-top: 1px dashed #b1b2b3;
  position: sticky;
  bottom: 0;
`;

const StyledFormsWrapper = styled.div`
  font-size: 0.875rem;
  flex-grow: 0;

  .MuiInputBase-input {
    font-size: 0.875rem;
  }
  .formName {
    display: flex;
    flex-direction: row;

    label {
      width: 10rem;
      padding-top: 0.3rem;
    }
  }
  .formType {
    > div {
      width: 10rem;
      padding-top: 0.3rem;
    }
    .selectType {
      width: 98%;
    }
  }
  .selectType {
    background-color: #fff;
  }

  .paperStatus {
    label,
    .labelSelect {
      width: 8.7rem;
      padding-top: 0.3rem;
      margin-left: 0;
    }
  }
`;

const StyledPreviewLabel = styled.div`
  width: fit-content;
  padding: 0.1rem 1rem;
  border-radius: 10px;
  right: 0;
  position: absolute;
  top: 0;
  background-color: #fbeb02;
`;

const StyledForm = styled.div`
  .tab-forms-wrapper {
    max-height: 60vh;
    overflow-y: auto;
  }
`;
