import { Submission } from '../submission/types';
import { Review } from '../review/types';

export const FormTypeEnum = {
  Submission: 'Submission',
  Review: 'Review',
};
type FormType = FormTypeEnum.Submission | FormTypeEnum.Review;

type Answerable = Submission | Review;

interface DefaultQuestions {
  title: { description: string };
  abstract: { description: string; hidden: boolean };
  authors: { description: string };
  keywords: { description: string };
  paper_upload: {
    description: string;
    required: boolean;
    max_size: number;
    extensions: string[];
    max_pages: number | null;
  };
}

interface Form {
  id: number;
  name: string;
  description: string | null;
  type: FormType;
  position: number;
  track_id: number;
  form_id: number | null;
  visibility: FormVisibilityOption;
  paper_status_ids: number[];
  is_default: boolean;
  default_questions?: DefaultQuestions;
  has_answers: boolean;
}

interface FormDTO {
  id: number;
  name?: string;
  description?: string;
  type?: FormType;
  role_id?: number;
  form_id?: number | null;
  questions?: QuestionDTO[];
  paper_status_ids?: number[];
  default_questions?: DefaultQuestions;
}

type FormVisibilityOption = 'read' | 'write' | 'hidden';
export const FormVisibilityOptions: FormVisibilityOption[] = ['read', 'write', 'hidden'];

type QuestionType = 'Comment' | 'Numeric' | 'Select' | 'MultiSelect' | 'FileUpload' | 'LinearScale' | 'LikertScale';

type QuestionStyle = 'InputText' | 'TextArea' | 'TextAreaLatex' | 'Select' | 'SelectRadioButton';

interface Question {
  id: number;
  title: string | null;
  text: string | null;
  required: boolean;
  type: QuestionType;
  options: any;
  position: number;
  form_id: number;
  style: QuestionStyle | null;
  role_visibility: {
    [key: number]: {
      can_read: boolean;
      can_read_others: boolean;
    };
  };
}

interface QuestionDTO extends Omit<Question, 'role_visibility'> {
  role_ids: string[];
}

type Format = 'plain' | 'latex';

interface AnswerJson {
  value: any;
  format?: Format;
}

interface Answer {
  id: number;
  answer: AnswerJson | null; // Content of value depends on the question type
  question_id: number;
  answerable_id: number;
}

interface AnswerDTO {
  answer: AnswerJson | null; // Content of value depends on the question type
  question_id: number;
}

interface FormState {
  loading: boolean;
  formById: { [key: number]: Form };
  questionById: { [key: number]: Question };
  answerById: { [key: string]: Answer };
  visibilityByFormId: {
    [key: number]: {
      [key: number]: { visibility: FormVisibilityOption; to_role_id: number | null; first_used_at: string | null }[];
    };
  };
}

interface getFormsResponse {
  data: {
    formById: { [key: number]: Form };
    questionById: { [key: number]: Question };
    answerById: { [key: string]: Answer };
    visibilityByFormId: {
      [key: number]: {
        [key: number]: { visibility: FormVisibilityOption; to_role_id: number | null; first_used_at: string | null }[];
      };
    };
  };
}

interface updateFormResponse {
  data: {
    form: Form;
    questionById: { [key: number]: Question };
    visibilities: {
      [key: number]: { visibility: FormVisibilityOption; to_role_id: number | null; first_used_at: string | null }[];
    };
  };
}

type ValidationStatus = 'Pending' | 'Complete';

interface AnswersDTO {
  answerById: { [key: string]: Answer };
}

interface updateVisibilityAction {
  form_id: number;
  target_role_id: number;
  visibility: FormVisibilityOption;
  to_role_id: number | null;
}

interface createFormResponse {
  data: {
    form: Form;
    questionById: { [key: number]: Question };
    visibilities: {
      [key: number]: { visibility: FormVisibilityOption; to_role_id: number | null; first_used_at: string | null }[];
    };
  };
}

interface deleteFormResponse {
  data: any;
}

interface storeAnswersRequest {
  answerable_id: number;
  form_type: FormType;
  answers: Answer[];
}

interface storeAnswersResponse {
  data: {
    answerById: { [key: string]: Answer };
    validation_status: ValidationStatus;
  };
}
