import React from 'react';
import { Button } from '../Button';
import MoreIcon from '../../../../icons/More';
import styled from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { ThemeProvider } from 'styled-components';

interface Props {
  isValid: boolean;
  label?: string;
  handleOnClick?: () => void;
}

const SquaredAddButton: React.FC<Props> = ({ isValid, label, handleOnClick }) => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledSquaredAddButton className="squaredAddButton">
        <Button
          type="submit"
          variant="text"
          icon={<MoreIcon fill="#0044f0" stroke="#fff" strokeWidth={1.2} rectangleStroke="#0044f0" />}
          className={`mb-1 w-12 ${label ? 'withLabel ' : ''}${!isValid ? 'pointer-events-none opacity-30' : ''}`}
          onClick={handleOnClick}
        >
          {label && <span className="pr-4">{label}</span>}
        </Button>
      </StyledSquaredAddButton>
    </ThemeProvider>
  );
};

export default SquaredAddButton;

const StyledSquaredAddButton = styled.div`
  button {
    padding: 0;
    display: inherit;
    justify-content: unset;
    width: max-content;
    height: max-content;

    span {
      margin: 0;

      svg {
        background-color: transparent;
      }
    }
  }

  .withLabel {
    color: #fff;
    background-color: #0044f0;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
