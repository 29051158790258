import { all, put, select, take } from '@redux-saga/core/effects';
import conferenceSlice from '../conference/slice';
import { _roleId, setToken } from '../local-storage';
import userSlice from '../user/slice';
import authSlice from './slice';
import { getRouteByPath } from '../../router/routes';
import history from '../history';
import { matchPath } from 'react-router';
import { ConferenceState, RoleType, Track } from '../conference/types';
import { selectConference, selectImpersonatedTrackId, selectIsEmailVerified } from '../selectors';
import { getUserRoles } from '../../helpers/role';

const getTrackFromURL = (
  conferenceState: ConferenceState,
  conferenceSlug: string,
  trackSlug: string,
): Track | undefined => {
  // Map URL slugs into IDs
  let trackFromURL: Track | undefined = undefined;

  const conferenceFromURL = Object.values(conferenceState.conferencesById).find(
    (value) => value.slug === conferenceSlug,
  );
  if (conferenceFromURL) {
    trackFromURL = Object.values(conferenceState.tracksById).find(
      (value) => value.slug === trackSlug && value.conference_id === conferenceFromURL.id,
    );
  }
  return trackFromURL;
};

export function determineRoleId(trackId: number, conferenceState: ConferenceState, forceTo?: RoleType): number {
  let roleId;

  const candidates = getUserRoles(trackId, conferenceState.userRolesByTrack, conferenceState.roleById);
  let filter;
  if (forceTo && (filter = candidates.filter((role) => role.type == forceTo)).length > 0) {
    roleId = filter[0].id;
  } else if (_roleId && candidates.map((role) => role.id).includes(_roleId)) {
    roleId = _roleId;
  } else {
    // Select the first one
    roleId = candidates[0].id;
  }
  return roleId;
}

/**
 * Do all the work whenever a user logs in
 * @param token
 */
export function* handleLoginOk(token: string): any {
  yield setToken(token);

  yield put(userSlice.actions.GET());
  yield all([take(userSlice.actions['GET:OK'])]);

  const isEmailVerified: boolean = yield select(selectIsEmailVerified);
  if (isEmailVerified) {
    yield put(conferenceSlice.actions.GET_CONFERENCES());
    yield all([take(conferenceSlice.actions['GET_CONFERENCES:OK'])]);

    /* At this point we have conferences and tracks loaded */
    let url;
    let doRedirect;
    const next = localStorage.getItem('next'); // This may contain a href like http://localhost:3000/conferences/my-conference/my-track/a-path-here?role=author
    try {
      url = new URL(next ?? '');
      doRedirect = true;
    } catch (e) {
      url = new URL(window.location.href);
      doRedirect = false;
    }
    if (next != null) {
      localStorage.removeItem('next');
    }
    const path = url.pathname;
    const route = getRouteByPath(path);
    const match = matchPath(path, { path: route.path, exact: true });

    const conferenceState: ConferenceState = yield select(selectConference);
    let track: Track | undefined;
    const impersonatedTrackId: number | null = yield select(selectImpersonatedTrackId);
    if (impersonatedTrackId) {
      track = conferenceState.tracksById[impersonatedTrackId];
    } else if (match && route.track_selected_access) {
      // Extract conferenceSlug and trackSlug from URL
      // @ts-ignore
      const { conference: conferenceSlug, track: trackSlug } = match.params;
      track = getTrackFromURL(conferenceState, conferenceSlug, trackSlug);
    }
    if (track) {
      const roleInSearchParam = url.searchParams.get('role') as RoleType | null;
      let forceTo;
      if (roleInSearchParam && (['author', 'reviewer', 'chair'] as RoleType[]).includes(roleInSearchParam)) {
        forceTo = roleInSearchParam;
      }
      const roleId = determineRoleId(track.id, conferenceState, forceTo);
      yield put(conferenceSlice.actions.SELECT_TRACK({ trackId: track.id, roleId: roleId, doRedirect: false }));
      yield take(conferenceSlice.actions['SELECT_TRACK:OK']);
    }

    if (doRedirect) {
      history.push(path + url.search);
    }
  }
  yield put(authSlice.actions['LOGIN:OK']()); // This action turns off the Loading at Redirector level
  return;
}
