import React from 'react';
import { Link } from '../ui';
import { EmailFormState, EmailSendDTO, EmailTemplate, Placeholder, PreviewRow } from '../../store/email/types';
import { GridColumnProps } from '@progress/kendo-react-grid';
import ActionsCell from '../ActionsCell/ActionsCell';
import { getEmailCount, getEmailRender } from '../../store/email/sagas';
import Loading from '../Loading/Loading';
import ProgressBar from '../ProgressBar/ProgressBar';
import EmailDialog from '../dialogs/EmailDialog/EmailDialog';
import EmailForm, { EmailFormFields } from '../EmailForm/EmailForm';
import MyGridCore from '../MyGridCore/MyGridCore';
import { DEFAULT_FONT_SIZE } from '../MyGridRedux/MyGridRedux';
import { useTranslation } from 'react-i18next';

interface Props {
  placeholders: Placeholder[];
  formState: EmailFormState;
  preview?: PreviewRow[];
  email: EmailSendDTO;
  onChange: (value: EmailFormFields) => void;
  templatesById: { [key: number]: EmailTemplate };
}

const SendEmailForm: React.FC<Props> = ({ placeholders, formState, preview, email, onChange, templatesById }) => {
  const { t, i18n } = useTranslation();
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [emailHtml, setEmailHtml] = React.useState<string | undefined>(undefined);

  switch (formState) {
    case 'COMPOSE':
      return <EmailForm onChange={onChange} placeholders={placeholders} value={email} templatesById={templatesById} />;
    case 'PREVIEW':
      const gridColumnProps: GridColumnProps[] = [];
      gridColumnProps.push(
        {
          field: 'from_name',
          title: t('From name'),
        },
        {
          field: 'from',
          title: t('From address'),
        },
        {
          field: 'recipient_name',
          title: t('Recipient name'),
        },
        {
          field: 'recipient_address',
          title: t('Recipient address'),
        },
        {
          field: 'subject',
          title: t('Subject'),
        },
        {
          field: 'body',
          title: t('Body'),
          cell: ActionsCell,
          filterable: false,
        },
      );

      const inputData: any[] = preview
        ? preview.map((previewRow) => ({
            ...previewRow,
            body: (
              <Link
                className="flex items-center cursor-pointer font-semibold"
                onClick={async () => {
                  const { ids, dry_run, token, ...rest } = email;
                  const response = await getEmailRender({
                    id: previewRow.id,
                    ...rest,
                  });
                  if (response.type === 'ok') {
                    setEmailHtml(response.value.data.html);
                    setShowDialog(true);
                  }
                }}
              >
                {t('Preview')}
              </Link>
            ),
          }))
        : [];

      return (
        <div>
          <MyGridCore
            allGridData={inputData}
            gridSettings={{
              gridColumnProps: gridColumnProps,
              advancedFilter: false,
              DATA_ITEM_KEY: '',
              SELECTED_FIELD: '',
              LABELABLE_ITEM_KEY: '',
              fontSize: DEFAULT_FONT_SIZE,
              dataState: {},
            }}
            selectedState={{}}
            dateFormat={null}
            selectable={false}
            showActionsColumn={false}
            showFilters={true}
          />
          {emailHtml && <EmailDialog show={showDialog} onClose={() => setShowDialog(false)} html={emailHtml} />}
        </div>
      );
    case 'SENDING':
      return (
        <ProgressBar
          title={t('Sending emails...')}
          fetchValue={async () => {
            if (email.token && preview) {
              const response = await getEmailCount(email.token);
              if (response.type === 'ok') {
                return Math.round((response.value.data.count / preview.length) * 100);
              }
            }
            return 0;
          }}
        />
      );
    case 'WAIT':
      return <Loading />;
  }
};

export default SendEmailForm;
