import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppDispatch } from '../store/store';
import { Submission } from '../store/submission/types';
import {
  selectCurrentRole,
  selectCurrentUser,
  selectErrorState,
  selectKeywordState,
  selectAssignmentState,
  selectSubmissionState,
  selectTrackRoles,
} from '../store/selectors';
import Loading from '../components/Loading/Loading';
import { OpenSnackBarDTO } from '../store/error/types';
import errorSlice from '../store/error/slice';
import SubmissionForm from '../components/SubmissionForm/SubmissionForm';
import submissionSlice from '../store/submission/slice';
import { AppState } from '../store/state';
import { User } from '../store/user/types';
import { getFormsData } from '../helpers/form';
import { FormTypeEnum } from '../store/form/types.d';
import { goBackFromSubmissionDetail } from '../components/SubmissionDetail/SubmissionDetail';
import { Role } from '../store/conference/types';
import { Prompt } from 'react-router-dom';
import { SubmissionPermissionManager } from '../helpers/permissions';
import { ValidatedField } from '../store/api.d';
import { initializeAnswers } from './SubmissionEditPage';
import { useTranslation } from 'react-i18next';

export const emptySubmission = (user: User): Submission => {
  return {
    id: 0,
    external_id: 0,
    title: '',
    abstract: '',
    file_upload: null,
    keyword_ids: [],
    authors: [
      {
        id: user.person.id,
        person_role_id: 0,
        email: user.user_alias_by_id[user.id].email,
        first_name: user.person.first_name,
        middle_name: null,
        last_name: user.person.last_name,
        organization: user.person.organization,
        is_primary: true,
      },
    ],
    answers: [],
    validation_status: 'Pending',
    paper_status: '',
    paper_status_id: 0,
    track_id: 0,
    label_ids: [],
    has_discussion: false,
  };
};

interface Props {
  loading: boolean;
  keywordState: KeywordState;
  user: User;
  roleId?: number;
  createSubmissionAction: (data: Submission) => void;
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
  roleById: { [key: string]: Role };
  validationErrors?: ValidatedField[];
  clearValidationErrorsAction: () => void;
}

const SubmissionNewPage: React.FC<Props> = ({
  loading,
  keywordState,
  user,
  roleId,
  createSubmissionAction,
  openSnackBarAction,
  roleById,
  validationErrors,
  clearValidationErrorsAction,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<Submission | undefined>(undefined);
  const isBlocking = data === undefined || validationErrors !== undefined; // Initial behaviour is to be blocking

  useEffect(() => {
    // Clear any validation error on exit
    return () => clearValidationErrorsAction();
  }, []);

  useEffect(() => {
    if (data) {
      // Save click
      createSubmissionAction(data);
    }
  }, [data]);

  if (!roleId || loading) {
    return <Loading />;
  }

  const authorRole = Object.values(roleById).find((role) => role.type == 'author');
  if (!authorRole) throw Error('Author role does not exist.');

  const currentRole = roleById[roleId];

  // Get Submission type forms
  const submission = emptySubmission(user);
  const isOwner = new SubmissionPermissionManager(submission).isPrimaryAuthor(user.person.id, currentRole);
  const submissionFormsData = getFormsData(
    FormTypeEnum.Submission,
    ['write'],
    roleId,
    isOwner ? null : authorRole.id,
    user.person.id,
    submission,
  );

  return (
    <>
      <Prompt when={isBlocking} message={t('Are you sure you want to leave?')} />
      <SubmissionForm
        initSubmission={data ?? { ...submission, answers: initializeAnswers(submissionFormsData.formsData) }}
        onSaveHandler={(data) => setData(data)}
        onCancelClick={() => {
          // Cancel click
          if (roleId) {
            const currentRoleType = roleById[roleId].type;
            goBackFromSubmissionDetail(currentRoleType);
          }
        }}
        keywordState={keywordState}
        user={user}
        openSnackBarAction={openSnackBarAction}
        formsData={submissionFormsData.formsData}
        userRole={currentRole}
        canViewFeedback={false}
        validationErrors={validationErrors}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading || selectAssignmentState(state).loading,
  keywordById: selectKeywordState(state).keywordById,
  keywordState: selectKeywordState(state),
  user: selectCurrentUser(state),
  roleId: selectCurrentRole(state)?.id,
  roleById: selectTrackRoles(state),
  validationErrors: selectErrorState(state).validationErrors,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createSubmissionAction: (data: Submission) => dispatch(submissionSlice.actions['CREATE_SUBMISSION'](data)),
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
  clearValidationErrorsAction: () => dispatch(errorSlice.actions['CLEAR:VALIDATION_ERRORS']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionNewPage);
