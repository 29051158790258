import React from 'react';

interface IconProps {
  stroke: string;
  width?: string;
  height?: string;
}

function Icon({ stroke = 'currentColor', width = '22', height = '22' }: IconProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 17.3134C17.2526 17.3134 18.6733 15.8926 18.6733 14.14C18.6733 12.3875 17.2526 10.9667 15.5 10.9667C13.7474 10.9667 12.3267 12.3875 12.3267 14.14C12.3267 15.8926 13.7474 17.3134 15.5 17.3134Z"
        stroke={stroke}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6786 20.8651C18.5728 19.7523 17.0688 19.1266 15.5001 19.1266C13.9313 19.1266 12.4273 19.7523 11.3215 20.8651"
        stroke={stroke}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 22.3C19.2555 22.3 22.3 19.2555 22.3 15.5C22.3 11.7445 19.2555 8.70001 15.5 8.70001C11.7444 8.70001 8.69995 11.7445 8.69995 15.5C8.69995 19.2555 11.7444 22.3 15.5 22.3Z"
        stroke={stroke}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_13_118)">
        <path
          d="M8.64583 1.35417V8.64583H1.35417V1.35417H8.64583ZM8.64583 0.3125H1.35417C0.78125 0.3125 0.3125 0.78125 0.3125 1.35417V8.64583C0.3125 9.21875 0.78125 9.6875 1.35417 9.6875H8.64583C9.21875 9.6875 9.6875 9.21875 9.6875 8.64583V1.35417C9.6875 0.78125 9.21875 0.3125 8.64583 0.3125Z"
          fill={stroke}
        />
        <path
          d="M6.04159 7.60418H2.39575V6.56251H6.04159V7.60418ZM7.60409 5.52084H2.39575V4.47918H7.60409V5.52084ZM7.60409 3.43751H2.39575V2.39584H7.60409V3.43751Z"
          fill={stroke}
        />
      </g>
      <g clipPath="url(#clip1_13_118)">
        <path
          d="M1.7498 12.5C1.7498 12.2234 1.58223 12 1.3748 12C1.16738 12 0.999805 12.2234 0.999805 12.5L0.999805 14C0.999804 14.8281 1.50371 15.5 2.1248 15.5L5.71895 15.5L4.85879 16.6469C4.7123 16.8422 4.7123 17.1594 4.85879 17.3547C5.00527 17.55 5.24316 17.55 5.38965 17.3547L6.88965 15.3547C7.03613 15.1594 7.03613 14.8422 6.88965 14.6469L5.38965 12.6469C5.24316 12.4516 5.00527 12.4516 4.85879 12.6469C4.7123 12.8422 4.7123 13.1594 4.85879 13.3547L5.71895 14.5L2.1248 14.5C1.91738 14.5 1.7498 14.2766 1.7498 14L1.7498 12.5Z"
          fill={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_13_118">
          <rect width="10" height="10" fill="white" />
        </clipPath>
        <clipPath id="clip1_13_118">
          <rect width="6" height="6" fill="white" transform="translate(7 12) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
