import React, { useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Loading from '../components/Loading/Loading';
import conferenceSlice from '../store/conference/slice';
import { Conference, ConferenceSelectTrack, ConferenceState } from '../store/conference/types';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { getRouteByName } from '../router/routes';
import history from '../store/history';
import PlusIcon from '../icons/Plus';
import { Button, Card, Link, SearchText, SelectRadioButton } from '../components/ui';
import { Tabs, TabsItem } from '../components/ui';
import { useTheme } from '@material-ui/core/styles';
import { selectAuth, selectConference } from '../store/selectors';
import DateTimeWithOffset from '../components/DateTimeWithOffset/DateTimeWithOffset';
import { ImpersonateRequest } from '../store/auth/types';
import authSlice from '../store/auth/slice';
import { determineRoleId } from '../store/auth/commons';
import { getUserRoles } from '../helpers/role';
import { useTranslation } from 'react-i18next';
import Calendar from '../icons/Calendar';
import Pin from '../icons/Pin';
import Globe from '../icons/Globe';
import { fillRoutePath } from '../helpers/path';
import Settings from '../icons/Settings';
import { Tooltip } from '@material-ui/core';

interface ConferencesPageProps {
  conferenceState: ConferenceState;
  selectTrackAction: (data: ConferenceSelectTrack) => void;
  isSuperAdmin: boolean;
  impersonateAction: (data: ImpersonateRequest) => void;
  unselectTrackAction: () => void;
  administeredConferences: number[];
}

interface ConferenceTrackOptions {
  id: string;
  label: string;
}

function ConferencesPage({
  conferenceState,
  selectTrackAction,
  isSuperAdmin,
  impersonateAction,
  unselectTrackAction,
  administeredConferences,
}: ConferencesPageProps) {
  const initializeSelectedTracks = (): { [conferenceId: number]: number } => {
    const result: { [conferenceId: number]: number } = {};

    Object.values(conferencesById).forEach((conference) => {
      const firstTrack = Object.values(tracksById).find((track) => track.conference_id === conference.id);

      if (firstTrack) {
        result[conference.id] = firstTrack.id;
      }
    });

    return result;
  };

  const { loading, userRolesByTrack, tracksById, conferencesById, roleById } = conferenceState;
  const [section, setSection] = React.useState<'my-conferences' | 'all-conferences' | 'past-conferences'>(
    'my-conferences',
  );
  const [searchText, setSearchText] = React.useState<string>('');
  const [selectedTracks, setSelectedTracks] =
    React.useState<{ [conferenceId: number]: number }>(initializeSelectedTracks);
  const theme = useTheme();
  const { t } = useTranslation();

  const myConferenceIds = new Set<number>();

  Object.keys(userRolesByTrack).map((value) => {
    myConferenceIds.add(tracksById[parseInt(value)].conference_id);
  });

  const today = new Date();

  // Testing dates
  //const today = new Date('2023-09-01T10:55:00.000000Z');
  //const today = new Date('2023-10-05T10:55:00.000000Z');
  //const today = new Date('1970-10-05T10:55:00.000000Z');

  useEffect(() => {
    if (conferenceState.currentTrackId) {
      /* If we reach conferences list page and we have a selected track this means we exit track by a strange way and thus by not by clicking 'Exit conference' or so.
      We must clean redux state at this point.
       */
      unselectTrackAction();
    }

    const activeConferencesIds = Array.from(myConferenceIds).filter(
      (id) => new Date(conferencesById[id].end_date) >= today,
    );

    if (!activeConferencesIds.length) {
      setSection('all-conferences');
    }
  }, []);

  const handleRadioTrackChange = (conferenceId: number, trackId: number) => {
    setSelectedTracks({
      ...selectedTracks,
      [conferenceId]: trackId,
    });
  };

  const searchConferences = (): [Conference[], Conference[]] => {
    const myConferences: Conference[] = [];
    const otherConferences: Conference[] = [];

    Object.values(conferencesById)
      .filter((conference) => {
        const text = searchText.trim().toLowerCase();
        return (
          conference.name.toLowerCase().includes(text) ||
          conference.short_name.toLowerCase().includes(text) ||
          (isSuperAdmin && conference.slug.toLowerCase().includes(text))
        );
      })
      .sort((a: Conference, b: Conference) => {
        // Aquí assumim que `a` i `b` són conferències.
        const dateA = a.start_date ? new Date(a.start_date) : Infinity;
        const dateB = b.start_date ? new Date(b.start_date) : Infinity;
        return dateA > dateB ? 1 : dateA < dateB ? -1 : 0; // Ordre ASC
      })
      .forEach((conference) => {
        if (myConferenceIds.has(conference.id)) {
          myConferences.push(conference);
        } else {
          otherConferences.push(conference);
        }
      });

    return [myConferences, otherConferences];
  };

  // Function to get conference track options
  const getTrackOptionsByConference = (conferenceId: number): ConferenceTrackOptions[] => {
    return Object.values(tracksById)
      .filter((track) => track.conference_id === conferenceId)
      .map((track) => {
        return { id: track.id.toString(), label: track.name };
      });
  };

  const renderConferences = (conferences: Conference[], arePast?: boolean) => {
    return conferences.map((conference, index) => {
      const selectedTrackOption = selectedTracks[conference.id]; // selected option

      const userRoles = getUserRoles(selectedTrackOption, userRolesByTrack, roleById);
      const isChair = userRoles.map((r) => roleById[r.id]).filter((role) => role.type == 'chair').length > 0;
      const isConferenceAdmin = administeredConferences.includes(conference.id);

      return (
        <Card key={index} featured={false} className={`relative ${arePast ? 'past-conference' : 'active-conference'}`}>
          <div className="cardRowInfo flex items-start justify-between">
            <p className="font-bold mb-4">{conference.name}</p>

            {(isSuperAdmin || isConferenceAdmin) && (
              <Tooltip title={t('Manage conference')} placement="top-start">
                <div
                  className="border rounded-lg p-1 cursor-pointer"
                  style={{ borderColor: '#C8CDD8' }}
                  onClick={() => {
                    history.push(
                      fillRoutePath(getRouteByName('RouteSettingsConference').path, {
                        slug: conference.slug,
                      }),
                    );
                  }}
                >
                  <Settings stroke={'#0e4ff0'} />
                </div>
              </Tooltip>
            )}
          </div>

          <div className="cardRowInfo flex items-start mb-1.5 text-sm">
            <div className="mt-0.5 mr-2">
              <Calendar />
            </div>
            <DateTimeWithOffset date={conference.start_date} /> - <DateTimeWithOffset date={conference.end_date} />
          </div>

          {conference.location && (
            <div className="cardRowInfo flex items-start mb-1.5 text-sm">
              <div className="mt-0.5 mr-2">
                <Pin />
              </div>
              <p>{conference.location}</p>
            </div>
          )}

          {conference.url && (
            <div className="cardRowInfo flex items-start mb-1.5 text-sm">
              <div className="mt-0.5 mr-2">
                <Globe stroke="#000" />
              </div>
              <p>
                <StyledLink href={conference.url} target="_blank">
                  {conference.url}
                </StyledLink>
              </p>
            </div>
          )}

          <div className="mt-4 text-sm">
            <p className="font-bold mb-2">{t('Tracks')}</p>

            <SelectRadioButton
              className="flex flex-col space-y-1"
              value={selectedTrackOption.toString()}
              options={getTrackOptionsByConference(conference.id)}
              onChange={(trackId) => handleRadioTrackChange(conference.id, parseInt(trackId))}
            />
          </div>

          <div className="sticky top-full">
            <Button
              className="cursor-pointer w-full mt-6 mb-3"
              variant={arePast ? 'outlined' : 'solid'}
              onClick={() => {
                selectTrackAction({
                  trackId: selectedTrackOption,
                  roleId: determineRoleId(selectedTrackOption, conferenceState),
                  doRedirect: true,
                });
              }}
            >
              <span className="font-bold">{t('Enter conference')}</span>
            </Button>

            {isSuperAdmin && !isChair && (
              <div className="mt-1 text-center">
                <hr className="border-t-2 border-dotted border-gray-400 mb-4" />
                <Button
                  className="cursor-pointer"
                  onClick={() => impersonateAction({ track_id: selectedTrackOption })}
                  style={{ backgroundColor: 'rgb(252, 211, 77)', borderColor: 'rgb(252, 211, 77)', width: '100%' }}
                >
                  <span className="font-bold text-black">{t('Impersonate chair')}</span>
                </Button>

                <div className="mt-4">
                  <span className="font-bold">{t('Slug')}:</span>
                  <span className="ml-1">{conference.slug}</span>
                </div>
              </div>
            )}
          </div>
        </Card>
      );
    });
  };

  if (loading) return <Loading />;

  let [myConferences, otherConferences] = searchConferences();

  // Here we decide what content to show in tabs
  let content;
  if (section == 'my-conferences') {
    // My active conferences
    myConferences = myConferences.filter((conference) => new Date(conference.end_date) > today);

    content =
      myConferences.length > 0 ? (
        <div className="grid xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
          {renderConferences(myConferences)}
        </div>
      ) : searchText.length > 0 ? (
        <div>{t('No conference was found')}.</div>
      ) : (
        <div>{t("You don't have active conferences yet")}.</div>
      );
  } else if (section == 'all-conferences') {
    // My active conferences
    myConferences = myConferences.filter((conference) => new Date(conference.end_date) > today);
    otherConferences = otherConferences.filter((conference) => new Date(conference.end_date) > today);
    const concat = myConferences.concat(otherConferences);

    content =
      concat.length > 0 ? (
        <div className="grid xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
          {renderConferences(concat)}
        </div>
      ) : (
        <div>{t('No conference was found')}.</div>
      );
  } else {
    // section == 'past-conferences'
    // My active conferences
    myConferences = myConferences.filter((conference) => new Date(conference.end_date) < today);
    otherConferences = otherConferences.filter((conference) => new Date(conference.end_date) < today);
    const arePast = true;

    content =
      myConferences.length > 0 || otherConferences.length > 0 ? (
        <div>
          {myConferences.length > 0 && (
            <>
              <h2 className="mb-4 text-sm font-bold leading-none">{t('My past conferences')}</h2>
              <div className="grid xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 past-conferences-wrapper">
                {renderConferences(myConferences, arePast)}
              </div>
            </>
          )}

          {otherConferences.length > 0 && (
            <>
              <h2 className={`mb-4 text-sm font-bold ${otherConferences.length > 0 && 'mt-8'}`}>
                {t('Other past conferences')}
              </h2>
              <div className="grid xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 past-conferences-wrapper">
                {renderConferences(otherConferences, arePast)}
              </div>
            </>
          )}
        </div>
      ) : (
        <div>{t('No conference was found')}.</div>
      );
  }

  return (
    <div className="max-w-screen-2xl mt-8 mx-auto w-full">
      <div className="flex flex-row justify-between">
        <div className="flex items-center mb-14">
          <h2 className="font-bold text-xl">{t('Conference List')}</h2>
          <button
            className="flex items-center ml-3 font-bold text-base requestConferenceButton"
            onClick={() => history.push(getRouteByName('RouteConferencesCreate').path)}
            style={{ color: theme.palette.primary.main }}
          >
            <PlusIcon />
            <span className="ml-3">{t('Request Conference')}</span>
          </button>
        </div>

        <SearchText
          className="h-9"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          placeholder={t('Search by text...')}
        />
      </div>

      <StyledSection className="pb-8 border-b">
        <header className="relative">
          <Tabs className="flex-grow">
            {/*
              My conferences: my active conferences. Marked with background blue
            */}
            <TabsItem
              label={t('My Conferences')}
              selected={section == 'my-conferences'}
              onClick={() => {
                setSection('my-conferences');
              }}
            />
            {/*
              Explore conferences: all active, owned conferences and other conferences. Other marked with background white
            */}
            <TabsItem
              label={t('Explore conferences')}
              selected={section == 'all-conferences'}
              onClick={() => {
                setSection('all-conferences');
              }}
            />
            {/*
              Past conferences: all past conferences are marked with background grey.
            */}
            <TabsItem
              label={t('Past conferences')}
              selected={section == 'past-conferences'}
              onClick={() => {
                setSection('past-conferences');
              }}
            />
          </Tabs>
        </header>
        {content}
      </StyledSection>
    </div>
  );
}

const StyledSection = styled.div`
  ${tw`mb-6`}
  header {
    ${tw`mb-6`}
  }
  .past-conference {
    background-color: #f4f1f1;
  }
  .active-conference {
    background-color: #edf3ff;
  }
`;

const StyledLink = styled(Link)`
  -ms-word-break: break-all;
  word-break: break-all;
`;

const mapStateToProps = (state: AppState) => ({
  conferenceState: state.conference,
  isSuperAdmin: selectAuth(state).isSuperAdmin,
  administeredConferences: selectConference(state).administeredConferences,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  selectTrackAction: (data: ConferenceSelectTrack) => dispatch(conferenceSlice.actions.SELECT_TRACK(data)),
  impersonateAction: (data: ImpersonateRequest) => dispatch(authSlice.actions.IMPERSONATE(data)),
  unselectTrackAction: () => dispatch(conferenceSlice.actions.UNSELECT_TRACK()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConferencesPage);
