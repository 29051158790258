import React from 'react';
import tw from 'twin.macro';
import CaretIcon from '../../icons/Caret';
import ChevronDownIcon from '../../icons/ChevronDown';
import { context } from './Menu';
import styled, { ThemeProvider } from 'styled-components';
import cn from 'classnames';
import useTheme from '@material-ui/core/styles/useTheme';

export type MenuItemProps = {
  icon: React.ReactNode;
  label: string;
  expanded?: boolean;
  selected?: boolean;
  color?: 'secondary' | 'primary';
  onClick?: () => void;
  children?: React.ReactNode;
};

export const MenuItem = ({
  icon = null,
  label,
  expanded,
  selected = false,
  color = 'secondary',
  onClick,
  children,
}: MenuItemProps): JSX.Element => {
  const { open: menuOpen } = React.useContext(context);

  const theme = useTheme();

  return (
    <>
      <ThemeProvider theme={theme}>
        <StyledDiv className={cn({ selected, hasChildren: !!children })} onClick={onClick} color={color}>
          <div className="icon">
            <div className="caret mr-3">
              <CaretIcon />
            </div>
            <div className="w-5" title={menuOpen ? '' : label}>
              {icon}
            </div>
          </div>
          <div className={cn('label', { hidden: !menuOpen })}>{label}</div>
          {children && Object.keys(children).length > 0 && (
            <button type="button" className={cn('toggle', { hidden: !menuOpen })}>
              <div className={`transform transition-all duration-300 ${expanded ? 'rotate-180' : 'rotate-0'}`}>
                <ChevronDownIcon />
              </div>
            </button>
          )}
        </StyledDiv>
        {expanded && children && <div className={cn('mb-2', { hidden: !menuOpen })}>{children}</div>}
      </ThemeProvider>
    </>
  );
};

const getTxtColor = (props: any) => {
  let selectedColor = props.theme.palette.text.secondary;
  if (props.color == 'primary') {
    selectedColor = props.theme.palette.text.primary;
  }
  return selectedColor;
};

const StyledDiv = styled.div`
  ${tw`flex items-center cursor-pointer`}
  color: ${(props) => getTxtColor(props)};

  .caret {
    ${tw`invisible`}
  }

  .icon {
    ${tw`flex justify-between items-center py-2.5 pr-3`}
  }

  .label {
    ${tw`flex-grow select-none`}
    color: ${(props) => getTxtColor(props)};
  }

  &.selected {
    .caret {
      ${tw`visible`}
    }

    .icon {
      color: ${(props) => props.theme.palette.primary.main};
    }

    .label {
      ${tw`font-semibold`}
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  .toggle {
    ${tw`self-stretch px-3 flex items-center invisible`}
    color: color: ${(props) => getTxtColor(props)};

    &.hidden {
      ${tw`hidden`}
    }
  }

  &.hasChildren .toggle {
    ${tw`visible`}
  }
`;
