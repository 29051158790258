import React, { useState } from 'react';
import FileUploadUpdater from '../../components/FileUploadUpdater/FileUploadUpdater';
import { postAssignmentsBulk } from '../../store/review/sagas';
import { AppState } from '../../store/state';
import {
  selectInfo,
  selectAssignmentState,
  selectSubmissionState,
  selectExistRolesHandleReviewers,
} from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SelectRadioButton } from '../../components/ui';

type TemplateName = 'assignments-of-submission' | 'assignments-of-reviewer';

interface Props {
  loading: boolean;
  existRolesHandleReviewers: boolean;
}

const AssignmentsBulkImportPage: React.FC<Props> = ({ loading, existRolesHandleReviewers }) => {
  const { t, i18n } = useTranslation();
  const [templateName, setTemplateName] = useState<TemplateName | undefined>(
    existRolesHandleReviewers ? undefined : 'assignments-of-submission',
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col h-full">
      {existRolesHandleReviewers ? (
        <SelectRadioButton
          required
          options={[
            { id: 'assignments-of-submission', label: t('Import assignments of submission') },
            { id: 'assignments-of-reviewer', label: t('Import assignments of reviewer') },
          ]}
          disabled={isDisabled}
          value={templateName}
          onChange={(value) => setTemplateName(value as TemplateName)}
          className="flex flex-col mt-1 gap-y-1"
        />
      ) : undefined}

      {templateName && (
        <StyledUploaderWrapper className="mt-2 mb-2 flex flex-col h-full">
          <FileUploadUpdater
            doPreview={(file) => {
              setIsDisabled(true);
              return postAssignmentsBulk({ file, dry_run: true, templateName });
            }}
            doSave={(file) => postAssignmentsBulk({ file, dry_run: false, templateName })}
            templateName={templateName}
            onCancelHandler={() => setIsDisabled(false)}
          />
        </StyledUploaderWrapper>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading || selectSubmissionState(state).loading || selectAssignmentState(state).loading,
  existRolesHandleReviewers: selectExistRolesHandleReviewers(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

const StyledUploaderWrapper = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div {
      input,
      a {
        font-size: 0.875rem;
      }
    }

    .buttonWrapper {
      margin: 2rem 0;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsBulkImportPage);
