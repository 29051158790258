//Inspired by this page: https://dev.to/bybydev/how-to-slugify-a-string-in-javascript-4o9n
export const generateSlug = (text: string): string => {
  text = text.trim();
  text = text.toLowerCase();
  text = text
    .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
  return text;
};
