import { store } from '../store/store';
import { selectCurrentRole } from '../store/selectors';
import { Role, RoleDTO, RoleRelationType } from '../store/conference/types';

export const getCurrentRole = (): Role | null => selectCurrentRole(store.getState());

export const hasRelation = (
  type: RoleRelationType,
  fromId: number,
  toId: number,
  roleRelationsById: { [key: string]: { [key: string]: number[] } },
): boolean => {
  return roleRelationsById[type] && roleRelationsById[type][fromId] && roleRelationsById[type][fromId].includes(toId);
};

const sortRoles = (roles: Role[]): Role[] => {
  return roles.sort((a, b) => {
    return b.rank - a.rank; // Sort descending by role priority
  });
};

export const getUserRoles = (
  trackId: number,
  userRolesByTrack: { [key: string]: { id: number }[] },
  roleById: { [key: string]: Role },
): Role[] => {
  const authorRole = Object.values(roleById).find((role) => role.type == 'author' && role.track_id == trackId);
  const userRolesIds = [...(userRolesByTrack[trackId] ?? []), { id: authorRole?.id ?? 0 }].map((r) => r.id); // Artificially add author role as everyone can enter with it
  const set = new Set(userRolesIds);
  const userRoles = Array.from(set).map((roleId) => roleById[roleId]);
  return sortRoles(userRoles); // Sort
};

export const doesHandleReviewers = (role: Role | RoleDTO): boolean => role.role_id !== null;

export class RoleTree {
  protected nodes: { [key: number]: Role };
  protected children: { [key: number]: number[] };
  protected parents: { [key: number]: number[] };

  constructor(roles: Role[]) {
    this.nodes = {};
    this.children = {};
    this.parents = {};

    // Initialize nodes
    roles.forEach((role) => {
      this.nodes[role.id] = role;
      this.children[role.id] = [];
      this.parents[role.id] = [];
    });

    Object.values(this.nodes).forEach((role) => {
      if (doesHandleReviewers(role)) {
        const childId = role.role_id as number;
        this.children[role.id].push(childId);
        this.parents[childId].push(role.id);
      }
    });
  }

  childrenOf(roleId: number): Role[] {
    return this.children[roleId].map((id) => this.nodes[id]);
  }

  parentOf(roleId: number): Role[] {
    return this.parents[roleId].map((id) => this.nodes[id]);
  }
}
