import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Answer, Question } from '../../store/form/types';
import FileUploaderS3 from '../FileUploaderS3/FileUploaderS3';
import { getCompositeAnswerId } from '../../helpers/answers';

interface Props extends GridCellProps {
  question: Question;
  answerById: { [key: string]: Answer };
}

const QuestionCell: React.FC<Props> = ({ question, answerById, ...props }) => {
  const field = props.field || '';
  const value = props.dataItem[field] || '';

  const id = getCompositeAnswerId(question.id, props.dataItem.id);
  const answer = answerById[id];

  const getInputComponent = () => {
    switch (question.type) {
      case 'FileUpload':
        return answer?.answer ? (
          <FileUploaderS3 initUpload={answer.answer ? answer.answer.value : null} extensions={[]} disabled={true} />
        ) : undefined;
      default:
        return value;
    }
  };

  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      {getInputComponent()}
    </td>
  );
};

export default QuestionCell;
