import { Controller, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { DateFormatEnum, User } from '../../store/user/types.d';
import { Button, InputText, Select, CancelButton } from '../ui';
import countries, { countryToFlag } from '../../helpers/countries';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import useTheme from '@material-ui/core/styles/useTheme';
import { formatDate } from '@progress/kendo-intl';
import { InputValidationError } from '../types';
import styled, { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../ui/inputs/LanguageSelector/LanguageSelector';
import Message from '../ui/display/Message/Message';
import Info from '../../icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

export const dateFormatOptions = [
  { id: '', label: 'System default' },
  { id: DateFormatEnum.DMY12, label: 'day/month/year 12h' },
  { id: DateFormatEnum.DMY24, label: 'day/month/year 24h' },
  { id: DateFormatEnum.MDY12, label: 'month/day/year 12h' },
  { id: DateFormatEnum.MDY24, label: 'month/day/year 24h' },
  { id: DateFormatEnum.YMD12, label: 'year/month/day 12h' },
  { id: DateFormatEnum.YMD24, label: 'year/month/day 24h' },
]; // https://github.com/telerik/kendo-intl/blob/master/docs/date-formatting/index.md#standard

export interface ProfileFormFields {
  first_name: string;
  middle_name: string;
  last_name: string;
  country: string;
  language: string;
  timezone: string;
  date_format: string;
  organization: string;
  dblp_key: string;
  google_scholar_id: string;
  orcid_id: string;
}

export interface ProfileFormProps {
  validation_error?: { [k: string]: string };
  isProfileCompleted?: boolean;
  save_handler: (form: ProfileFormFields) => void;
  user: User;
  onCancelClick: () => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  validation_error,
  isProfileCompleted,
  save_handler,
  user,
  onCancelClick,
}) => {
  const theme = useTheme();
  const { handleSubmit, errors, control } = useForm<ProfileFormFields>();
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState<boolean>(!isProfileCompleted);

  useEffect(() => {
    setVisible(!isProfileCompleted);
  }, [isProfileCompleted]);

  let firstNameProps: InputValidationError = {};
  let middleNameProps: InputValidationError = {};
  let lastNameProps: InputValidationError = {};
  let countryProps: InputValidationError = {};
  let timezoneProps: InputValidationError = {};
  let dateFormatProps: InputValidationError = {};
  let organizationProps: InputValidationError = {};
  let dblpKeyProps: InputValidationError = {};
  let googleScholarIdProps: InputValidationError = {};
  let orcidIdProps: InputValidationError = {};

  const frontend_validation_rules = {
    first_name: { required: 'This field is required' },
    middle_name: { required: false },
    last_name: { required: 'This field is required' },
    country: { required: 'Enter your country' },
    language: { required: false },
    timezone: { required: false },
    date_format: { required: false },
    organization: { required: false },
    dblp_key: { required: "This field is required. If you don't have one, please write 'Not in DBLP'." },
    google_scholar_id: {
      required: "This field is required. If you don't have one, please write 'Not in Google Scholar'.",
    },
    orcid_id: { required: "This field is required. If you don't have one, please write 'Not in ORCID'." },
  };
  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.first_name) {
      firstNameProps = {
        ...firstNameProps,
        error: true,
        helperText: errors.first_name.message,
      };
    }
    if (errors.middle_name) {
      middleNameProps = {
        ...middleNameProps,
        error: true,
        helperText: errors.middle_name.message,
      };
    }
    if (errors.last_name) {
      lastNameProps = {
        ...lastNameProps,
        error: true,
        helperText: errors.last_name.message,
      };
    }
    if (errors.country) {
      countryProps = {
        ...countryProps,
        error: true,
        helperText: errors.country.message,
      };
    }
    if (errors.timezone) {
      timezoneProps = {
        ...timezoneProps,
        error: true,
        helperText: errors.timezone.message,
      };
    }
    if (errors.timezone) {
      dateFormatProps = {
        ...timezoneProps,
        error: true,
        helperText: errors.timezone.message,
      };
    }
    if (errors.organization) {
      organizationProps = {
        ...organizationProps,
        error: true,
        helperText: errors.organization.message,
      };
    }
    if (errors.dblp_key) {
      dblpKeyProps = {
        ...dblpKeyProps,
        error: true,
        helperText: errors.dblp_key.message,
      };
    }
    if (errors.google_scholar_id) {
      googleScholarIdProps = {
        ...googleScholarIdProps,
        error: true,
        helperText: errors.google_scholar_id.message,
      };
    }
    if (errors.orcid_id) {
      orcidIdProps = {
        ...orcidIdProps,
        error: true,
        helperText: errors.orcid_id.message,
      };
    }
  } else {
    // Backend Validation
    if (validation_error) {
      if (validation_error.first_name) {
        firstNameProps = {
          ...firstNameProps,
          error: true,
          helperText: validation_error.first_name,
        };
      }
      if (validation_error.middle_name) {
        middleNameProps = {
          ...middleNameProps,
          error: true,
          helperText: validation_error.middle_name,
        };
      }
      if (validation_error.last_name) {
        lastNameProps = {
          ...lastNameProps,
          error: true,
          helperText: validation_error.last_name,
        };
      }
      if (validation_error.country) {
        countryProps = {
          ...countryProps,
          error: true,
          helperText: validation_error.country,
        };
      }
      if (validation_error.timezone) {
        timezoneProps = {
          ...timezoneProps,
          error: true,
          helperText: validation_error.timezone,
        };
      }
      if (validation_error.date_format) {
        timezoneProps = {
          ...timezoneProps,
          error: true,
          helperText: validation_error.date_format,
        };
      }
      if (validation_error.organization) {
        organizationProps = {
          ...organizationProps,
          error: true,
          helperText: validation_error.organization,
        };
      }
      if (validation_error.dblp_key) {
        dblpKeyProps = {
          ...dblpKeyProps,
          error: true,
          helperText: validation_error.dblp_key,
        };
      }
      if (validation_error.google_scholar_id) {
        googleScholarIdProps = {
          ...googleScholarIdProps,
          error: true,
          helperText: validation_error.google_scholar_id,
        };
      }
      if (validation_error.orcid_id) {
        orcidIdProps = {
          ...orcidIdProps,
          error: true,
          helperText: validation_error.orcid_id,
        };
      }
    }
  }

  let options = countries
    .sort(function (a, b) {
      const nameA = a.label.toUpperCase(); // ignore upper and lowercase
      const nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    .map((country) => {
      const label = countryToFlag(country.code) + ' ' + country.label;
      return { id: country.code, label };
    });

  options = [{ id: '', label: 'None' }].concat(options);

  return (
    <ThemeProvider theme={theme}>
      <StyledProfileContentWrapper className="flex flex-col">
        <p className="text-2xl mb-3 text-center font-semibold text-gray-600">
          {t(`Welcome`)} {user.person.full_name}
        </p>
        <p className="mb-10 text-center text-gray-600">{t('Personal info and options to manage it.')}</p>

        <form onSubmit={handleSubmit(save_handler)} className="flex flex-col" style={{ flexGrow: 1 }}>
          {visible && (
            <StyledMessage
              severity="warning"
              handleClose={() => setVisible(false)}
              className="mt-auto mb-10 mx-auto w-full"
            >
              <p className="title mb-1">{t('Attention')}</p>
              <p className="text">{t('Please, ensure all required fields are completed to continue.')}</p>
            </StyledMessage>
          )}

          <StyledFormContent style={{ flexGrow: 1 }}>
            <div className="flex flex-col mx-10">
              <div className="font-bold mb-4 text-sm">{t('Required details')}</div>
              <Controller
                name="first_name"
                control={control}
                defaultValue={user.person.first_name}
                rules={frontend_validation_rules.first_name}
                render={({ onChange, value }) => (
                  <InputText
                    label={t('First name')}
                    autoFocus
                    value={value}
                    onChange={onChange}
                    error={firstNameProps.error}
                    helperText={firstNameProps.helperText}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                defaultValue={user.person.last_name}
                rules={frontend_validation_rules.last_name}
                render={({ onChange, value }) => (
                  <InputText
                    label={t('Last name')}
                    value={value}
                    onChange={onChange}
                    error={lastNameProps.error}
                    helperText={lastNameProps.helperText}
                  />
                )}
              />
              <Controller
                name="country"
                control={control}
                defaultValue={user.person.country ?? ''}
                rules={frontend_validation_rules.country}
                render={({ onChange, value }) => (
                  <Select
                    label={t('Country')}
                    value={value}
                    options={options}
                    onChange={onChange}
                    error={countryProps.error}
                    helperText={countryProps.helperText}
                  />
                )}
              />
              <Controller
                name="dblp_key"
                control={control}
                defaultValue={user.person.dblp_key ?? ''}
                rules={frontend_validation_rules.dblp_key}
                render={({ onChange, value }) => (
                  <div className="relative">
                    <InputText
                      label="DBLP URL"
                      description=""
                      value={value}
                      onChange={onChange}
                      error={dblpKeyProps.error}
                      helperText={dblpKeyProps.helperText}
                      className="w-full"
                    />
                    <Tooltip
                      title={t(
                        "Your DBLP URL (i.e. https://dblp.org/pid/00/0000.html or https://dblp.uni-trier.de/pid/00/0000.html). Please avoid providing links to disambiguation pages (e.g., https://dblp.org/pid/50/3402.html is not acceptable, but https://dblp.org/pid/50/3402-26.html is fine). If you don't have one, please write 'Not in DBLP'",
                      )}
                      placement="top-start"
                    >
                      <div className="absolute tooltipProfileForm tooltipDblp" style={{ top: '15px', left: '80px' }}>
                        <Info />
                      </div>
                    </Tooltip>
                  </div>
                )}
              />
              <Controller
                name="google_scholar_id"
                control={control}
                defaultValue={user.person.google_scholar_id ?? ''}
                rules={frontend_validation_rules.google_scholar_id}
                render={({ onChange, value }) => (
                  <div className="relative">
                    <InputText
                      label="Google Scholar URL"
                      description=""
                      value={value}
                      onChange={onChange}
                      error={googleScholarIdProps.error}
                      helperText={googleScholarIdProps.helperText}
                      className="w-full"
                    />
                    <Tooltip
                      title={t(
                        "Your scholar profile URL (i.e. https://scholar.google.com/citations?user=MsGRzsoAAAAJ). If you don't have one, please write 'Not in Google Scholar'",
                      )}
                      placement="top-start"
                    >
                      <div
                        className="absolute tooltipProfileForm tooltipScholar"
                        style={{ top: '15px', left: '139px' }}
                      >
                        <Info />
                      </div>
                    </Tooltip>
                  </div>
                )}
              />
              <Controller
                name="orcid_id"
                control={control}
                defaultValue={user.person.orcid_id ?? ''}
                rules={frontend_validation_rules.orcid_id}
                render={({ onChange, value }) => (
                  <div className="relative">
                    <InputText
                      label="ORCID URL"
                      description=""
                      value={value}
                      onChange={onChange}
                      error={orcidIdProps.error}
                      helperText={orcidIdProps.helperText}
                      className="w-full"
                    />
                    <Tooltip
                      title={t(
                        "Your ORCID ID (i.e. https://orcid.org/0000-0000-0000-0000). If you don't have one, please write 'Not in ORCID'",
                      )}
                      placement="top-start"
                    >
                      <div className="absolute tooltipProfileForm tooltipOrcid" style={{ top: '15px', left: '85px' }}>
                        <Info />
                      </div>
                    </Tooltip>
                  </div>
                )}
              />
            </div>
            <div className="flex flex-col mx-10">
              <div className="font-bold mb-4 text-sm">{t('Additional info')}</div>
              <Controller
                name="middle_name"
                control={control}
                defaultValue={user.person.middle_name ?? ''}
                rules={frontend_validation_rules.middle_name}
                render={({ onChange, value }) => (
                  <InputText
                    label={t('Middle name')}
                    value={value}
                    onChange={onChange}
                    error={middleNameProps.error}
                    helperText={middleNameProps.helperText}
                  />
                )}
              />
              <Controller
                name="language"
                control={control}
                defaultValue={user.person.language}
                render={({ onChange }) => (
                  <StyledLanguageWrapper className="flex flex-col profileFormLanguageWrapper">
                    <div className="font-normal text-sm labelSelect">{t('Language')}</div>
                    <LanguageSelector onChange={onChange} />
                  </StyledLanguageWrapper>
                )}
              />
              <Controller
                name="timezone"
                control={control}
                defaultValue={user.person.timezone}
                rules={frontend_validation_rules.timezone}
                render={({ onChange, value }) => (
                  <div className="profileFormTimezoneWrapper">
                    <div className="font-normal text-sm labelSelect" color={theme.palette.text.primary}>
                      {t('Timezone')}
                    </div>
                    <TimezoneSelect
                      value={value}
                      onChange={(timezone) => {
                        onChange(timezone.value);
                      }}
                      timezones={{ ...allTimezones, 'etc/gmt+12': 'Anywhere on Earth' }}
                      className="timezoneSelectWrapper"
                    />
                  </div>
                )}
              />
              <Controller
                name="date_format"
                control={control}
                defaultValue={user.person.date_format ?? ''}
                rules={frontend_validation_rules.date_format}
                render={({ onChange, value }) => (
                  <div className="profileFormDatetimeWrapper">
                    <Select
                      label={t('Date format')}
                      value={value}
                      options={dateFormatOptions.map(({ id, label }) => ({
                        id,
                        label: id ? `${label} (${formatDate(new Date(), id)})` : label,
                      }))}
                      onChange={(dateFormat) => onChange(dateFormat)}
                    />
                  </div>
                )}
              />
              <Controller
                name="organization"
                control={control}
                defaultValue={user.person.organization}
                rules={frontend_validation_rules.organization}
                render={({ onChange, value }) => (
                  <InputText
                    label={t('Organization')}
                    value={value}
                    onChange={onChange}
                    error={organizationProps.error}
                    helperText={organizationProps.helperText}
                  />
                )}
              />
            </div>
          </StyledFormContent>

          <StyledButtons>
            <CancelButton
              onClick={() => {
                onCancelClick();
              }}
            >
              {t('Cancel')}
            </CancelButton>

            <Button type="submit" className="w-48 place-self-center">
              <span className="px-14">{t('Save')}</span>
            </Button>
          </StyledButtons>
        </form>
      </StyledProfileContentWrapper>
    </ThemeProvider>
  );
};

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  padding: 1rem 0;
  position: sticky;
  bottom: 0;
  background-color: rgb(237, 243, 255);
  z-index: 10;
`;

const StyledProfileContentWrapper = styled.div`
  flex-grow: 1;
`;

const StyledFormContent = styled.div`
  display: flex;
  margin: 0 auto 4% auto;
  max-width: 930px;

  .k-widget.k-dropdown {
    width: 24rem;
  }

  label,
  .labelSelect {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .helperText {
    height: auto;
  }
`;

const StyledMessage = styled(Message)`
  max-width: 852px;
`;

const StyledLanguageWrapper = styled.div`
  .k-dropdown-wrap {
    padding-top: 5px;
    padding-bottom: 4px;
    background-color: #fff !important;
    border: 1px solid #afb0b1 !important;
  }
`;

export default ProfileForm;
